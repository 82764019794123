import { TiktokOrder } from "@/types/TikTokOrder";
import { Currency } from "@/types/Currency";
import {
  StatementTableValue,
  StatementTableValueView,
} from "./statement-table-value";

export type StatementFieldMapping = {
  header: string;
  order: number;
  isNotCurrency?: boolean;
  isSubtract?: boolean;
  isNeutral?: boolean;
  isChildrenAccordion?: boolean;
  estimated?: boolean;
  isPercentage?: boolean;
  tooltip?: string;
  customRender?: (order: TiktokOrder, currency: Currency) => React.ReactNode;
  extraDetail?: (order: TiktokOrder, currency: Currency) => React.ReactNode;
  children?: Record<string, StatementFieldMapping>;
};

export const statementFieldMappings: Record<
  string,
  StatementFieldMapping
> = {
  settledNetUnits: {
    header: "Net Units",
    order: 0.5,
    isNotCurrency: true,
    isNeutral: true,
    isChildrenAccordion: true,
    tooltip: "Total number of units in settled orders",
    children: {
      settledUnits: {
        header: "Units",
        order: 0.5,
        isNotCurrency: true,
        isNeutral: true,
        tooltip: "Total number of units",
      },
      refundedQuantity: {
        header: "Refunded Units",
        order: 1,
        isNotCurrency: true,
        isSubtract: true,
        tooltip: "Total number of units refunded",
      },
    },
  },
  settledGrossSales: {
    header: "Gross Sales",
    order: 1,
    isNeutral: true,
    tooltip:
      "Net gross sales which includes TikTok and Seller paid shipping fees & discounts. Excluding buyer paid shipping fees.",
    children: {
      settledGrossSales: {
        header: "Gross Sales",
        order: 1,
        isNeutral: true,
        isChildrenAccordion: true,
        tooltip:
          "Gross sales before refunds which includes TikTok and Seller paid shipping fees & discounts. Excluding buyer paid shipping fees.",
        customRender: (order: TiktokOrder, currency: Currency) => {
          return (
            <StatementTableValueView
              fieldConfig={{
                ...statementFieldMappings.settledGrossSales,
                children: undefined,
              }}
              value={
                (order.tiktokStatement?.mergoioData.settledGrossSales ?? 0) -
                (order.tiktokStatement?.mergoioData
                  .settledGrossSalesRefundAmount ?? 0)
              }
              currency={currency}
            />
          );
        },
        children: {
          customerPayments: {
            header: "Customer Payment",
            order: 2,
            isNeutral: true,
            customRender: (order: TiktokOrder, currency: Currency) => {
              return (
                <StatementTableValueView
                  fieldConfig={{
                    ...statementFieldMappings.settledMerchandiseSales,
                    children: undefined,
                    isNeutral: false,
                  }}
                  value={order.tiktokStatement?.customerPaymentAmount ?? 0}
                  currency={currency}
                />
              );
            },
          },
          buyerPaidShipping: {
            header: "Buyers Shipping Fee",
            order: 2.5,
            isSubtract: true,
            tooltip: "Buyer paid shipping fees",
            customRender: (order: TiktokOrder, currency: Currency) => {
              return (
                <StatementTableValueView
                  fieldConfig={{
                    ...statementFieldMappings.settledMerchandiseSales,
                    children: undefined,
                    isNeutral: false,
                  }}
                  value={
                    -(order.tiktokStatement?.customerPaidShippingFeeAmount ?? 0)
                  }
                  currency={currency}
                />
              );
            },
          },
          tiktokPaidDiscount: {
            header: "Platform Discounts",
            order: 2.75,
            isSubtract: true,
            tooltip: "Discounts applied by the platform",
            customRender: (order: TiktokOrder, currency: Currency) => {
              return (
                <StatementTableValueView
                  fieldConfig={{
                    ...statementFieldMappings.settledMerchandiseSales,
                    children: undefined,
                    isNeutral: false,
                  }}
                  value={-(order.tiktokStatement?.platformDiscountAmount ?? 0)}
                  currency={currency}
                />
              );
            },
          },
          sellerPaidDiscount: {
            header: "Seller Discounts",
            order: 2.75,
            isSubtract: true,
            tooltip: "Discounts applied by the seller",
            customRender: (order: TiktokOrder, currency: Currency) => {
              return (
                <StatementTableValueView
                  fieldConfig={{
                    ...statementFieldMappings.settledMerchandiseSales,
                    children: undefined,
                    isNeutral: false,
                  }}
                  value={Math.abs(order.tiktokStatement?.sellerDiscountAmount ?? 0)}
                  currency={currency}
                />
              );
            },
          }
        }
      },
      settledGrossSalesRefundAmount: {
        header: "Refund Amount",
        order: 1.5,
        isSubtract: true,
        tooltip: "Total revenue from settled orders refunded",
      },
    },
  },
  settledMerchandiseSales: {
    header: "Net Sales",
    order: 2,
    isNeutral: true,
    isChildrenAccordion: true,
    tooltip: "Net sales excluding any seller or platform discounts.",
    children: {
      customerPayments: {
        header: "Customer Payment",
        order: 2,
        isNeutral: true,
        customRender: (order: TiktokOrder, currency: Currency) => {
          return (
            <StatementTableValueView
              fieldConfig={{
                ...statementFieldMappings.settledMerchandiseSales,
                children: undefined,
                isNeutral: false,
              }}
              value={order.tiktokStatement?.customerPaymentAmount ?? 0}
              currency={currency}
            />
          );
        },
      },
      buyerPaidShipping: {
        header: "Buyers Shipping Fee",
        order: 2.5,
        isSubtract: true,
        tooltip: "Buyer paid shipping fees",
        customRender: (order: TiktokOrder, currency: Currency) => {
          return (
            <StatementTableValueView
              fieldConfig={{
                ...statementFieldMappings.settledMerchandiseSales,
                children: undefined,
                isNeutral: false,
              }}
              value={
                -(order.tiktokStatement?.customerPaidShippingFeeAmount ?? 0)
              }
              currency={currency}
            />
          );
        },
      },
      tiktokPaidDiscount: {
        header: "Platform Discounts",
        order: 2.75,
        isSubtract: true,
        tooltip: "Discounts applied by the platform",
        customRender: (order: TiktokOrder, currency: Currency) => {
          return (
            <StatementTableValueView
              fieldConfig={{
                ...statementFieldMappings.settledMerchandiseSales,
                children: undefined,
                isNeutral: false,
              }}
              value={-(order.tiktokStatement?.platformDiscountAmount ?? 0)}
              currency={currency}
            />
          );
        },
      },
      settledMerchandiseSalesRefundAmount: {
        header: "Refund Amount",
        order: 2.75,
        isSubtract: true,
        tooltip: "Total revenue from settled orders refunded",
      },
      // sellerDiscounts: {
      //   header: "Seller Discounts",
      //   order: 2.75,
      //   isSubtract: true,
      //   tooltip: "Seller discounts",
      //   customRender: (order: TiktokOrder, currency: Currency) => {
      //     return (
      //       <StatementTableValueView
      //         fieldConfig={{
      //           ...statementFieldMappings.settledMerchandiseSales,
      //           children: undefined,
      //           isNeutral: false
      //         }}
      //         value={(order.tiktokStatement?.sellerDiscountAmount ?? 0)}
      //         currency={currency}
      //       />
      //     );
      //   },
      // },
    },
  },
  settledPlatformCommission: {
    header: "TikTok Commiss. Fee",
    order: 3,
    isSubtract: true,
    tooltip: "Total platform commission fees paid on settled orders",
  },
  settledAffiliateCommission: {
    header: "Affiliates",
    order: 3.1,
    isSubtract: true,
    tooltip: "Total affiliate commission fees paid on settled orders",
    extraDetail: (order: TiktokOrder, currency: Currency) => {
      return (
        <div className="h-[75px] bg-muted/20 border-b flex flex-col justify-center">
         
        </div>
      );
    },
  },
  shipping: {
    header: "Shipping",
    order: 4,
    isSubtract: true,
    tooltip: "Settled shipping costs paid directly by seller",
    customRender: (order: TiktokOrder, currency: Currency) => {
      return (
        <StatementTableValueView
          fieldConfig={{
            ...statementFieldMappings.shipping,
            children: undefined,
          }}
          value={
            (order.tiktokStatement?.mergoioData
              .settledEstimatedSellerShippingCost ?? 0) +
            (order.tiktokStatement?.mergoioData
              .settledEstimatedFbmShippingCost ?? 0) +
            (order.tiktokStatement?.mergoioData
              .settledEstimatedFbtShippingCost ?? 0)
          }
          currency={currency}
        />
      );
    },
    children: {
      settledEstimatedFbmShippingCost: {
        header: "FBM Shipping",
        order: 3.2,
        isSubtract: true,
        tooltip: "Settled shipping costs for Fulfilled by Merchant orders",
      },
      settledEstimatedFbtShippingCost: {
        header: "FBT Shipping",
        order: 3.3,
        isSubtract: true,
        tooltip: "Settled shipping costs for Fulfilled by TikTok orders",
      },
      settledEstimatedSellerShippingCost: {
        header: "Seller Shipping",
        order: 4,
        isSubtract: true,
        tooltip: "Settled shipping costs paid directly by seller",
      },
    },
  },
  settledEstimatedFbtFulfilmentAndPackagingFees: {
    header: "FBT Fulfillment & Packaging",
    order: 4.5,
    isSubtract: true,
    estimated: true,
    tooltip: "Estimated fulfillment fees for Fulfilled by TikTok orders",
  },
  settledCostOfGoods: {
    header: "COGS",
    order: 5,
    isSubtract: true,
    tooltip: "Total cost of goods for settled orders",
  },
  settledNetTax: {
    header: "Net Tax",
    order: 6,
    isSubtract: true,
    estimated: true,
    isChildrenAccordion: true,
    tooltip: "Total net tax collected on settled orders",
    children: {
      settledTax: {
        header: "Tax",
        order: 6.1,
        isSubtract: true,
        estimated: true,
        tooltip: "Total tax collected on settled orders",
      },
      settledTaxBack: {
        header: "Tax Back",
        order: 6.2,
        estimated: true,
        tooltip: "Total tax that can be reclaimed on settled orders",
      },
    },
  },
  settledNetProfits: {
    header: "Net Profits",
    order: 7,
    tooltip: "Total net profits from settled orders",
    extraDetail: (order: TiktokOrder, currency: Currency) => {
      return (
        <div className="h-[40px] bg-muted/20 opacity-50 border-b flex flex-col justify-center">
          
        </div>
      );
    },
  },
  margin: {
    header: "Net Margin",
    order: 8,
    isPercentage: true,
    isNeutral: true,
    tooltip: "Net margin as a percentage of gross sales.",
    extraDetail: (order: TiktokOrder, currency: Currency) => {
      return (
        <div className="h-[40px] bg-muted/20 opacity-50 border-b flex flex-col justify-center">
          
        </div>
      );
    },
  },
  settlementAmount: {
    header: "Actual Settlement Amount",
    order: 9,
    tooltip:
      "Net sales minus any immediate deductions payable to TikTok (Shipping, TikTok Commiss. Fee etc.)",
    customRender: (order: TiktokOrder, currency: Currency) => {
      return (
        <StatementTableValueView
          fieldConfig={{
            ...statementFieldMappings.settledGrossSales,
            children: undefined,
            isNeutral: false,
          }}
          value={order.tiktokStatement?.settlementAmount ?? 0}
          currency={currency}
        />
      );
    },
  },
  //   refundedQuantity: {
  //     header: "Refunded Qty",
  //     order: 15,
  //     isNotCurrency: true,
  //     isNeutral: true,
  //     tooltip: "Number of units refunded",
  //   },
  //   sellerShippingQuantity: {
  //     header: "Seller Shipping Qty",
  //     order: 15,
  //     isNotCurrency: true,
  //     isNeutral: true,
  //     tooltip: "Number of orders shipped by seller",
  //   },
};
