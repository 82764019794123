import { Info } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { TiktokOrder } from "@/types/TikTokOrder";
import { Currency } from "@/types/Currency";

export const RawDataButtonDialog = ({
  order,
  currency,
}: {
  order: TiktokOrder;
  currency: Currency;
}) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          variant="secondary"
          className="w-[200px] mt-2 flex items-center justify-between gap-2"
        >
          View Raw Data
          <Info className="w-4 h-4" />
        </Button>
      </DialogTrigger>
      <DialogContent className="p-0 max-w-7xl max-h-[80vh] overflow-y-auto">
        <div className="flex flex-col gap-10">
          <div>
            <h3 className="font-semibold mb-2 px-4 py-2 border-b">
              Mergoio Data
            </h3>
            <div className="w-full space-y-2">
              {Object.entries(order.mergoioData).map(([key, value]) => (
                <div
                  key={key}
                  className="flex justify-between items-center py-1 border-b"
                >
                  <div className="w-1/2 px-4">
                    {key
                      .replace(/([A-Z])/g, " $1")
                      .trim()
                      .split(" ")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  </div>
                  <div className="w-1/2 flex justify-end px-4">
                    {typeof value === "number"
                      ? `${currency.symbol}${Number(value).toFixed(2)}`
                      : String(value)}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div>
            <h3 className="font-semibold mb-2 px-4 py-2 border-b">
              Full Order Data
            </h3>
            <div className="w-full space-y-2">
              {Object.entries(order).map(([key, value]) => {
                if (typeof value === "object") return null;
                return (
                  <div
                    key={key}
                    className="flex justify-between items-center py-1 border-b"
                  >
                    <div className="w-1/2 px-4">{key}</div>
                    <div className="w-1/2 flex justify-end px-4">
                      {String(value)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
