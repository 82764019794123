import React, { useContext, useState } from "react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { TaxSettingsPopover } from "@/components/tax-settings-popover";
import { ModeToggle } from "@/components/mode-toggle";
import { CurrencySelect } from "@/components/currency-select";
import { ChartSettingsPopover } from "@/components/chart-settings-popover";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";
import { Globe, Moon, DollarSign, BarChart2, Bell, Store } from "lucide-react";
import { ChartSettings } from "@/components/chart-settings";
import { PlatformFeesConfigureButton } from "@/components/platformCommissionFees/platform-fees-configure-button";
import { FbtFulfillmentFeesConfigureButton } from "@/components/fbtFulfillmentFees/fbt-fulfillment-fees-configure-button";
import { useOrganisationService } from "@/hooks/organisation.hooks";
import { CogsCalcPopover } from "@/components/cogs-calc-popover";

export const Settings: React.FC = () => {
  const {
    addPurchasesTaxReclaimToNetProfits,
    setAddPurchasesTaxReclaimToNetProfits,
  } = useContext(UserStateContext);
  const [showProductAlerts, setShowProductAlerts] = useState(true);
  const {updateSettings} = useOrganisationService();

  const onChangeAddPurchasesTaxReclaimToNetProfits = (checked: boolean) => {
    setAddPurchasesTaxReclaimToNetProfits(checked);
    updateSettings({
      addPurchasesTaxReclaimToNetProfits: checked
    });
  }

  const settingBlocks = [
    {
      title: "TikTok Settings",
      subtitle: "Configure your TikTok shop settings",
      icon: <Store className="h-6 w-6" />,
      content: (
        <>
          <div className="w-full px-6 flex py-4 flex-col gap-4 border-b border-t">
            <div>
              <Label htmlFor="platform-fees-configure-button" className="text-sm font-medium">
                TikTok Commission Fee
              </Label>
              <p className="text-xs text-muted-foreground mt-1">
                Configure platform fee rates for your orders. Some brands receive reduced platform fee percentages, such as 1-2% for the first 30-90 days. Use this button to set up and manage your platform fee rates across different time periods.
              </p>
            </div>
            <PlatformFeesConfigureButton />
          </div>
          <div className="px-6 flex py-4 flex-col gap-4 border-b">
            <div>
              <Label
                htmlFor="fbt-fulfillment-fees-configure-button"
                className="text-sm font-medium"
              >
                FBT Fulfillment Fee Discounts
              </Label>
              <p className="text-xs text-muted-foreground mt-1">
                Configure fulfillment fee discounts for FBT (Fulfillment By TikTok) orders. Some brands
                receive 20-50% discount, or first 90 days free, click the button
                to set up and manage discount.
              </p>
            </div>
            <FbtFulfillmentFeesConfigureButton type="NORMAL" />
          </div>
          <div className="px-6 flex flex-col gap-4 py-4 border-b">
            <div>
              <Label htmlFor="cogs-calc-select" className="text-sm font-medium">
                COGS Calculation Method
              </Label>
              <p className="text-xs text-muted-foreground mt-1">
                Choose how COGS should be calculated for your orders. This affects how your profit margins and financial metrics are calculated.
              </p>
            </div>
            <CogsCalcPopover type="BUTTON" />
          </div>
        </>
      ),
    },
    {
      title: "Tax Settings",
      subtitle: "Configure your tax preferences",
      icon: <DollarSign className="h-6 w-6" />,
      content: (
        <>
          <div className="px-6 flex flex-col gap-4 py-4 border-b border-t">
            <div>
              <Label htmlFor="tax-rate-select" className="text-sm font-medium">
                Tax Settings
              </Label>
              <p className="text-xs text-muted-foreground mt-1">
                Configure tax settings for your sales and costs.
              </p>
            </div>
            <TaxSettingsPopover />
          </div>
        </>
      ),
    },
    {
      title: "General Settings",
      subtitle: "Customize your global preferences",
      icon: <Globe className="h-6 w-6" />,
      content: (
        <>
          <div className="px-6 flex items-center justify-between py-4 border-b border-t">
            <Label htmlFor="currency-select" className="text-sm font-medium">
              Currency
            </Label>
            <CurrencySelect variant="muted" />
          </div>
          <div className="px-6 flex items-center justify-between py-4 border-b">
            <Label htmlFor="mode-toggle" className="text-sm font-medium">
              Theme
            </Label>
            <ModeToggle />
          </div>
        </>
      ),
    }
  ];

  return (
    <div className="container mx-auto px-6 space-y-8 max-w-[70%] h-full flex flex-col items-center justify-center">
      <div className="w-full flex justify-between items-center">
        <h1 className="text-xl font-bold">Settings</h1>
      </div>

      <div className="grid gap-6 md:grid-cols-3">
        {settingBlocks.map((block, index) => (
          <Card key={index} className="shadow-lg">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2 px-6">
              <CardTitle className="text-xl font-semibold">
                {block.title}
              </CardTitle>
              {block.icon}
            </CardHeader>
            <CardDescription className="text-sm text-muted-foreground px-6">
              {block.subtitle}
            </CardDescription>
            <CardContent className="p-0 pt-4">{block.content}</CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};
