import React, { createContext, useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "@/components/ui/toaster";
import { useNavigate } from "react-router";
import { Currency } from "@/types/Currency";
import { useOrganisationService } from "@/hooks/organisation.hooks";
import { defaultTaxOptions, TaxConfigurationItem, TaxOptions } from "@/types/TaxConfigurationItem";

export type UserRole = "ADMIN" | "MEMBER" | "OWNER";

type UserStateContextType = {
  v2: boolean;
  setV2: (v2: boolean) => void;
  taxOptions: TaxOptions;
  setTaxOptions: (taxOptions: TaxOptions) => void;
  cogsCalculation:
    | "ALL_ORDERS"
    | "EXCLUDE_UNSHIPPED_CANCELLED_ORDERS"
    | "EXCLUDE_CANCELLED_ORDERS";
  setCogsCalculation: (
    cogsCalculation:
      | "ALL_ORDERS"
      | "EXCLUDE_UNSHIPPED_CANCELLED_ORDERS"
      | "EXCLUDE_CANCELLED_ORDERS"
  ) => void;
  swapChartsOnDashboard: boolean;
  setSwapChartsOnDashboard: (swapChartsOnDashboard: boolean) => void;
  addPurchasesTaxReclaimToNetProfits: boolean;
  setAddPurchasesTaxReclaimToNetProfits: (
    addPurchasesTaxReclaimToNetProfits: boolean
  ) => void;
  isLineChart: boolean;
  setIsLineChart: (isLineChart: boolean) => void;
  isCumulative: boolean;
  setIsCumulative: (isCumulative: boolean) => void;
  showSettledData: boolean;
  setShowSettledData: (showSettledData: boolean) => void;
  taxRate: number;
  setTaxRate: (taxRate: number) => void;
  taxRateValidFrom: Date | null;
  setTaxRateValidFrom: (taxRateValidFrom: Date | null) => void;
  currency: Currency;
  setCurrency: (currency: Currency) => void;
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isSidebarOpen: boolean) => void;
  token: string | null;
  userRole: UserRole | null;
  loading: boolean;
  isAuthenticated: boolean;
  signIn: (token: string, userRole: UserRole) => void;
  logOut: () => void;
  selectedSellerAccountId: number | null;
  setSelectedSellerAccountId: (selectedSellerAccountId: number | null) => void;
  listOrders: {
    fromDate: Date | undefined;
    setFromDate: (fromDate: Date | undefined) => void;
    toDate: Date | undefined;
    setToDate: (toDate: Date | undefined) => void;
    selectedStatuses: string[];
    setSelectedStatuses: (selectedStatuses: string[]) => void;
    onlyPaidOrders: boolean;
    setOnlyPaidOrders: (onlyPaidOrders: boolean) => void;
    filterDialogOpen: boolean;
    setFilterDialogOpen: (filterDialogOpen: boolean) => void;
    onlySampleOrders: boolean;
    setOnlySampleOrders: (onlySampleOrders: boolean) => void;
    currentPage: number;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  };
};

export const UserStateContext = createContext<UserStateContextType>({
  v2: true,
  setV2: () => {},
  taxOptions: defaultTaxOptions,
  setTaxOptions: () => {},
  cogsCalculation: "EXCLUDE_UNSHIPPED_CANCELLED_ORDERS",
  setCogsCalculation: () => {},
  swapChartsOnDashboard: false,
  setSwapChartsOnDashboard: () => {},
  addPurchasesTaxReclaimToNetProfits: true,
  setAddPurchasesTaxReclaimToNetProfits: () => {},
  isLineChart: false,
  setIsLineChart: () => {},
  isCumulative: false,
  setIsCumulative: () => {},
  showSettledData: true,
  setShowSettledData: () => {},
  taxRate: 20,
  setTaxRate: () => {},
  taxRateValidFrom: null,
  setTaxRateValidFrom: () => {},
  currency: {
    name: "GBP",
    symbol: "£",
  },
  setCurrency: () => {},
  isSidebarOpen: false,
  setIsSidebarOpen: () => {},
  token: null,
  userRole: null,
  isAuthenticated: false,
  selectedSellerAccountId: null,
  setSelectedSellerAccountId: () => {},
  loading: true,
  signIn: (token: string, userRole: UserRole) => {},
  logOut: () => {},
  listOrders: {
    fromDate: undefined,
    setFromDate: () => {},
    toDate: undefined,
    setToDate: () => {},
    selectedStatuses: [],
    setSelectedStatuses: () => {},
    onlyPaidOrders: false,
    setOnlyPaidOrders: () => {},
    filterDialogOpen: false,
    setFilterDialogOpen: () => {},
    onlySampleOrders: false,
    setOnlySampleOrders: () => {},
    currentPage: 1,
    setCurrentPage: () => {},
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000, // 60 seconds in milliseconds
    },
  },
});

export const UserStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [fromDate, setFromDate] = useState<Date | undefined>(() => undefined);
  const [toDate, setToDate] = useState<Date | undefined>(() => undefined);
  const [token, setToken] = useState<string | null>(null);
  const [userRole, setUserRole] = useState<null | UserRole>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [currency, setCurrency] = useState<Currency>({
    name: "GBP",
    symbol: "£",
  });
  const [taxOptions, setTaxOptions] = useState<TaxOptions>(defaultTaxOptions);
  const [swapChartsOnDashboard, setSwapChartsOnDashboard] =
    useState<boolean>(false);
  const [taxRate, setTaxRate] = useState<number>(20);
  const [taxRateValidFrom, setTaxRateValidFrom] = useState<Date | null>(null);
  const [selectedSellerAccountId, setSelectedSellerAccountId] = useState<
    number | null
  >(null);
  const [cogsCalculation, setCogsCalculation] = useState<
    | "ALL_ORDERS"
    | "EXCLUDE_UNSHIPPED_CANCELLED_ORDERS"
    | "EXCLUDE_CANCELLED_ORDERS"
  >("EXCLUDE_UNSHIPPED_CANCELLED_ORDERS");
  const [isLineChart, setIsLineChart] = useState(false);
  const [isCumulative, setIsCumulative] = useState(false);
  const [showSettledData, setShowSettledData] = useState(true);
  const [v2, setV2] = useState(false);
  const [
    addPurchasesTaxReclaimToNetProfits,
    setAddPurchasesTaxReclaimToNetProfits,
  ] = useState(true);

  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [onlyPaidOrders, setOnlyPaidOrders] = useState(false);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [onlySampleOrders, setOnlySampleOrders] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const onSelectSellerAccount = (newSellerAccountId: number | null) => {
    localStorage.setItem(
      "sellerAccountId",
      newSellerAccountId ? newSellerAccountId.toString() : "null"
    );
    setSelectedSellerAccountId(newSellerAccountId);
  };

  const signIn = (token: string, userRole: UserRole) => {
    localStorage.setItem("userRole", userRole);
    localStorage.setItem("token", token);
    localStorage.setItem("tab", "program");
    localStorage.setItem("sellerAccountId", "null");
    setToken(token);
    setUserRole(userRole);
    setIsAuthenticated(true);
    setSelectedSellerAccountId(null);
    navigate("/");
  };

  const logOut = () => {
    localStorage.setItem("userRole", "");
    localStorage.setItem("token", "");
    localStorage.setItem("user-session-expired", "");
    localStorage.setItem("tab", "program");
    localStorage.setItem("sellerAccountId", "null");
    setToken(null);
    setUserRole(null);
    setIsAuthenticated(false);
    setSelectedSellerAccountId(null);
    navigate("/");
  };

  useEffect(() => {
    // load user from local storage
    const localStorageUserRole = localStorage.getItem("userRole");
    if (localStorageUserRole && localStorageUserRole !== "null") {
      setUserRole(localStorageUserRole as UserRole);
    }

    const storedV2 = localStorage.getItem("v2");
    if (storedV2 && storedV2 !== "null" && storedV2 === "true") {
      setV2(true);
    } else if (!storedV2 || storedV2 === "null") {
      setV2(true);
    }

    // load token from local storage
    const localStorageToken = localStorage.getItem("token");
    if (localStorageToken && localStorageToken !== "null") {
      setToken(localStorageToken);
      setIsAuthenticated(true);
    }

    const sellerAccountId = localStorage.getItem("sellerAccountId");
    if (sellerAccountId && sellerAccountId !== "null") {
      setSelectedSellerAccountId(parseInt(sellerAccountId));
    }

    // const storedTaxRate = localStorage.getItem("taxRate");
    // const storedTaxRateValidFrom = localStorage.getItem("taxRateValidFrom");
    // if (storedTaxRate && storedTaxRate !== "null") {
    //   setTaxRate(Number(storedTaxRate));
    // }
    // if (storedTaxRateValidFrom && storedTaxRateValidFrom !== "null") {
    //   setTaxRateValidFrom(new Date(storedTaxRateValidFrom));
    // }

    const storedCurrency = localStorage.getItem("currency");
    if (storedCurrency && storedCurrency !== "null") {
      setCurrency(JSON.parse(storedCurrency));
    }

    // const storedAddPurchasesTaxReclaimToNetProfits = localStorage.getItem("addPurchasesTaxReclaimToNetProfits");
    // if (storedAddPurchasesTaxReclaimToNetProfits && storedAddPurchasesTaxReclaimToNetProfits !== "null") {
    //   setAddPurchasesTaxReclaimToNetProfits(storedAddPurchasesTaxReclaimToNetProfits === "true");
    // }

    setLoading(false);
  }, []);

  return (
    <UserStateContext.Provider
      value={{
        v2,
        setV2: (v2: boolean) => {
          setV2(v2);
          localStorage.setItem("v2", v2.toString());
        },
        swapChartsOnDashboard,
        setSwapChartsOnDashboard,
        addPurchasesTaxReclaimToNetProfits,
        setAddPurchasesTaxReclaimToNetProfits: (
          addPurchasesTaxReclaimToNetProfits: boolean
        ) => {
          setAddPurchasesTaxReclaimToNetProfits(
            addPurchasesTaxReclaimToNetProfits
          );
          localStorage.setItem(
            "addPurchasesTaxReclaimToNetProfits",
            addPurchasesTaxReclaimToNetProfits.toString()
          );
          //updateSettings({ addPurchasesTaxReclaimToNetProfits });
        },
        cogsCalculation,
        setCogsCalculation: (
          cogsCalculation:
            | "ALL_ORDERS"
            | "EXCLUDE_UNSHIPPED_CANCELLED_ORDERS"
            | "EXCLUDE_CANCELLED_ORDERS"
        ) => {
          setCogsCalculation(cogsCalculation);
          localStorage.setItem("cogsCalculation", cogsCalculation);
        },
        isLineChart,
        setIsLineChart,
        isCumulative,
        setIsCumulative,
        showSettledData,
        setShowSettledData,
        selectedSellerAccountId,
        setSelectedSellerAccountId: (sellerAccountId: number | null) => {
          onSelectSellerAccount(sellerAccountId);
        },
        taxRate,
        setTaxRate: (taxRate: number) => {
          setTaxRate(taxRate);
          localStorage.setItem("taxRate", taxRate.toString());
          //updateSettings({ taxRate });
        },
        taxRateValidFrom,
        setTaxRateValidFrom: (taxRateValidFrom: Date | null) => {
          setTaxRateValidFrom(taxRateValidFrom);
          localStorage.setItem(
            "taxRateValidFrom",
            taxRateValidFrom ? taxRateValidFrom.toISOString() : "null"
          );
          //updateSettings({ taxRateValidFrom });
        },
        currency,
        setCurrency: (currency: Currency) => {
          setCurrency(currency);
          localStorage.setItem("currency", JSON.stringify(currency));
          //updateSettings({ currency });
        },
        taxOptions,
        setTaxOptions,
        token,
        userRole,
        isAuthenticated,
        isSidebarOpen,
        setIsSidebarOpen,
        signIn,
        logOut,
        loading,
        listOrders: {
          fromDate,
          setFromDate,
          toDate,
          setToDate,
          selectedStatuses,
          setSelectedStatuses,
          onlyPaidOrders,
          setOnlyPaidOrders,
          filterDialogOpen,
          setFilterDialogOpen,
          onlySampleOrders,
          setOnlySampleOrders,
          currentPage,
          setCurrentPage,
        },
      }}
    >
      <Toaster />
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </UserStateContext.Provider>
  );
};
