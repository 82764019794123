import React, { useContext, useState, useMemo, useEffect } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Progress } from "@/components/ui/progress";
import {
  CheckCircle,
  AlertCircle,
  Loader2,
  Package,
  ShoppingCart,
  TrendingUp,
  InfoIcon,
  PlayIcon,
  Loader2Icon,
  HandIcon,
  SettingsIcon,
  XCircleIcon,
  CheckCircleIcon,
  ArrowLeft,
  ChevronRightIcon,
  RefreshCcwIcon,
  HelpCircleIcon,
  AlertTriangleIcon,
} from "lucide-react";
import { cn } from "@/lib/utils";
import { MergoioLogo } from "@/components/layout/MergoioLogo";
import { UserStateContext } from "@/contexts/UserStateProvider";
import {
  RETRIEVE_TIKTOK_SELLER_ACCOUNT_BY_ID,
  RETRIEVE_TIKTOK_SELLER_ACCOUNT_BY_ID_REFETCH_INTERVAL,
  useRetrieveTiktokSellerAccountById,
  useRetrieveTiktokSellerAccountByIdWithRefetchInterval,
  useTiktokSellerAccountService,
} from "@/hooks/tiktokSellerAccount.hooks";
import { Button } from "@/components/ui/button";
import {
  OperationJobType,
  OperationJobStatus,
  Operation,
} from "@/types/Operation";
import { ModeToggle } from "@/components/mode-toggle";
import { MyAccountDropdown } from "@/components/my-account-dropdown";
import { _Button } from "@/components/button";
import { useToast } from "@/hooks/toast.hooks";
import { useQueryClient } from "@tanstack/react-query";
import { LoadingFallback } from "@/components/loading-fallback";
import { DefaultLoader } from "@/components/default-loader";
import moment from "moment";
import { useNavigate } from "react-router";
import { AnimatedProgress } from "@/components/progress-animate";

const jobConfigurations = [
  {
    type: OperationJobType.INITIAL_SYNC_PRODUCTS_AND_SKUS,
    title: "Import Products",
    description:
      "Importing your existing products from TikTok Shop into Mergoio.",
    icon: <Package className="h-8 w-8 text-purple-500" />,
    buttonClassNames: "bg-purple-500 hover:bg-purple-600",
  },
  {
    type: OperationJobType.INITIAL_SYNC_ORDERS,
    title: "Import Historical Orders",
    description:
      "Importing your existing orders from TikTok Shop. We'll import all new orders in real-time.",
    icon: <ShoppingCart className="h-8 w-8 text-blue-500" />,
    buttonClassNames: "bg-blue-500 hover:bg-blue-600",
  },
  {
    type: OperationJobType.INITIAL_SYNC_TIKTOK_SKU_PERIODS,
    title: "Analyse SKU Performance",
    description:
      "Applying predictive algorithms to predict analytics for each SKU by looking at completed orders. We can't give you the full Mergoio experience without doing this, please bare with us!",
    icon: <TrendingUp className="h-8 w-8 text-green-500" />,
    buttonClassNames: "bg-green-500 hover:bg-green-600",
  },
];

const friendlyJobStatus = {
  [OperationJobStatus.PENDING]: "Pending",
  [OperationJobStatus.READY]: "Ready to Start",
  [OperationJobStatus.RECEIVED_AND_PROCESSING]: "In Progress",
  [OperationJobStatus.NOT_REQUIRED]: "Not Required",
  [OperationJobStatus.FAILED]: "Failed",
  [OperationJobStatus.SUCCESS]: "Completed",
};

export const SellerAccountSetup = () => {
  const { selectedSellerAccountId, setSelectedSellerAccountId } =
    useContext(UserStateContext);
  const { kickOffInitialSetupOperation, completeSetup, retryOperation } =
    useTiktokSellerAccountService();
  const [kickingOffJob, setKickingOffJob] = useState(false);
  const [retryingJob, setRetryingJob] = useState(false);
  const [completingSetup, setCompletingSetup] = useState(false);
  const [activeJobType, setActiveJobType] = useState<OperationJobType | null>(
    null
  );
  const {
    data: sellerAccount,
    isLoading,
    dataUpdatedAt,
  } = useRetrieveTiktokSellerAccountByIdWithRefetchInterval(
    selectedSellerAccountId
  );
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const jobStatusMap: Record<OperationJobType, Operation> = useMemo(() => {
    if (!sellerAccount?.operations)
      return {} as Record<OperationJobType, Operation>;
    return sellerAccount.operations.reduce((acc, op) => {
      acc[op.jobType] = op;
      return acc;
    }, {} as Record<OperationJobType, Operation>);
  }, [sellerAccount?.operations]);

  const getJobStatus = (jobType: OperationJobType) => {
    return jobStatusMap[jobType]?.jobStatus || null;
  };

  const getProgressValue = (status: OperationJobStatus | null) => {
    switch (status) {
      case OperationJobStatus.SUCCESS:
        return 100;
      case OperationJobStatus.RECEIVED_AND_PROCESSING:
        return 50;
      case OperationJobStatus.READY:
      case OperationJobStatus.PENDING:
        return 0;
      default:
        return 0;
    }
  };

  const isJobStartable = (index: number) => {
    if (index === 0) return true;
    const previousJobStatus = getJobStatus(jobConfigurations[index - 1].type);
    return previousJobStatus === OperationJobStatus.SUCCESS;
  };

  const isJobRetryable = (job: Operation) => {
    if (!job) return false;

    const status = job.jobStatus;
    if (
      status === OperationJobStatus.FAILED
    )
      return true;

    if (status === OperationJobStatus.RECEIVED_AND_PROCESSING && job.retryable) {
      return true;
    }

    return false;
  };

  const handleStartJob = async (jobType: OperationJobType) => {
    if (!selectedSellerAccountId) {
      return;
    }
    setKickingOffJob(true);
    setActiveJobType(jobType);
    try {
      const response = await kickOffInitialSetupOperation({
        sellerAccountId: selectedSellerAccountId,
        jobType,
      });
      if (!response.success) {
        toast({
          icon: <XCircleIcon size={12} className={"text-red-500"} />,
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: response.errorMessage || "Failed to start the job.",
        });
        return;
      }
      queryClient.invalidateQueries({
        queryKey: [RETRIEVE_TIKTOK_SELLER_ACCOUNT_BY_ID_REFETCH_INTERVAL],
      });
      toast({
        icon: <CheckCircleIcon size={12} className={"text-green-500"} />,
        variant: "default",
        title: "Success",
        description: "This process has been successfully started.",
        duration: 2000,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "An unexpected error occurred while starting the job.",
        variant: "destructive",
      });
    } finally {
      setKickingOffJob(false);
    }
  };

  useEffect(() => {
    setRetryingJob(false);
  }, [sellerAccount]);

  const handleRetryJob = async (jobType: OperationJobType) => {
    if (!selectedSellerAccountId) {
      return;
    }
    setRetryingJob(true);
    setActiveJobType(jobType);
    try {
      const response = await retryOperation(jobStatusMap[jobType]?.id);
      if (!response.success) {
        toast({
          icon: <XCircleIcon size={12} className={"text-red-500"} />,
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: response.errorMessage || "Failed to retry the job.",
        });
        setRetryingJob(false);
        return;
      }
      queryClient.invalidateQueries({
        queryKey: [RETRIEVE_TIKTOK_SELLER_ACCOUNT_BY_ID_REFETCH_INTERVAL],
      });
      toast({
        icon: <CheckCircleIcon size={12} className={"text-green-500"} />,
        variant: "default",
        title: "Success",
        description: "Job has been successfully restarted.",
        duration: 2000,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "An unexpected error occurred while retrying the job.",
        variant: "destructive",
      });
      setRetryingJob(false);
    }
  };

  const handleCompleteSetup = async () => {
    if (!selectedSellerAccountId) {
      return;
    }
    setCompletingSetup(true);
    try {
      const response = await completeSetup(selectedSellerAccountId);
      if (!response.success) {
        toast({
          icon: <XCircleIcon size={12} className={"text-red-500"} />,
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: response.errorMessage || "Failed to complete setup.",
        });
        return;
      }
      queryClient.invalidateQueries({
        queryKey: [RETRIEVE_TIKTOK_SELLER_ACCOUNT_BY_ID_REFETCH_INTERVAL],
      });
      queryClient.invalidateQueries({
        queryKey: [RETRIEVE_TIKTOK_SELLER_ACCOUNT_BY_ID],
      });
      toast({
        icon: <CheckCircleIcon size={12} className={"text-green-500"} />,
        variant: "default",
        title: "Success",
        description: "Setup has been successfully completed.",
        duration: 2000,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "An unexpected error occurred while completing setup.",
        variant: "destructive",
      });
      setCompletingSetup(false);
    }
  };

  const allJobsCompleted = jobConfigurations.every(
    (job) => getJobStatus(job.type) === OperationJobStatus.SUCCESS
  );

  return (
    <div className="flex flex-col items-center justify-start min-h-screen">
      <div className="px-8 w-full border-b flex justify-between items-center mb-8 py-4">
        <div className="mx-auto w-full max-w-4xl flex justify-between items-center">
          <MergoioLogo />
          <div className="flex items-center space-x-4">
            <Button variant="outline" onClick={() => navigate("/help")}>
              <HelpCircleIcon className="h-4 w-4 mr-2" />
              Help
            </Button>
            <MyAccountDropdown />
            <ModeToggle />
          </div>
        </div>
      </div>
      <div className="w-full max-w-4xl">
        <div className="flex flex-col gap-8">
          <div className="flex flex-col items-start gap-2">
            <Button
              variant="secondary"
              className="mb-4 flex items-center"
              onClick={() => setSelectedSellerAccountId(null)}
            >
              <ArrowLeft size={20} className="mr-2" />
              Back to accounts
            </Button>
            <div className="text-xl flex gap-2 items-center">
              Let's setup
              {!isLoading && (
                <>
                  : <b>{sellerAccount?.sellerName} Store</b>
                </>
              )}
            </div>
            <div className="max-w-lg text-sm opacity-50 flex gap-4 items-center">
              Before we can show you the full power of Mergoio, we need to setup
              your store. Please go through each step below. All you need to do
              is click "start" and we'll will handle the rest.
            </div>
          </div>
          <div className="pb-32 space-y-2">
            {jobConfigurations.map((job, index) => {
              const status = getJobStatus(job.type);
              const progress = getProgressValue(status);
              const isStartable = isJobStartable(index);
              const isCompleted = status === OperationJobStatus.SUCCESS;
              const canRetry = isJobRetryable(jobStatusMap[job.type]);

              return (
                <div
                  key={job.type}
                  className={cn(
                    "bg-background border border-foreground/40 rounded-xl p-6 transition-all duration-300 hover:shadow-lg",
                    isCompleted && "opacity-40"
                  )}
                >
                  <LoadingFallback
                    loader={<DefaultLoader />}
                    loading={isLoading}
                  >
                    <div className="flex items-start gap-8">
                      <div>{job.icon}</div>
                      <div className="flex-grow flex-col flex gap-4">
                        <div className="flex justify-between items-center">
                          <div
                            className={cn(
                              "lg:w-2/3 w-full",
                              isCompleted && "w-full"
                            )}
                          >
                            <h3 className="text-xl font-semibold">
                              {job.title}
                            </h3>
                            {!isCompleted && (
                              <p className="text-sm text-muted-foreground">
                                {job.description}
                              </p>
                            )}
                          </div>
                          <Badge
                            className={cn(
                              "flex gap-2 items-center text-sm font-medium",
                              {
                                "bg-muted/40 text-muted-foreground": !status,
                                "bg-green-500/20 text-green-500":
                                  status === OperationJobStatus.SUCCESS,
                                "bg-blue-500/20 text-blue-500":
                                  status ===
                                  OperationJobStatus.RECEIVED_AND_PROCESSING,
                                "bg-red-500/20 text-red-500":
                                  status === OperationJobStatus.FAILED,
                              }
                            )}
                          >
                            {status === OperationJobStatus.SUCCESS ? (
                              <CheckCircle className="h-4 w-4 mr-1" />
                            ) : status ===
                              OperationJobStatus.RECEIVED_AND_PROCESSING ? (
                              <Loader2 className="h-4 w-4 mr-1 animate-spin" />
                            ) : (
                              <AlertCircle className="h-4 w-4 mr-1" />
                            )}
                            {status ? friendlyJobStatus[status] : "Not Started"}
                          </Badge>
                        </div>
                        {jobStatusMap[job.type]?.errors && (
                          <div className="mt-4 flex flex-col gap-4">
                            {jobStatusMap[job.type]?.errors?.map(
                              (error, index) => (
                                <div
                                  key={index}
                                  className="flex gap-2 items-center"
                                >
                                  <AlertCircle className="h-4 w-4 mr-1 text-red-500" />
                                  <p className="text-sm text-red-500">
                                    {error.friendlyMessage || error.message}
                                  </p>
                                </div>
                              )
                            )}
                          </div>
                        )}
                        {status &&
                          !isCompleted &&
                          (job.type === OperationJobType.INITIAL_SYNC_ORDERS ||
                            job.type ===
                              OperationJobType.INITIAL_SYNC_TIKTOK_SKU_PERIODS) && (
                            <div className="mt-4 flex flex-col gap-4">
                              {job.type === OperationJobType.INITIAL_SYNC_ORDERS
                                ? jobStatusMap[
                                    job.type
                                  ]?.userJobData?.syncOrdersStatusData?.map(
                                    (shopData, index) => (
                                      <div key={index}>
                                        <p className="text-sm font-medium">
                                          {shopData.shopName}
                                        </p>
                                        <div className="mt-2 bg-gray-200 h-2 rounded-full overflow-hidden">
                                          <div
                                            className={cn(
                                              "h-full rounded-full",
                                              job.buttonClassNames
                                            )}
                                            style={{
                                              width: `${
                                                (shopData.processedOrderCount /
                                                  shopData.totalOrderCount) *
                                                100
                                              }%`,
                                            }}
                                          ></div>
                                        </div>
                                        <div className="flex justify-between text-xs text-muted-foreground mt-1">
                                          <p>
                                            {shopData.processedOrderCount} /{" "}
                                            {shopData.totalOrderCount} orders
                                          </p>
                                          <p>
                                            Estimated time left:{" "}
                                            {moment()
                                              .add(
                                                ((shopData.totalOrderCount -
                                                  shopData.processedOrderCount) /
                                                  50) *
                                                  10,
                                                "seconds"
                                              )
                                              .fromNow(true)}
                                          </p>
                                        </div>
                                      </div>
                                    )
                                  )
                                : jobStatusMap[
                                    job.type
                                  ]?.userJobData?.syncSkuPeriodsStatusData?.map(
                                    (shopData, index) => (
                                      <div key={index}>
                                        <p className="text-sm font-medium">
                                          {shopData.shopName}
                                        </p>
                                        <div className="mt-2 bg-gray-200 h-2 rounded-full overflow-hidden">
                                          <div
                                            className={cn(
                                              "h-full rounded-full",
                                              job.buttonClassNames
                                            )}
                                            style={{
                                              width: `${
                                                (shopData.processedSettledOrdersCount /
                                                  shopData.totalSettledOrdersCount) *
                                                100
                                              }%`,
                                            }}
                                          ></div>
                                        </div>
                                        <div className="flex justify-between text-xs text-muted-foreground mt-1">
                                          <p>
                                            Analysing{" "}
                                            {
                                              shopData.processedSettledOrdersCount
                                            }{" "}
                                            / {shopData.totalSettledOrdersCount}{" "}
                                            orders
                                          </p>
                                          {shopData.processedSettledOrdersCount !==
                                            shopData.totalSettledOrdersCount && (
                                            <p>
                                              Estimated time left:{" "}
                                              {moment()
                                                .add(
                                                  (shopData.totalSettledOrdersCount -
                                                    shopData.processedSettledOrdersCount) *
                                                    0.5,
                                                  "seconds"
                                                )
                                                .fromNow(true)}
                                            </p>
                                          )}
                                        </div>
                                        <div className="text-orange-500 text-xs text-muted-foreground mt-4 flex gap-2 items-center">
                                          <InfoIcon className="h-4 w-4 mr-2" />
                                          <p>
                                            This can take several hours for
                                            large catalogs. We're importing all
                                            your settlements for each order and
                                            applying predictive analytics to
                                            each SKU to help us predict figures
                                            like fulfilment costs, affiliate
                                            commission fee's, tax, and much
                                            more.
                                          </p>
                                        </div>
                                      </div>
                                    )
                                  )}
                              {job.type === OperationJobType.INITIAL_SYNC_TIKTOK_SKU_PERIODS && 
                               jobStatusMap[job.type]?.userJobData?.lastResetPing && (
                                <div className="mt-4">
                                  <p className="text-sm font-medium mb-2">
                                    Aggregating data
                                  </p>
                                  <div className="mt-2 bg-gray-200 h-2 rounded-full overflow-hidden">
                                    <AnimatedProgress />
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        {status &&
                          !isCompleted &&
                          job.type !== OperationJobType.INITIAL_SYNC_ORDERS &&
                          job.type !==
                            OperationJobType.INITIAL_SYNC_TIKTOK_SKU_PERIODS && (
                            <div className="mt-4">
                              <div className="mt-2 bg-gray-200 h-2 rounded-full overflow-hidden">
                                <div
                                  className={cn(
                                    "h-full rounded-full",
                                    job.buttonClassNames
                                  )}
                                  style={{
                                    width: `${progress}%`,
                                  }}
                                ></div>
                              </div>
                              <p className="text-xs text-right text-muted-foreground mt-1">
                                {progress}% Complete
                              </p>
                            </div>
                          )}
                        {!status && isStartable && (
                          <_Button
                            onClick={() => handleStartJob(job.type)}
                            loading={
                              kickingOffJob && activeJobType === job.type
                            }
                            className={cn("mt-4 w-full", job.buttonClassNames)}
                            variant={"secondary"}
                          >
                            <>
                              <PlayIcon className="h-4 w-4 mr-2" />
                              Import
                            </>
                          </_Button>
                        )}
                        {canRetry && (
                          <div className="flex flex-col gap-2">
                            <div className="text-sm text-red-500 flex gap-2 items-center">
                              <AlertTriangleIcon className="h-4 w-4 mr-2" />
                              You can retry this job if its stuck.
                            </div>
                            <_Button
                              onClick={() => handleRetryJob(job.type)}
                              loading={
                                retryingJob && activeJobType === job.type
                              }
                              className={cn(
                                "mt-4 w-full",
                                retryingJob && activeJobType === job.type
                                  ? ""
                                  : "bg-red-500 hover:bg-red-600"
                              )}
                              variant={"secondary"}
                            >
                              <>
                                <RefreshCcwIcon className="h-4 w-4 mr-2" />
                                {retryingJob && activeJobType === job.type
                                  ? "Retrying..."
                                  : "Retry"}
                              </>
                            </_Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </LoadingFallback>
                </div>
              );
            })}
            <div className="w-full max-w-3xl mx-auto py-4 flex flex-col justify-center items-center gap-2 text-sm text-muted-foreground">
              <InfoIcon className="h-4 w-4 mr-2" />
              <div className="w-full max-w-xl text-center">
                You can close this page at any time and check back in later.
                Make sure to remember to hit "start" on each import process as
                the previous completes.
              </div>
            </div>
            {allJobsCompleted && (
              <div className="flex justify-end">
                <_Button
                  onClick={handleCompleteSetup}
                  loading={completingSetup}
                  className="mt-4 w-[300px] h-[70px] text-lg flex gap-4 items-center justify-between bg-green-500/50 hover:bg-green-600"
                  variant={"secondary"}
                >
                  Dashboard
                  <ChevronRightIcon className="h-4 w-4 ml-2" />
                </_Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
