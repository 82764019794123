import { Currency } from "@/types/Currency";

import { TiktokOrder, TiktokOrderLineItem } from "@/types/TikTokOrder";
import { OrderLineItemTableValue } from "./order-line-item-table-value";

export const orderLineItemFieldMappings: Record<
  string,
  {
    header: string;
    order: number;
    isNotCurrency?: boolean;
    isSubtract?: boolean;
    isNeutral?: boolean;
    estimated?: boolean;
    isPercentage?: boolean;
    comingSoon?: boolean;
    size?: "lg";
    customRender?: (order: TiktokOrder, lineItem: TiktokOrderLineItem, currency: Currency) => React.ReactNode;
  }
> = {
  margin: {
    header: "Net Margin",
    order: 0.75,
    isPercentage: true,
    isNeutral: true,
  },
  totalGrossSales: {
    header: "Gross Sales",
    order: 1,
    isNeutral: true,
  },
  totalMerchandiseSales: {
    header: "Net Sales",
    order: 2,
    isNeutral: true,
  },
  estimatedNetTax: {
    header: "Net Tax",
    order: 2.5,
    estimated: true,
    isSubtract: true,
  },
  estimatedTax: {
    header: "Tax",
    order: 3,
    estimated: true,
    isSubtract: true,
  },
  estimatedTaxBack: {
    header: "Tax Back",
    order: 3.5,
    estimated: true,
  },
  potentialAffiliateCommission: {
    header: "Affiliates",
    order: 5,
    isSubtract: true,
    estimated: true,
  },
  costOfGoods: {
    header: "COGS",
    order: 6,
    isSubtract: true,
  },
  estimatedFbmShippingCost: {
    header: "FBM Shipping",
    order: 8,
    isSubtract: true,
    estimated: true,
  },
  estimatedFbtShippingCost: {
    header: "FBT Shipping",
    order: 9,
    isSubtract: true,
    estimated: true,
  },
  totalEstimatedFbtFulfilmentAndPackagingFees: {
    header: "FBT Fulfill. & Packaging",
    order: 10,
    isSubtract: true,
    estimated: true,
  },
  sellerShippingCost: {
    header: "Seller Shipping",
    order: 11,
    isSubtract: true,
  },
  estimatedPlatformFee: {
    header: "TikTok Commiss. Fee",
    order: 12,
    isSubtract: true,
    estimated: true,
  },
  netProfits: {
    header: "Net Profits",
    order: 13,
    size: "lg",
    customRender: (order: TiktokOrder, lineItem: TiktokOrderLineItem, currency: Currency) => {
      const netProfitExcAff = 
        lineItem.mergoioData.netProfits - 
        lineItem.mergoioData.potentialAffiliateCommission;
      const netProfitIncAff = lineItem.mergoioData.netProfits;
      return (
        <>
        <div className="text-left justify-start flex gap-2 items-center">
          <OrderLineItemTableValue
            mappings={{
              ...orderLineItemFieldMappings,
              netProfits: {
                ...orderLineItemFieldMappings.netProfits,
                customRender: undefined,
              },
            }}
            fieldKey="netProfits"
            value={netProfitExcAff}
            order={order}
            lineItem={lineItem}
            currency={currency}
          />
          <div className="text-foreground/70">to</div>
          <OrderLineItemTableValue
            mappings={{
              ...orderLineItemFieldMappings,
              netProfits: {
                ...orderLineItemFieldMappings.netProfits,
                customRender: undefined,
              },
            }}
            fieldKey="netProfits"
            value={netProfitIncAff}
            order={order}
            lineItem={lineItem}
            currency={currency}
          />
          </div>
          <p className="text-foreground/40 text-xs">
            with affiliate / without affiliate
          </p>
        </>
      );
    },
  },
};