import axios from "axios";
import {useContext} from "react";
import {UserStateContext} from "@/contexts/UserStateProvider";
import { AdminStateContext } from "@/contexts/AdminStateProvider";

export interface ApiResponse {
    success: boolean;
    userError?: boolean;
    errorMessage?: string;
    content?: any;
}

const BASE_URL = process.env.REACT_APP_MERGOIO_API_URL || "http://localhost:8000"

export const useRequest = () => {
    const {isAuthenticated: isAdminAuthenticated, isUserViewing} = useContext(AdminStateContext)
    const {logOut, isAuthenticated} = useContext(UserStateContext)
    const {logOut: logoutAdmin} = useContext(AdminStateContext)
    //const {dispatchNotificationUpdate} = useContext(NotificationsContext)

    const request = async (payload: {
        url: string,
        method: "POST" | "GET" | "DELETE" | "PUT",
        headers?: Record<string, string>,
        data?: any | null,
        params?: Record<string, string>,
    }): Promise<ApiResponse> => {
        let token = ""
        if (isAdminAuthenticated && !isUserViewing) {
            token = localStorage.getItem("admin-token") || ""
        } else {
            token = localStorage.getItem("token") || ""   
        }


        let fullUrl = BASE_URL + payload.url;
        if (payload.params) {
            const searchParams = new URLSearchParams(payload.params);
            fullUrl += `?${searchParams.toString()}`;
        }

        const response = await fetch(fullUrl, {
            method: payload.method,
            body: payload.data instanceof FormData
                ? payload.data
                : payload.data ? JSON.stringify(payload.data) : undefined,
            headers: {
                ...payload.headers,
                "Authorization": "Bearer " + token
            },
        })

        if (!response) {
            return {success: false, errorMessage: "Something went wrong"}
        }

        if (response.status === 401) {
            logOut()
            logoutAdmin()
            return {success: false, errorMessage: "Session expired, redirecting ..."}
        }

        let json;
        try {
            json = await response.json()
        } catch (e) {
            console.log("Failed to parse",e)
            // ERRCODE: JPF
            // JSON PARSE FAILED
            json = null
        }

        if(json) {
            if (json.status === "failed") {
                return {success: false, userError: true, errorMessage: json.message}
            } else if (response.status !== 200) {
                return {success: false, userError: false, errorMessage:
                        json.message
                            ? json.message
                            : "Something went wrong. Please contact support."}
            }
        }

        return {success: true, content: json}
    }

    const streamRequest = async (payload: {
        url: string,
        method: "POST" | "GET",
        headers?: Record<string, string>,
        data?: any | null,
    }): Promise<ReadableStream<Uint8Array> | null> => {
        const localStorageUserToken = localStorage.getItem("token")

        const response = await fetch(BASE_URL + payload.url, {
            method: payload.method,
            body: payload.data instanceof FormData
                ? payload.data
                : JSON.stringify(payload.data),
            headers: {
                ...payload.headers,
                "Authorization": "Bearer " + localStorageUserToken,
                "Content-Type": "application/json",
            },
        })

        if (!response.ok) {
            if (response.status === 401) {
                logOut()
                throw new Error("Session expired, redirecting ...")
            }
            throw new Error(`HTTP error! status: ${response.status}`)
        }

        return response.body
    }

    return {
        request,
        streamRequest
    }
}