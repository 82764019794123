import { useEffect, useMemo } from "react";
import { useContext } from "react";
import { Truck, Info } from "lucide-react";
import { useLiteSettledDashboardStatistics } from "@/hooks/tiktokSkuSettledPeriod.hooks";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { collectSettledTotals } from "@/lib/utils";
import { ChartTile } from "@/types/ChartTile";
import { Badge } from "@/components/ui/badge";
import { ChartTileOverview } from "@/components/chart-tile";

export default function ShippingOverviewTile({
  selectedDateRange,
  fromDate,
  toDate,
  headerExtra,
  extraRightHeader,
  overrideTiles,
}: {
  selectedDateRange: string;
  fromDate: Date;
  toDate: Date;
  headerExtra?: React.ReactNode;
  extraRightHeader?: React.ReactNode;
  overrideTiles?: {
    dataKey: string;
    gridClass: string;
    hide?: boolean;
    color?: string;
    chartColor?: string;
  }[];
}) {
  const { data: liteSettledDashboardStatistics, isLoading: isSettledLoading } =
    useLiteSettledDashboardStatistics(
      fromDate,
      toDate,
      true,
      undefined,
      "byStatementTime"
    );
  const { currency, swapChartsOnDashboard } = useContext(UserStateContext);

  const settledTotals = useMemo(
    () => collectSettledTotals(liteSettledDashboardStatistics),
    [liteSettledDashboardStatistics]
  );

  const dashboardTiles: ChartTile[] = [
    {
      title: "FBT Shipping",
      tooltip: "Total FBT shipping cost for settled orders.",
      value:
        settledTotals && settledTotals.settledEstimatedFbtShippingCost
          ? `${currency.symbol}${settledTotals.settledEstimatedFbtShippingCost?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}`
          : `${currency.symbol}0.00`,
      icon: Truck,
      color: "from-blue-400 to-blue-600",
      chartColor: "#3B82F6",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "settledEstimatedFbtShippingCost",
      gridClass: "col-span-2",
    },
    {
      title: "FBT Fulfilment & Packaging",
      tooltip: "Total FBT fulfilment and packaging fees for settled orders.",
      value:
        settledTotals && settledTotals.settledEstimatedFbtFulfilmentAndPackagingFees
          ? `${currency.symbol}${settledTotals.settledEstimatedFbtFulfilmentAndPackagingFees?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}`
          : `${currency.symbol}0.00`,
      icon: Truck,
      color: "from-blue-400 to-blue-600", 
      chartColor: "#3B82F6",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "settledEstimatedFbtFulfilmentAndPackagingFees",
      gridClass: "col-span-2",
    },
    {
      title: "FBM Shipping",
      tooltip: "Total FBM shipping cost for settled orders.",
      value:
        settledTotals && settledTotals.settledEstimatedFbmShippingCost
          ? `${currency.symbol}${settledTotals.settledEstimatedFbmShippingCost?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}`
          : `${currency.symbol}0.00`,
      icon: Truck,
      color: "from-blue-400 to-blue-600",
      chartColor: "#3B82F6", 
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "settledEstimatedFbmShippingCost",
      gridClass: "col-span-2",
    },
    {
      title: "Seller-Fulfilled Shipping",
      tooltip: "Total seller-fulfilled shipping cost for settled orders.",
      value:
        settledTotals && settledTotals.settledEstimatedSellerShippingCost
          ? `${currency.symbol}${settledTotals.settledEstimatedSellerShippingCost?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}`
          : `${currency.symbol}0.00`,
      icon: Truck,
      color: "from-blue-400 to-blue-600",
      chartColor: "#3B82F6",
      format: "currency", 
      dataLabel: "~ ESTIMATED",
      dataKey: "settledEstimatedSellerShippingCost",
      gridClass: "col-span-2",
    },
  ].filter((tile) => {
    if (!overrideTiles) return true;
    const override = overrideTiles.find((o) => o.dataKey === tile.dataKey);
    if (!override) return false;
    tile.color = override.color || tile.color;
    tile.chartColor = override.chartColor || tile.chartColor;
    tile.gridClass = override.gridClass;
    return true;
  });

  return (
    <div className="space-y-2">
      <div className="mb-4">
        <div className="flex justify-between gap-4 items-center">
          <div className="flex items-center gap-4">
            <h3 className="text-lg font-semibold text-foreground">
              Shipping Overview
            </h3>
            {headerExtra}
          </div>
          <div className="flex items-center gap-4">
            {extraRightHeader && extraRightHeader}
          </div>
        </div>
      </div>

      <div className="pb-10 grid grid-cols-4 gap-4">
        {dashboardTiles.map((tile, index) =>
          tile.hide ? null : (
            <ChartTileOverview
              key={index}
              tile={tile}
              swapCharts={swapChartsOnDashboard}
              isLoading={isSettledLoading}
              isSettledLoading={isSettledLoading}
              combinedData={liteSettledDashboardStatistics}
            />
          )
        )}
      </div>
    </div>
  );
}
