import { Currency } from "@/types/Currency";

import { TiktokOrder } from "@/types/TikTokOrder";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { AlertTriangle, Info, TriangleAlertIcon } from "lucide-react";
import { TooltipProvider } from "@/components/ui/tooltip";
import { EstimationTableValueView } from "./estimation-table-value";
import moment from "moment";

export type OrderMapping = {
  header: string;
  order: number;
  isChildrenAccordion?: boolean;
  isNotCurrency?: boolean;
  isSubtract?: boolean;
  isNeutral?: boolean;
  estimated?: boolean;
  isPercentage?: boolean;
  comingSoon?: boolean;
  tooltip?: string;
  extraDetail?: (order: TiktokOrder, currency: Currency) => React.ReactNode;
  customRender?: (order: TiktokOrder, currency: Currency) => React.ReactNode;
  children?: Record<string, OrderMapping>;
};

export const orderMappings: Record<string, OrderMapping> = {
  netUnits: {
    header: "Net Units",
    order: 0.5,
    isNotCurrency: true,
    isNeutral: true,
    tooltip: "The total number of units sold excluding cancelled units.",
    isChildrenAccordion: true,
    children: {
      units: {
        header: "Units",
        order: 0.5,
        isNotCurrency: true,
        isNeutral: true,
        tooltip: "Total number of units",
      },
      cancelledUnits: {
        header: "Cancelled Units",
        order: 1,
        isNotCurrency: true,
        isSubtract: true,
        tooltip: "Total number of units cancelled",
      },
    },
  },
  totalGrossSales: {
    header: "Net Gross Sales",
    order: 1,
    isNeutral: true,
    tooltip: "Net gross sales before any discounts.",
    children: {
      grossSales: {
        isChildrenAccordion: true,
        header: "Gross Sales",
        order: 1,
        isNeutral: true,
        tooltip: "Gross sales before any discounts excludes shipping fees.",
        customRender: (order: TiktokOrder, currency: Currency) => {
          return (
            <EstimationTableValueView
              fieldConfig={{
                ...orderMappings.totalGrossSales,
              }}
              value={
                (order?.mergoioData.totalGrossSales ?? 0) -
                (order?.mergoioData.totalGrossSalesRefundAmount ?? 0)
              }
              currency={currency}
            />
          );
        },
        children: {
          payment: {
            header: "Customer Payment",
            order: 2,
            tooltip: "Payment received from the customer",
            customRender: (order: TiktokOrder, currency: Currency) => {
              return (
                <EstimationTableValueView
                  fieldConfig={{
                    ...orderMappings.totalGrossSales,
                    isNeutral: false,
                  }}
                  value={order?.payment ?? 0}
                  currency={currency}
                />
              );
            },
          },
          shippingSalePrice: {
            header: "Buyers Shipping Fee",
            order: 2,
            tooltip: "Shipping fees paid by the buyer",
            customRender: (order: TiktokOrder, currency: Currency) => {
              return (
                <EstimationTableValueView
                  fieldConfig={{
                    ...orderMappings.totalGrossSales,
                    isNeutral: false,
                  }}
                  value={-(order?.shippingSalePrice ?? 0)}
                  currency={currency}
                />
              );
            },
          },
          // sellerTiktokPaidShipping: {
          //   header: "Tiktok/Seller-Paid Shipping",
          //   order: 2,
          //   tooltip: "Shipping fee's paid by the platform or seller",
          //   customRender: (order: TiktokOrder, currency: Currency) => {
          //     return (
          //       <EstimationTableValueView
          //         fieldConfig={{
          //           ...orderMappings.totalGrossSales,
          //           isNeutral: false
          //         }}
          //         value={(order?.shippingFeeDeductionPlatform + order?.shippingFeeDeductionSeller ?? 0)}
          //         currency={currency}
          //       />
          //     );
          //   },
          // },
          tiktokDiscounts: {
            header: "Platform Discounts",
            order: 2,
            tooltip: "Discounts applied by the platform",
            customRender: (order: TiktokOrder, currency: Currency) => {
              return (
                <EstimationTableValueView
                  fieldConfig={{
                    ...orderMappings.totalGrossSales,
                    isNeutral: false,
                  }}
                  value={order?.subtotalDeductionPlatform ?? 0}
                  currency={currency}
                />
              );
            },
          },
          sellerDiscounts: {
            header: "Seller Discounts",
            order: 2,
            tooltip: "Discounts applied by the seller",
            customRender: (order: TiktokOrder, currency: Currency) => {
              return (
                <EstimationTableValueView
                  fieldConfig={{
                    ...orderMappings.totalGrossSales,
                    isNeutral: false,
                  }}
                  value={order?.subtotalDeductionSeller ?? 0}
                  currency={currency}
                />
              );
            },
          },
        },
      },
      totalGrossSalesRefundAmount: {
        header: "Refund Amount",
        order: 1.5,
        isSubtract: true,
        tooltip: "Total revenue from settled orders refunded",
      },
    },
  },
  totalMerchandiseSales: {
    header: "Net Sales",
    order: 2,
    isNeutral: true,
    isChildrenAccordion: true,
    tooltip:
      "Net revenue amount after seller discounts are deducted.",
    children: {
      customerPayment: {
        header: "Customer Payment",
        order: 2,
        tooltip: "Payment received from the customer",
        customRender: (order: TiktokOrder, currency: Currency) => {
          return (
            <EstimationTableValueView
              fieldConfig={{
                ...orderMappings.totalMerchandiseSales,
                isNeutral: false,
              }}
              value={order?.payment ?? 0}
              currency={currency}
            />
          );
        },
      },
      shippingSalePrice: {
        header: "Buyers Shipping Fee",
        order: 2,
        tooltip: "Shipping fees paid by the buyer",
        customRender: (order: TiktokOrder, currency: Currency) => {
          return (
            <EstimationTableValueView
              fieldConfig={{
                ...orderMappings.totalGrossSales,
                isNeutral: false,
              }}
              value={-(order?.shippingSalePrice ?? 0)}
              currency={currency}
            />
          );
        },
      },
      // totalPlatformPaidShippingFee: {
      //   header: "Tiktok/Seller-Paid Shipping",
      //   order: 2,
      //   isNeutral: false,
      //   tooltip: "Shipping fee's paid by the platform",
      //   customRender: (order: TiktokOrder, currency: Currency) => {
      //     return (
      //       <EstimationTableValueView
      //         fieldConfig={{
      //           ...orderMappings.totalMerchandiseSales,
      //           isNeutral: false
      //         }}
      //         value={-(order?.shippingFeeDeductionPlatform + order?.shippingFeeDeductionSeller ?? 0)}
      //         currency={currency}
      //       />
      //     );
      //   },
      // },
      totalPlatformDiscounts: {
        header: "Platform Discounts",
        order: 2.5,
        isSubtract: true,
        tooltip: "Discounts applied by the platform",
        customRender: (order: TiktokOrder, currency: Currency) => {
          return (
            <EstimationTableValueView
              fieldConfig={{
                ...orderMappings.totalMerchandiseSales,
                isNeutral: false,
              }}
              value={order?.subtotalDeductionPlatform ?? 0}
              currency={currency}
            />
          );
        },
      },
      // sellerDiscounts: {
      //   header: "Seller Discounts",
      //   order: 2.75,
      //   isSubtract: true,
      //   tooltip: "Seller discounts",
      //   customRender: (order: TiktokOrder, currency: Currency) => {
      //     return (
      //       <EstimationTableValueView
      //         fieldConfig={{
      //           ...orderMappings.totalMerchandiseSales,
      //           isNeutral: false
      //         }}
      //         value={-(order?.subtotalDeductionSeller ?? 0)}
      //         currency={currency}
      //       />
      //     );
      //   },
      // },
      totalMerchandiseSalesRefundAmount: {
        header: "Refund Amount",
        order: 2.5,
        isSubtract: true,
        tooltip: "Refund amount.",
      },
    },
  },
  estimatedPlatformFee: {
    header: "TikTok Commiss. Fee",
    order: 2.05,
    isSubtract: true,
    estimated: true,
    tooltip:
      "Estimation of fees charged by the e-commerce platform for using their services.",
  },
  estimatedAffiliateCommission: {
    header: "Affiliates",
    order: 2.1,
    isSubtract: true,
    comingSoon: true,
    extraDetail: (order: TiktokOrder, currency: Currency) => {
      return (
        <div className="h-[75px] bg-muted/20 border-b flex flex-col justify-center">
          <div className="px-4 text-xs text-green-500 px-4 py-1 flex items-center gap-2">
            <TriangleAlertIcon className="w-3 h-3" />
            {order.aggregatedAffiliateCommissionStats?.percentageWithAffiliateCommission?.toFixed(
              0
            ) ?? 0}
            % of similar orders had affiliate commission (in last 90 days)
          </div>
          <div className="opacity-50 flex justify-between items-center py-1 px-4 pl-8">
            <span className="flex items-center gap-2">
              Potential Commission
              <TooltipProvider delayDuration={0}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Info
                      size={12}
                      className="text-muted-foreground opacity-50 hover:opacity-100 transition-opacity cursor-help"
                    />
                  </TooltipTrigger>
                  <TooltipContent className={"w-[200px]"}>
                    <p>Potential affiliate commission if eligible</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <div className="flex items-center gap-1 opacity-50 font-normal text-xs">
                <AlertTriangle className="w-3 h-3" />
                Estimated
              </div>
            </span>
            <span>
              <EstimationTableValueView
                fieldConfig={{
                  ...orderMappings.estimatedAffiliateCommission,
                  comingSoon: false,
                }}
                value={order.mergoioData.potentialAffiliateCommission}
                currency={currency}
              />
            </span>
          </div>
        </div>
      );
    },
    tooltip:
      "Estimated fees paid to affiliates. This is estimated based on your most recent settlement data.",
  },
  sellerShippingCost: {
    header: "Shipping",
    order: 2.5,
    estimated: true,
    isSubtract: true,
    tooltip:
      "Shipping fee's paid for orders managed, and fulfilled by the seller (you).",
    customRender: (order: TiktokOrder, currency: Currency) => {
      return (
        <EstimationTableValueView
          fieldConfig={{
            ...orderMappings.totalMerchandiseSales,
            isNeutral: false,
          }}
          value={
            (order?.mergoioData.estimatedFbmShippingCost ?? 0) +
            (order?.mergoioData.estimatedFbtShippingCost ?? 0) +
            (order?.mergoioData.sellerShippingCost ?? 0)
          }
          currency={currency}
        />
      );
    },
    children: {
      estimatedFbmShippingCost: {
        header: "FBM Shipping",
        order: 8,
        isSubtract: true,
        estimated: true,
        tooltip:
          "Shipping fee's paid for orders auto-shipped with TikTok, but fulfilled by the seller (Fulfilled by Merchant).",
      },
      estimatedFbtShippingCost: {
        header: "FBT Shipping",
        order: 9,
        isSubtract: true,
        estimated: true,
        tooltip:
          "Shipping fee's for orders fulfilled using Fulfilled by TikTok (FBT)",
      },
      sellerShippingCost: {
        header: "Seller Shipping",
        order: 11,
        isSubtract: true,
        tooltip:
          "Shipping fee's paid for orders managed, and fulfilled by the seller (you).",
      },
    },
  },
  totalEstimatedFbtFulfilmentAndPackagingFees: {
    header: "FBT Fulfillment & Packaging",
    order: 5.5,
    isSubtract: true,
    estimated: true,
    tooltip:
      "Estimated fee's paid to TikTok for the fulfillment of orders using Fulfilled by TikTok (FBT).",
  },
  costOfGoods: {
    header: "COGS",
    order: 6,
    isSubtract: true,
    tooltip:
      "Direct costs attributable to the production of the goods sold by a company.",
  },
  estimatedNetTax: {
    header: "Net Tax",
    order: 12,
    estimated: true,
    isSubtract: true,
    isChildrenAccordion: true,
    tooltip: "Total tax minus tax back on costs.",
    children: {
      estimatedTax: {
        header: "Tax",
        order: 12.1,
        estimated: true,
        isSubtract: true,
        tooltip:
          "Estimate of all taxes collected on sales, based on the sales figure.",
      },
      estimatedTaxBack: {
        header: "Tax Back",
        order: 12.2,
        estimated: true,
        tooltip: "Estimate of all taxes that can be reclaimed.",
      },
    },
  },
  netProfits: {
    header: "Net Profits",
    order: 13,
    tooltip:
      "Earnings after all costs and expenses have been deducted from revenue (tax back included in calculations).",
    extraDetail: (order: TiktokOrder, currency: Currency) => (
      <div className="h-[40px] opacity-50 flex justify-between items-center border-b py-1 px-4 pl-8 bg-muted/20">
        <span className="flex items-center gap-2">
          incl. potential affiliate commission
          <TooltipProvider delayDuration={0}>
            <Tooltip>
              <TooltipTrigger asChild>
                <Info
                  size={12}
                  className="text-muted-foreground opacity-50 hover:opacity-100 transition-opacity cursor-help"
                />
              </TooltipTrigger>
              <TooltipContent className={"w-[200px]"}>
                <p>Net profits if eligible for affiliate commission.</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </span>
        <span>
          <EstimationTableValueView
            fieldConfig={orderMappings.netProfits}
            value={
              order.mergoioData.netProfits +
              order.mergoioData.potentialAffiliateCommission
            }
            currency={currency}
          />
        </span>
      </div>
    ),
  },
  margin: {
    header: "Net Margin",
    order: 14,
    isPercentage: true,
    isNeutral: true,
    tooltip: "Net margin as a percentage of gross sales.",
    extraDetail: (order: TiktokOrder, currency: Currency) => (
      <div className="h-[40px] opacity-50 flex justify-between items-center border-b py-1 px-4 pl-8 bg-muted/20">
        <span className="flex items-center gap-2">
          incl. potential affiliate commission
          <TooltipProvider delayDuration={0}>
            <Tooltip>
              <TooltipTrigger asChild>
                <Info
                  size={12}
                  className="text-muted-foreground opacity-50 hover:opacity-100 transition-opacity cursor-help"
                />
              </TooltipTrigger>
              <TooltipContent className={"w-[200px]"}>
                <p>Net margin if eligible for affiliate commission.</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </span>
        <span>
          <EstimationTableValueView
            fieldConfig={orderMappings.margin}
            value={
              order.mergoioData.totalMerchandiseSales === 0
                ? 0
                : ((order.mergoioData.netProfits +
                    order.mergoioData.potentialAffiliateCommission) /
                    order.mergoioData.totalMerchandiseSales) *
                  100
            }
            currency={currency}
          />
        </span>
      </div>
    ),
  },
};
