import { Button } from "@/components/ui/button";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { useLiteDashboardStatistics } from "@/hooks/tiktokOrder.hooks";
import { collectTotals, combineTotals } from "@/lib/utils";
import { ChartTile } from "@/types/ChartTile";
import { DollarSign } from "lucide-react";
import { useContext, useMemo } from "react";
import { ChartTileOverview } from "../../../../components/chart-tile";
import { Badge } from "@/components/ui/badge";
import { useOrganisationPlan } from "@/hooks/organisation.hooks";

export const AdsOverviewTile = ({
  fromDate,
  toDate,
}: {
  fromDate: Date;
  toDate: Date;
}) => {
  const { data: organisationPlan } = useOrganisationPlan();
  const { data: liteDashboardStatistics, isLoading } =
    useLiteDashboardStatistics(fromDate, toDate);
  const { currency, isCumulative, swapChartsOnDashboard } =
    useContext(UserStateContext);

  const totals = useMemo(
    () => collectTotals(liteDashboardStatistics),
    [liteDashboardStatistics]
  );

  const combinedData = useMemo(
    () => combineTotals(liteDashboardStatistics, [], isCumulative),
    [liteDashboardStatistics, isCumulative]
  );

  const tiles: ChartTile[] = [
    {
      title: "Total Ad Spend",
      tooltip: "Total amount spent on advertising across all campaigns.",
      value:
        totals && totals.totalSpend
          ? `${currency.symbol}${totals.totalSpend?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : `${currency.symbol}0.00`,
      icon: DollarSign,
      color: "from-purple-400 to-purple-600",
      chartColor: "#9333EA",
      format: "currency",
      dataLabel: "LIVE",
      dataKey: "totalSpend",
      gridClass: "col-span-2",
      heightClass: "h-[200px]",
    },
  ];

  return (
    organisationPlan?.adSpendEnabled ? (
      <div className="w-full">
        <div className="w-full flex items-center flex-wrap justify-between mb-4">
          <h3 className="text-lg font-semibold text-foreground">
            Ads Overview
          </h3>
          <Badge className="bg-purple-500/20 text-purple-500">Live Data</Badge>
        </div>
        <div className="flex flex-col gap-4">
          {tiles.map((tile) => (
            <ChartTileOverview
              key={tile.title}
              tile={tile}
              swapCharts={swapChartsOnDashboard}
              isLoading={isLoading}
              isSettledLoading={false}
              combinedData={combinedData}
            />
          ))}
        </div>
      </div>
    ) : null
  );
};
