import { TiktokOrder, TiktokOrderLineItem } from "@/types/TikTokOrder";
import { cn } from "@/lib/utils";
import { Currency } from "@/types/Currency";
import { AlertTriangle } from "lucide-react";

export const OrderLineItemTableValue = ({
  mappings,
  fieldKey,
  value,
  order,
  lineItem,
  currency,
  hideOperators = false,
}: {
  mappings: Record<
    string,
    {
      header: string;
      isNotCurrency?: boolean;
      isNeutral?: boolean;
      isSubtract?: boolean;
      tooltip?: string;
      estimated?: boolean;
      isPercentage?: boolean;
      comingSoon?: boolean;
      customRender?: (
        order: TiktokOrder,
        lineItem: TiktokOrderLineItem,
        currency: Currency
      ) => React.ReactNode;
    }
  >;
  fieldKey: string;
  value: number;
  order: TiktokOrder;
  lineItem: TiktokOrderLineItem;
  currency: Currency;
  hideOperators?: boolean;
}) => {
  const config = mappings[fieldKey];
  if (config.customRender) {
    return <>{config.customRender(order, lineItem, currency)}</>;
  }
  const isNegative = config.isSubtract ? true : value < 0;
  const isPositive = value > 0;
  const isNeutral = config.isNeutral ?? value === 0;
  const isPercentage = config.isPercentage ?? false;
  return config.comingSoon ? (
    <div className="flex items-center gap-2 font-normal text-xs text-orange-500">
      <AlertTriangle className="shrink-0 w-3 h-3" />
      Coming Soon
    </div>
  ) : (
    <span
      className={cn(
        isNeutral
          ? isPercentage
            ? value > 0
              ? "text-green-500"
              : value < 0
              ? "text-red-500"
              : "text-foreground"
            : "text-foreground"
          : isNegative
          ? "text-red-500"
          : isPositive
          ? "text-green-500"
          : "text-foreground"
      )}
    >
      {!hideOperators && (isNeutral ? "" : isNegative ? "- " : isPositive ? "+ " : "")}
      {config.isNotCurrency
        ? Math.abs(value)
        : isPercentage
        ? `${value.toFixed(2)}%`
        : `${currency.symbol}${Math.abs(value).toFixed(2)}`}
    </span>
  );
};
