import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { ChevronDown, InfoIcon, Settings } from "lucide-react";
import { useContext } from "react";

export function ChartSettings() {
  const {
    isLineChart,
    setIsLineChart,
    isCumulative,
    setIsCumulative,
    showSettledData,
    setShowSettledData,
  } = useContext(UserStateContext);
  return (
    <div className="grid gap-4">
      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between">
          <Label htmlFor="chart-type" className="text-sm">
            Chart Type
          </Label>
          <Select
            value={isLineChart ? "line" : "bar"}
            onValueChange={(value) => setIsLineChart(value === "line")}
          >
            <SelectTrigger className="h-auto w-[120px]">
              <SelectValue placeholder="Select chart type" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="bar">Bar Chart</SelectItem>
              <SelectItem value="line">Line Chart</SelectItem>
            </SelectContent>
          </Select>
        </div>
        {/* <div className="flex items-center justify-between">
          <div className="flex flex-col">
            <Label htmlFor="cumulative-switch" className="text-sm">
              Cumulative
            </Label>
            <div className="text-sm text-muted-foreground">
              <InfoIcon className="inline-block mr-2" size={14} />
              When enabled shows settled amounts (where you have been paid)
              aggregated over time.
            </div>
          </div>
          <Switch
            id="cumulative-switch"
            checked={isCumulative}
            onCheckedChange={setIsCumulative}
          />
        </div> */}
        <div className="flex items-center justify-between">
          <Label htmlFor="settled-data-switch" className="text-sm">
            Show Settled Data
          </Label>
          <Switch
            id="settled-data-switch"
            checked={showSettledData}
            onCheckedChange={setShowSettledData}
          />
        </div>
      </div>
    </div>
  );
}
