import { TiktokOrder } from "@/types/TikTokOrder";
import { CalendarIcon } from "lucide-react";
import moment from "moment";

export const ShippingTimeline = ({ order }: { order: TiktokOrder }) => {
  return (
    <>
      <div className="space-y-1">
        <p className="flex items-center gap-2 text-sm text-muted-foreground">
          <CalendarIcon className="w-4 h-4 text-blue-500" />
          <span className="font-medium text-foreground">Collected:</span>{" "}
          {order.collectionTime ? moment(order.collectionTime).format("DD MMM YYYY [at] HH:mm") : "N/A"}
        </p>
        <p className="flex items-center gap-2 text-sm text-muted-foreground">
          <CalendarIcon className="w-4 h-4 text-orange-500" />
          <span className="font-medium text-foreground">Collection Due:</span>{" "}
          {order.collectionDueTime ? moment(order.collectionDueTime).format("DD MMM YYYY [at] HH:mm") : "N/A"}
        </p>
        <p className="flex items-center gap-2 text-sm text-muted-foreground">
          <CalendarIcon className="w-4 h-4 text-yellow-500" />
          <span className="font-medium text-foreground">Delivery Due:</span>{" "}
          {order.deliveryDueTime ? moment(order.deliveryDueTime).format("DD MMM YYYY [at] HH:mm") : "N/A"}
        </p>
        <p className="flex items-center gap-2 text-sm text-muted-foreground">
          <CalendarIcon className="w-4 h-4 text-purple-500" />
          <span className="font-medium text-foreground">Delivery Option Required:</span>{" "}
          {order.deliveryOptionRequiredDeliveryTime ? moment(order.deliveryOptionRequiredDeliveryTime).format("DD MMM YYYY [at] HH:mm") : "N/A"}
        </p>
        <p className="flex items-center gap-2 text-sm text-muted-foreground">
          <CalendarIcon className="w-4 h-4 text-indigo-500" />
          <span className="font-medium text-foreground">Delivery SLA:</span>{" "}
          {order.deliverySlaTime ? moment(order.deliverySlaTime).format("DD MMM YYYY [at] HH:mm") : "N/A"}
        </p>
        <p className="flex items-center gap-2 text-sm text-muted-foreground">
          <CalendarIcon className="w-4 h-4 text-cyan-500" />
          <span className="font-medium text-foreground">Fast Dispatch SLA:</span>{" "}
          {order.fastDispatchSlaTime ? moment(order.fastDispatchSlaTime).format("DD MMM YYYY [at] HH:mm") : "N/A"}
        </p>
        <p className="flex items-center gap-2 text-sm text-muted-foreground">
          <CalendarIcon className="w-4 h-4 text-cyan-500" />
          <span className="font-medium text-foreground">Shipped Time:</span>{" "}
          {order.rtsTime ? moment(order.rtsTime).format("DD MMM YYYY [at] HH:mm") : "N/A"}
        </p>
      </div>
    </>
  );
};
