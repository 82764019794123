import { TiktokOrder } from "@/types/TikTokOrder";
import { Mail } from "lucide-react";
import { MapPin } from "lucide-react";
import { Phone } from "lucide-react";
import { User } from "lucide-react";
import { BanIcon } from "lucide-react";

export const RecipientAddress = ({ order }: { order: TiktokOrder }) => {
  return (  
    !order.recipientAddress?.name ? (
        <div className="py-8 flex flex-col items-center justify-center text-muted-foreground">
          <BanIcon className="h-12 w-12 mb-4" />
          <p className="text-lg opacity-75">
            No address details available
          </p>
          <p className="text-sm opacity-50">
            Address details will appear here once the order is
            paid and confirmed
          </p>
        </div>
      ) : (
        <div className="px-4 pb-4 grid grid-cols-2 gap-4">
          <div>
            <p className="font-medium flex items-center gap-2">
              <User className="w-4 h-4" />
              {order.recipientAddress.name}
            </p>
            <p className="flex items-center gap-2">
              <Phone className="w-4 h-4" />
              {order.recipientAddress.phoneNumber}
            </p>
          </div>
          <div>
            <p className="flex items-center gap-2">
              <MapPin className="w-4 h-4" />
              {order.recipientAddress.addressLine1}
            </p>
            {order.recipientAddress.addressLine2 && (
              <p className="ml-6">
                {order.recipientAddress.addressLine2}
              </p>
            )}
            {order.recipientAddress.addressLine3 && (
              <p className="ml-6">
                {order.recipientAddress.addressLine3}
              </p>
            )}
            {order.recipientAddress.addressLine4 && (
              <p className="ml-6">
                {order.recipientAddress.addressLine4}
              </p>
            )}
            <p className="flex items-center gap-2">
              <Mail className="w-4 h-4" />
              {order.recipientAddress.postalCode}
            </p>
            {order.recipientAddress?.districtInfo?.map(
              (district, index) => (
                <p
                  key={index}
                  className="flex items-center gap-2"
                >
                  <MapPin className="w-4 h-4" />
                  {district.addressName}
                </p>
              )
            )}
          </div>
        </div>
      )
  );
};
