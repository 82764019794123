import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Menu,
  ArrowLeft,
  ImageIcon,
  Package,
  DollarSign,
  Info,
  TrendingUp,
  CreditCard,
  Percent,
  Truck,
  Receipt,
  FilterIcon,
  Edit,
  SettingsIcon,
  ChevronRight,
  BarChart,
  ShoppingCart,
  Users,
} from "lucide-react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { ModeToggle } from "@/components/mode-toggle";
import { Button } from "@/components/ui/button";
import { LoadingFallback } from "@/components/loading-fallback";
import { MyAccountDropdown } from "@/components/my-account-dropdown";
import { useProductById } from "@/hooks/product.hook";
import { CurrencySelect } from "@/components/currency-select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Product, Sku } from "@/types/Product";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn, getDateRange } from "@/lib/utils";
import { useLiteProductSkuStatistics } from "@/hooks/tiktokOrder.hooks";
import { ChartTileOverview } from "@/components/chart-tile";
import { LiteStatisticPeriod } from "@/types/LiteStatisticPeriod";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ChartSettingsPopover } from "@/components/chart-settings-popover";
import { SalesBySku } from "./components/sales-by-sku";
import { SalesAndProfit } from "./components/sales-and-profit/sales-and-profit";
import { CancellationInsights } from "./components/cancellation-insights";

export const ViewProductStatistics = () => {
  const navigate = useNavigate();
  const { isSidebarOpen, setIsSidebarOpen, currency } =
    useContext(UserStateContext);
  const { productId } = useParams<{ productId: string }>();
  const { product, isLoading, error } = useProductById(productId || "");

  const truncateString = (str: string, num: number) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };

  return (
    <div className="min-h-screen flex-1 flex flex-col overflow-hidden">
      <main className="flex-1 bg-background overflow-y-auto min-h-screen">
        <header className="border-b pt-6 pb-2 shadow-sm h-auto flex flex-wrap items-center gap-4 px-4 md:px-6">
          <div className="flex items-center gap-4 w-full md:w-auto">
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="md:hidden text-foreground"
            >
              <Menu size={24} />
            </button>
            <Button
              variant="ghost"
              className="flex items-center"
              onClick={() => window.history.back()}
            >
              <ArrowLeft size={20} className="mr-2" />
              Back
            </Button>
            <h2 className="text-xl font-semibold text-foreground">
              Product Statistics
            </h2>
          </div>
          <div className="flex items-center space-x-2 w-full md:w-auto md:ml-auto">
            <ChartSettingsPopover />
            <CurrencySelect />
            <MyAccountDropdown />
          </div>
        </header>

        <div>
          <LoadingFallback loading={isLoading}>
            {error ? (
              <div className="text-red-500">
                Error loading product: {error.message}
              </div>
            ) : (
              product && (
                <div>
                  <div className="px-6 py-4 flex items-center gap-6">
                    <div className="w-[100px] relative aspect-square overflow-hidden rounded-lg shadow-lg">
                      {product.mainImageUrl ? (
                        <>
                          <img
                            src={product.mainImageUrl || ""}
                            alt={product.title}
                            className="object-cover w-full h-full transform hover:scale-105 transition-transform duration-300"
                          />
                          {product.brandName && product.brandName !== "" && (
                            <div className="absolute light:text-white dark:text-black top-2 right-2 bg-primary text-white px-2 py-1 rounded-full text-xs font-semibold">
                              {product.brandName}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="w-full h-full aspect-square bg-muted rounded-lg shadow-sm flex items-center justify-center">
                          <ImageIcon className="w-8 h-8 text-gray-400" />
                        </div>
                      )}
                    </div>
                    <div className="w-full flex justify-between items-center gap-4">
                      <div className="w-[400px] flex flex-col gap-2">
                        <h1 className="text-lg font-medium">{truncateString(product.title, 50)}</h1>
                        {product.brandName && product.brandName !== "" && (
                          <p className="text-muted-foreground">
                            Brand: {product.brandName}
                          </p>
                        )}
                      </div>
                      <Button
                          className="w-[200px] flex gap-2 items-center justify-between"
                          variant="secondary"
                          onClick={() => navigate(`/products/${product.id}`)}
                        >
                          Manage Product
                          <ChevronRight size={12} />
                        </Button>
                    </div>
                  </div>

                  <Tabs defaultValue="sales" className="w-full px-6">
                    <TabsList>
                      <TabsTrigger value="sales">
                        <BarChart className="w-4 h-4 mr-2" />
                        Sales and Profit
                      </TabsTrigger>
                      <TabsTrigger value="sku">
                        <Package className="w-4 h-4 mr-2" />
                        Sales by SKU
                      </TabsTrigger>
                      <TabsTrigger value="cancellations">
                        <Users className="w-4 h-4 mr-2" />
                        Cancellation Insights
                      </TabsTrigger>
                    </TabsList>
                    <TabsContent value="sales">
                        <SalesAndProfit />
                    </TabsContent>
                    <TabsContent value="sku">
                        <SalesBySku />
                    </TabsContent>
                    <TabsContent value="cancellations">
                        <CancellationInsights />
                    </TabsContent>
                  </Tabs>
                </div>
              )
            )}
          </LoadingFallback>
        </div>
      </main>
    </div>
  );
};
