import { Currency } from "@/types/Currency";

import { TiktokOrder } from "@/types/TikTokOrder";
import { AlertTriangle, ChevronRight, Info } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../../components/ui/tooltip";
import { cn } from "@/lib/utils";
import { OrderMapping } from "./order-mappings";
import { useState } from "react";

export const EstimationTableValueView = ({
  fieldConfig,
  value,
  currency,
}: {
  fieldConfig: {
    comingSoon?: boolean;
    isNeutral?: boolean;
    isPercentage?: boolean;
    isNotCurrency?: boolean;
    isSubtract?: boolean;
  };
  value: number;
  currency: Currency;
}) => {
  const isNegative = value < 0;
  const isPositive = value > 0;
  const isNeutral = fieldConfig.isNeutral ?? value === 0;
  const isPercentage = fieldConfig.isPercentage ?? false;
  return fieldConfig.comingSoon ? (
    <div className="flex items-center gap-1 font-normal text-xs text-orange-500">
      <AlertTriangle className="shrink-0 w-3 h-3" />
      Coming Soon
    </div>
  ) : (
    <span
      className={cn(
        isNeutral
          ? isPercentage
            ? value > 0
              ? "text-green-500"
              : value < 0
              ? "text-red-500"
              : "text-foreground"
            : "text-foreground"
          : isNegative
          ? "text-red-500"
          : isPositive
          ? "text-green-500"
          : "text-foreground"
      )}
    >
      {isNeutral ? "" : isNegative ? "- " : isPositive ? "+ " : ""}
      {fieldConfig.isNotCurrency
        ? Math.abs(value)
        : isPercentage
        ? `${value.toFixed(2)}%`
        : `${currency.symbol}${Math.abs(value).toFixed(2)}`}
    </span>
  );
};

export const EstimationTableValue = ({
  mappings,
  fieldKey,
  value,
  order,
  currency,
}: {
  mappings: Record<string, OrderMapping>;
  fieldKey: string;
  value: number;
  order: TiktokOrder;
  currency: Currency;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const fieldConfig = mappings[fieldKey];
  if (!fieldConfig) return null;
  const extraDetail = fieldConfig.extraDetail
    ? fieldConfig.extraDetail(order, currency)
    : null;
  const customRender = fieldConfig.customRender
    ? fieldConfig.customRender(order, currency)
    : null;

  const hasChildren =
    fieldConfig.children && Object.keys(fieldConfig.children).length > 0;
  const isAccordion = fieldConfig.isChildrenAccordion && hasChildren;

  return (
    <>
      <div
        key={fieldKey}
        className={cn(
          "flex justify-between items-center border-b py-1 px-4",
          isAccordion && "cursor-pointer hover:bg-muted/10"
        )}
        onClick={() => isAccordion && setIsExpanded(!isExpanded)}
      >
        <span className="flex items-center gap-2">
          <TooltipProvider delayDuration={0}>
            <Tooltip>
              <TooltipTrigger asChild>
                <Info
                  size={12}
                  className="text-muted-foreground opacity-50 hover:opacity-100 transition-opacity cursor-help"
                />
              </TooltipTrigger>
              <TooltipContent className={"w-[200px]"}>
                <p>{fieldConfig.tooltip}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          {fieldConfig.header}
          {fieldConfig.estimated && (
            <div className="flex items-center gap-1 opacity-50 font-normal text-xs">
              <AlertTriangle className="w-3 h-3" />
              Estimated
            </div>
          )}
          {isAccordion && (
            <ChevronRight
              className={cn(
                "h-4 w-4 transition-transform",
                isExpanded && "transform rotate-90"
              )}
            />
          )}
        </span>
        {customRender ? (
          customRender
        ) : (
          <EstimationTableValueView
            fieldConfig={fieldConfig}
            value={value}
            currency={currency}
          />
        )}
      </div>
      {extraDetail}
      {fieldConfig.children &&
        (!fieldConfig.isChildrenAccordion || isExpanded) && (
          <div className="bg-muted/20 opacity-50 pl-4">
            {Object.entries(fieldConfig.children).map(
              ([childKey, childConfig]) => {
                const childValue =
                  order.mergoioData[
                    childKey as keyof typeof order.mergoioData
                  ] || 0;
                return (
                  <EstimationTableValue
                    key={childKey}
                    mappings={fieldConfig.children ?? {}}
                    fieldKey={childKey}
                    value={childValue}
                    order={order}
                    currency={currency}
                  />
                );
              }
            )}
          </div>
        )}
    </>
  );
};
