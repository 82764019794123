import React, { useContext, useState, useEffect } from "react";
import {
  Menu,
  Home,
  BarChart2,
  Settings,
  HelpCircle,
  ChevronDown,
  User,
  LogOut,
  ShoppingBag,
  Zap,
  ChevronRight,
  AlertCircle,
  DollarSign,
  TrendingUp,
  TrendingDown,
  Package,
  Users,
  ExternalLinkIcon,
  Award,
  ArrowUpRight,
  Sidebar,
  CheckIcon,
  XIcon,
  Info,
  SettingsIcon,
} from "lucide-react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { ModeToggle } from "@/components/mode-toggle";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import {
  Bar,
  BarChart,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  AreaChart,
  Area,
  RadialBarChart,
  PolarGrid,
  RadialBar,
  PolarRadiusAxis,
  Label,
} from "recharts";
import { Switch } from "@/components/ui/switch";
import { Badge } from "@/components/ui/badge";
import SalesOverviewTile from "./components/sales-overview-tile";
import CancellationsOverviewTile from "./components/cancellations-overview-tile";
import TopAffiliatesTile from "./components/top-affiliates-tile";
import { _Sidebar } from "@/components/layout/_sidebar";
import { MyAccountDropdown } from "@/components/my-account-dropdown";
import { CurrencySelect } from "@/components/currency-select";
import { TaxSettingsPopover } from "@/components/tax-settings-popover";
import { PlatformFeesConfigureButton } from "@/components/platformCommissionFees/platform-fees-configure-button";
import { TopProductsOverviewTile } from "./components/top-products-overview-tile";
import { TaxOverviewTile } from "./components/tax-overview-tile";
import { ChartSettingsPopover } from "../../../components/chart-settings-popover";
import { getDateRange } from "@/lib/utils";
import { OrderCount } from "@/components/order-count";
import { useStripeSubscriptionDetails } from "@/hooks/organisation.hooks";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { _Button } from "@/components/button";
import { Link, useNavigate } from "react-router-dom";
import { useMultipleLiteDashboardStatistics } from "@/hooks/tiktokOrder.hooks";
import PandLOverviewTile from "./components/pandl-overview-tile";
import { AdsOverviewTile } from "./components/ads-overview-tile";
import ShippingOverviewTile from "./components/shipping-overview-tile";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { DatePicker } from "@/components/ui/date-picker";
import moment from "moment";
import { SelectDateRange } from "@/components/select-date-range-component";

interface DateRangeDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  fromDate: Date | null;
  toDate: Date | null;
  onApplyDates: (fromDate: Date, toDate: Date) => void;
}

const DateRangeDialog = ({
  open,
  onOpenChange,
  fromDate: initialFromDate,
  toDate: initialToDate,
  onApplyDates,
}: DateRangeDialogProps) => {
  const [fromDate, setFromDate] = useState<Date | null>(initialFromDate);
  const [toDate, setToDate] = useState<Date | null>(initialToDate);
  const [errors, setErrors] = useState<{ fromDate?: string; toDate?: string }>(
    {}
  );

  const validate = () => {
    const newErrors: { fromDate?: string; toDate?: string } = {};

    if (!fromDate && toDate) {
      newErrors.fromDate = "Please select a start date";
    }
    if (!toDate && fromDate) {
      newErrors.toDate = "Please select an end date";
    }
    if (fromDate && toDate && moment(toDate).isBefore(moment(fromDate))) {
      newErrors.toDate = "End date must be after start date";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleApply = () => {
    if (!validate()) return;
    if (!fromDate || !toDate) return;
    onApplyDates(fromDate, toDate);
    onOpenChange(false);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="w-[625px]">
        <DialogHeader>
          <DialogTitle>Select Date Range</DialogTitle>
        </DialogHeader>
        <div className="w-full flex flex-col gap-10">
          <div>
            <Label>Date Range</Label>
            <div className="flex gap-4">
              <div className="w-full lg:w-1/2">
                <DatePicker
                  date={fromDate ?? undefined}
                  onDateChange={(date) => setFromDate(date ?? null)}
                  className={errors.fromDate ? "border border-red-500" : ""}
                />
                {errors.fromDate && (
                  <div className="text-red-500 text-sm mt-1">
                    {errors.fromDate}
                  </div>
                )}
              </div>
              <div className="w-full lg:w-1/2">
                <DatePicker
                  date={toDate ?? undefined}
                  onDateChange={(date) => setToDate(date ?? null)}
                  className={errors.toDate ? "border border-red-500" : ""}
                />
                {errors.toDate && (
                  <div className="text-red-500 text-sm mt-1">
                    {errors.toDate}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button type="submit" onClick={() => handleApply()}>
            Apply Dates
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export const Dashboard = () => {
  const {
    isSidebarOpen,
    setIsSidebarOpen,
    swapChartsOnDashboard,
    setSwapChartsOnDashboard,
  } = useContext(UserStateContext);
  const [selectedDateRange, setSelectedDateRange] = useState("14");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [totalSales, setTotalSales] = useState<number>(0);
  const [totalCogs, setTotalCogs] = useState<number>(0);
  const [dateRangeDialogOpen, setDateRangeDialogOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedDateRange !== "custom") {
      const { from, to } = getDateRange(selectedDateRange);
      setFromDate(from);
      setToDate(to);
    }
  }, [selectedDateRange]);

  const handleDateRangeSelect = (value: string) => {
    setSelectedDateRange(value);
  };

  const handleApplyCustomDates = (fromDate: Date, toDate: Date) => {
    console.log("fromDate", fromDate);
    setFromDate(fromDate);
    setToDate(toDate);
    setSelectedDateRange("custom");
  };

  return (
    <div className="min-h-screen flex-1 flex flex-col overflow-hidden">
      <main className="flex-1 bg-background overflow-y-auto min-h-screen">
        <header className="border-b pt-6 pb-4 shadow-sm h-auto flex flex-wrap items-center px-4 md:px-6">
          <div className="lg:w-1/2 w-full flex items-center gap-4">
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="md:hidden text-foreground"
            >
              <Menu size={24} />
            </button>
            <div className="max-w-3xl">
              <h2 className="text-xl font-semibold text-foreground">
                Sales & Profit
              </h2>
              <div className="flex items-start gap-4">
                <Info className="h-4 w-4 text-blue-500 mt-4 shrink-0" />
                <div className="text-sm text-muted-foreground">
                  Data is shown based on when orders were placed. Visit the{" "}
                  <Link
                    className="text-blue-500 underline hover:text-blue-600"
                    to="/analytics/settlements"
                  >
                    Settlement Analytics
                  </Link>{" "}
                  to view data based on when payments were settled in your seller account.
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 w-full flex items-center justify-end space-x-2">
            <_Button
              onClick={() => navigate("/settings")}
              className="w-auto flex items-center gap-2"
              variant="outline"
              size="sm"
            >
              <SettingsIcon className="w-4 h-4" />
              Settings
            </_Button>
            <ChartSettingsPopover />
            <CurrencySelect />
          </div>
        </header>

        <div className="mx-auto min-h-screen">
          <div className="px-6 py-6 border-b">
            <PandLOverviewTile />
          </div>
          <div className="h-max flex flex-col items-stretch lg:flex-row gap-6">
            {/* Left column: Sales Overview */}
            <div className="w-full lg:w-8/12 p-6 pr-0">
              {fromDate && toDate && (
                <div>
                  <SalesOverviewTile
                    headerExtra={
                      <div className="mr-2 flex items-center gap-4">
                        <SelectDateRange
                          onDateRangeChange={handleApplyCustomDates}
                          initialFromDate={fromDate}
                          initialToDate={toDate}
                          initialRange={selectedDateRange}
                        />
                      </div>
                    }
                    onTotalCogsChange={setTotalCogs}
                    onTotalSalesChange={setTotalSales}
                    selectedDateRange={selectedDateRange}
                    fromDate={fromDate}
                    toDate={toDate}
                  />
                  <ShippingOverviewTile
                    selectedDateRange={selectedDateRange}
                    fromDate={fromDate}
                    toDate={toDate}
                  />
                </div>
              )}
            </div>

            {/* Right column: Cancellation Overview, Top Affiliates, and Product Alerts */}
            <div className="w-full lg:w-4/12 border-l pt-6 px-6 flex flex-col gap-8">
              {fromDate && toDate && (
                <>
                  <AdsOverviewTile fromDate={fromDate} toDate={toDate} />
                  <TaxOverviewTile fromDate={fromDate} toDate={toDate} />
                  <CancellationsOverviewTile
                    totalCogs={totalCogs}
                    fromDate={fromDate}
                    toDate={toDate}
                  />
                  <TopProductsOverviewTile
                    fromDate={fromDate}
                    toDate={toDate}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
