import { LoadingFallback } from "@/components/loading-fallback";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import { useLiteProductStatistics } from "@/hooks/tiktokOrder.hooks";
import { Truck, Info, ChevronDown, ChevronUp } from "lucide-react";
import { useMemo, useContext, useState, useEffect } from "react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useLiteSettledDashboardStatistics } from "@/hooks/tiktokSkuSettledPeriod.hooks";
import {
  cn,
  collectSettledTotals,
  collectTotals,
  combineTotals,
} from "@/lib/utils";
import { ChartTile } from "@/types/ChartTile";
import { useParams } from "react-router-dom";
import { ChartTileOverview } from "@/components/chart-tile";
import { FbtFulfillmentFeesConfigureButton } from "@/components/fbtFulfillmentFees/fbt-fulfillment-fees-configure-button";

export default function ShippingOverviewTile({
  fromDate,
  toDate,
  productId,
}: {
  fromDate: Date;
  toDate: Date;
  productId: string;
}) {
  const { data: productStatisticsMap, isLoading } = useLiteProductStatistics(
    fromDate,
    toDate,
    productId ? [productId] : []
  );
  const { data: settledStatistics, isLoading: isSettledLoading } =
    useLiteSettledDashboardStatistics(fromDate, toDate, true, productId);

  const {
    swapChartsOnDashboard,
    setSwapChartsOnDashboard,
    currency,
    isCumulative,
  } = useContext(UserStateContext);

  const [isFbtExpanded, setIsFbtExpanded] = useState(false);
  const [isFbmExpanded, setIsFbmExpanded] = useState(false);
  const [isSellerExpanded, setIsSellerExpanded] = useState(false);

  const productStatistics = useMemo(() => {
    if (productId && productStatisticsMap && productStatisticsMap[productId]) {
      return productStatisticsMap[productId];
    }
    return [];
  }, [productId, productStatisticsMap]);

  const totals = useMemo(
    () => collectTotals(productStatistics),
    [productStatistics]
  );
  const settledTotals = useMemo(
    () => collectSettledTotals(settledStatistics),
    [settledStatistics]
  );
  const combinedData = useMemo(
    () => combineTotals(productStatistics, settledStatistics, isCumulative),
    [productStatistics, settledStatistics, isCumulative]
  );

  const [isFbtAllZero, setIsFbtAllZero] = useState(true);
  const [isFbmAllZero, setIsFbmAllZero] = useState(true);
  const [isSellerAllZero, setIsSellerAllZero] = useState(true);

  useEffect(() => {
    setIsFbtAllZero(
      !totals?.totalEstimatedFbtShippingCost &&
        !totals?.totalTiktokEstimatedFbtShippingCost &&
        !settledTotals?.settledEstimatedFbtShippingCost
    );

    setIsFbmAllZero(
      !totals?.totalEstimatedFbmShippingCost &&
        !totals?.totalTiktokEstimatedFbmShippingCost &&
        !settledTotals?.settledEstimatedFbmShippingCost
    );

    setIsSellerAllZero(
      !totals?.totalEstimatedSellerShippingCost &&
        !settledTotals?.settledEstimatedSellerShippingCost
    );

    setIsFbtExpanded(
      !!totals?.totalEstimatedFbtShippingCost ||
        !!totals?.totalTiktokEstimatedFbtShippingCost ||
        !!settledTotals?.settledEstimatedFbtShippingCost
    );

    setIsFbmExpanded(
      !!totals?.totalEstimatedFbmShippingCost ||
        !!totals?.totalTiktokEstimatedFbmShippingCost ||
        !!settledTotals?.settledEstimatedFbmShippingCost
    );

    setIsSellerExpanded(
      !!totals?.totalEstimatedSellerShippingCost ||
        !!settledTotals?.settledEstimatedSellerShippingCost
    );
  }, [totals, settledTotals]);

  const fbtShippingTiles: ChartTile[] = [
    {
      title: "Our Estimation",
      tooltip: "Uses our prediction model to estimate your FBT shipping cost.",
      value:
        totals && totals.totalEstimatedFbtShippingCost
          ? `${
              currency.symbol
            }${totals.totalEstimatedFbtShippingCost?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : `${currency.symbol}0.00`,
      change: 3,
      icon: Truck,
      color: "from-blue-400 to-blue-600",
      chartColor: "#3B82F6",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "totalEstimatedFbtShippingCost",
      gridClass: "col-span-2",
    },
    {
      title: "TikTok's Estimation",
      tooltip: "TikTok's estimation of your FBT shipping cost.",
      value:
        totals && totals.totalTiktokEstimatedFbtShippingCost
          ? `${
              currency.symbol
            }${totals.totalTiktokEstimatedFbtShippingCost?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}`
          : `${currency.symbol}0.00`,
      change: 3,
      icon: Truck,
      color: "from-blue-400 to-blue-600",
      chartColor: "#3B82F6",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "totalTiktokEstimatedFbtShippingCost",
      gridClass: "col-span-2",
    },
    {
      title: "Settled To Date",
      tooltip:
        "FBT shipping cost settled to date. Most accurate figure, lags behind live data by approx 3 - 30 days.",
      value:
        settledTotals && settledTotals.settledEstimatedFbtShippingCost
          ? `${
              currency.symbol
            }${settledTotals.settledEstimatedFbtShippingCost?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}`
          : `${currency.symbol}0.00`,
      change: 3,
      icon: Truck,
      color: "from-blue-400 to-blue-600",
      chartColor: "#3B82F6",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "INVALID",
      gridClass: "col-span-2",
      settledDataKey: "settledEstimatedFbtShippingCost",
    },
  ];

  const fbtFulfilmentAndPackagingTiles: ChartTile[] = [
    {
      title: "Our Estimation",
      tooltip:
        "Uses our prediction model to estimate your FBT fulfilment and packaging fees.",
      value:
        totals && totals.totalEstimatedFbtFulfilmentAndPackagingFees
          ? `${
              currency.symbol
            }${totals.totalEstimatedFbtFulfilmentAndPackagingFees?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}`
          : `${currency.symbol}0.00`,
      change: 3,
      icon: Truck,
      color: "from-blue-400 to-blue-600",
      chartColor: "#3B82F6",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "totalEstimatedFbtFulfilmentAndPackagingFees",
      gridClass: "col-span-3",
    },
    {
      title: "Settled To Date",
      subtitle: "Estimated",
      tooltip:
        "FBT fulfilment and packaging fees settled to date. TikTok does not share this data so we use our prediction model to estimate it for settled orders.",
      value:
        settledTotals &&
        settledTotals.settledEstimatedFbtFulfilmentAndPackagingFees
          ? `${
              currency.symbol
            }${settledTotals.settledEstimatedFbtFulfilmentAndPackagingFees?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}`
          : `${currency.symbol}0.00`,
      change: 3,
      icon: Truck,
      color: "from-blue-400 to-blue-600",
      chartColor: "#3B82F6",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "INVALID",
      gridClass: "col-span-3",
      settledDataKey: "settledEstimatedFbtFulfilmentAndPackagingFees",
    },
  ];

  const fbmShippingTiles: ChartTile[] = [
    {
      title: "Our Estimation",
      tooltip: "Uses our prediction model to estimate your FBM shipping cost.",
      value:
        totals && totals.totalEstimatedFbmShippingCost
          ? `${
              currency.symbol
            }${totals.totalEstimatedFbmShippingCost?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : `${currency.symbol}0.00`,
      change: 3,
      icon: Truck,
      color: "from-blue-400 to-blue-600",
      chartColor: "#3B82F6",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "totalEstimatedFbmShippingCost",
      gridClass: "col-span-2",
    },
    {
      title: "TikTok's Estimation",
      tooltip:
        "TikTok's estimation of your FBM shipping cost. Usually less accurate than our estimation.",
      value:
        totals && totals.totalTiktokEstimatedFbmShippingCost
          ? `${
              currency.symbol
            }${totals.totalTiktokEstimatedFbmShippingCost?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}`
          : `${currency.symbol}0.00`,
      change: 3,
      icon: Truck,
      color: "from-blue-400 to-blue-600",
      chartColor: "#3B82F6",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "totalTiktokEstimatedFbmShippingCost",
      gridClass: "col-span-2",
    },
    {
      title: "Settled To Date",
      tooltip:
        "FBM shipping cost settled to date. Most accurate figure, lags behind live data by approx 3 - 30 days.",
      value:
        settledTotals && settledTotals.settledEstimatedFbmShippingCost
          ? `${
              currency.symbol
            }${settledTotals.settledEstimatedFbmShippingCost?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}`
          : `${currency.symbol}0.00`,
      change: 3,
      icon: Truck,
      color: "from-blue-400 to-blue-600",
      chartColor: "#3B82F6",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "INVALID",
      gridClass: "col-span-2",
      settledDataKey: "settledEstimatedFbmShippingCost",
    },
  ];

  const sellerShippingTiles: ChartTile[] = [
    {
      title: "Our Estimation",
      tooltip:
        "Based on your shipping settings, we've estimated your seller-fulfilled shipping cost.",
      value:
        totals && totals.totalEstimatedSellerShippingCost
          ? `${
              currency.symbol
            }${totals.totalEstimatedSellerShippingCost?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}`
          : `${currency.symbol}0.00`,
      change: 3,
      icon: Truck,
      color: "from-blue-400 to-blue-600",
      chartColor: "#3B82F6",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "totalEstimatedSellerShippingCost",
      gridClass: "col-span-2",
    },
    {
      title: "Settled To Date",
      tooltip:
        "Seller-fulfilled shipping cost settled to date. Most accurate figure, lags behind live data by approx 3 - 30 days.",
      value:
        settledTotals && settledTotals.settledEstimatedSellerShippingCost
          ? `${
              currency.symbol
            }${settledTotals.settledEstimatedSellerShippingCost?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}`
          : `${currency.symbol}0.00`,
      change: 3,
      icon: Truck,
      color: "from-blue-400 to-blue-600",
      chartColor: "#3B82F6",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "INVALID",
      gridClass: "col-span-2",
      settledDataKey: "settledEstimatedSellerShippingCost",
    },
  ];

  return (
    <div className="space-y-2">
      <div className="mb-10 flex flex-col gap-4">
        <div className="flex items-center gap-4">
          <h3 className="text-lg font-semibold text-foreground">
            Shipping Overview
          </h3>
          <div className="flex items-center gap-2 text-blue-500">
            <Info className="h-3 w-3" />
            <p className="text-sm">
              Sample orders are excluded from total calculations.
            </p>
          </div>
        </div>
        <div className="text-sm text-muted-foreground">
          <p className="mb-2">
            We receive multiple types of shipping data from TikTok: their
            initial cost estimates and the actual settled costs that arrive 3-30
            days after orders complete.
          </p>
          <p>
            We've built our own prediction model that typically outperforms
            TikTok's estimates. Below you can compare our estimates, TikTok's
            estimates, and the final settled costs to see the differences in
            accuracy.
          </p>
        </div>
      </div>

      <div>
        <div
          className={cn(
            "flex flex-col gap-4",
            !isFbtExpanded && "border-b pb-1"
          )}
        >
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => setIsFbtExpanded(!isFbtExpanded)}
          >
            <h1 className="text-lg font-semibold text-foreground">
              FBT Shipping Estimation
            </h1>
            <div className="flex items-center gap-2">
              {isFbtAllZero || !totals?.totalEstimatedFbtShippingCost ? (
                <span className="text-lg font-semibold">
                  {currency.symbol}0.00
                </span>
              ) : (
                <span className="text-lg font-semibold">
                  {"£" +
                    (totals?.totalEstimatedFbtShippingCost).toLocaleString(
                      undefined,
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    )}
                </span>
              )}
              {isFbtExpanded ? (
                <ChevronUp size={20} />
              ) : (
                <ChevronDown size={20} />
              )}
            </div>
          </div>
          {isFbtExpanded && (
            <div className="pb-10 grid grid-cols-6 gap-4">
              {fbtShippingTiles.map((tile, index) => (
                <ChartTileOverview
                  key={index}
                  tile={tile}
                  swapCharts={swapChartsOnDashboard}
                  isLoading={isLoading}
                  isSettledLoading={isSettledLoading}
                  combinedData={combinedData}
                />
              ))}
            </div>
          )}
        </div>

        <div
          className={cn(
            "flex flex-col gap-4 mt-4",
            !isFbtExpanded && "border-b pb-1"
          )}
        >
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => setIsFbtExpanded(!isFbtExpanded)}
          >
            <div className="flex items-center gap-6">
              <h1 className="text-lg font-semibold text-foreground">
                FBT Fulfilment and Packaging Estimation
              </h1>
              <FbtFulfillmentFeesConfigureButton />
            </div>
            <div className="flex items-center gap-2">
              {!totals?.totalEstimatedFbtFulfilmentAndPackagingFees ? (
                <span className="text-lg font-semibold">
                  {currency.symbol}0.00
                </span>
              ) : (
                <span className="text-lg font-semibold">
                  {"£" +
                    (totals?.totalEstimatedFbtFulfilmentAndPackagingFees).toLocaleString(
                      undefined,
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    )}
                </span>
              )}
              {isFbtExpanded ? (
                <ChevronUp size={20} />
              ) : (
                <ChevronDown size={20} />
              )}
            </div>
          </div>
          {isFbtExpanded && (
            <div className="pb-10 grid grid-cols-6 gap-4">
              {fbtFulfilmentAndPackagingTiles.map((tile, index) => (
                <ChartTileOverview
                  key={index}
                  tile={tile}
                  swapCharts={swapChartsOnDashboard}
                  isLoading={isLoading}
                  isSettledLoading={isSettledLoading}
                  combinedData={combinedData}
                />
              ))}
            </div>
          )}
        </div>

        <div
          className={cn(
            "flex flex-col gap-4 mt-4",
            !isFbmExpanded && "border-b pb-1"
          )}
        >
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => setIsFbmExpanded(!isFbmExpanded)}
          >
            <h1 className="text-lg font-semibold text-foreground">
              FBM Shipping Estimation
            </h1>
            <div className="flex items-center gap-2">
              {isFbmAllZero || !totals?.totalEstimatedFbmShippingCost ? (
                <span className="text-lg font-semibold">
                  {currency.symbol}0.00
                </span>
              ) : (
                <span className="text-lg font-semibold">
                  {"£" +
                    (totals?.totalEstimatedFbmShippingCost).toLocaleString(
                      undefined,
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    )}
                </span>
              )}
              {isFbmExpanded ? (
                <ChevronUp size={20} />
              ) : (
                <ChevronDown size={20} />
              )}
            </div>
          </div>
          {isFbmExpanded && (
            <div className="pb-10 grid grid-cols-6 gap-4">
              {fbmShippingTiles.map((tile, index) => (
                <ChartTileOverview
                  key={index}
                  tile={tile}
                  swapCharts={swapChartsOnDashboard}
                  isLoading={isLoading}
                  isSettledLoading={isSettledLoading}
                  combinedData={combinedData}
                />
              ))}
            </div>
          )}
        </div>

        <div
          className={cn(
            "flex flex-col gap-4 mt-4",
            !isSellerExpanded && "border-b pb-1"
          )}
        >
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => setIsSellerExpanded(!isSellerExpanded)}
          >
            <h1 className="text-lg font-semibold text-foreground">
              Seller-Fulfilled Shipping Estimation
            </h1>
            <div className="flex items-center gap-2">
              {isSellerAllZero || !totals?.totalEstimatedSellerShippingCost ? (
                <span className="text-lg font-semibold">
                  {currency.symbol}0.00
                </span>
              ) : (
                <span className="text-lg font-semibold">
                  {"£" +
                    (totals?.totalEstimatedSellerShippingCost).toLocaleString(
                      undefined,
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    )}
                </span>
              )}
              {isSellerExpanded ? (
                <ChevronUp size={20} />
              ) : (
                <ChevronDown size={20} />
              )}
            </div>
          </div>
          {isSellerExpanded && (
            <div className="pb-32 grid grid-cols-4 gap-4">
              {sellerShippingTiles.map((tile, index) => (
                <ChartTileOverview
                  key={index}
                  tile={tile}
                  swapCharts={swapChartsOnDashboard}
                  isLoading={isLoading}
                  isSettledLoading={isSettledLoading}
                  combinedData={combinedData}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
