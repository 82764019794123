import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  collectTotals,
  collectSettledTotals,
  combineTotals,
} from "@/lib/utils";
import { useLiteProductStatistics } from "@/hooks/tiktokOrder.hooks";
import { useLiteSettledDashboardStatistics } from "@/hooks/tiktokSkuSettledPeriod.hooks";
import { useParams } from "react-router-dom";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { ChartTileOverview } from "@/components/chart-tile";
import { ChartTile } from "@/types/ChartTile";
import {
  DollarSign,
  Package,
  TrendingUp,
  CreditCard,
  Percent,
  Truck,
  Info,
} from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Switch } from "@/components/ui/switch";
import ShippingOverviewTile from "./shipping-overview-tile";
import { TaxOverviewTile } from "./tax-overview-tile";
import { SelectDateRange } from "@/components/select-date-range-component";

export const SalesAndProfit = () => {
  const { productId } = useParams<{ productId: string }>();
  const [fromDate, setFromDate] = useState<Date>(() => {
    const today = new Date();
    return new Date(today.setDate(today.getDate() - 14));
  });
  const [toDate, setToDate] = useState<Date>(new Date());
  const {
    currency,
    isLineChart,
    isCumulative,
    swapChartsOnDashboard,
    setSwapChartsOnDashboard,
    showSettledData,
  } = useContext(UserStateContext);

  const handleDateRangeChange = (from: Date, to: Date) => {
    setFromDate(from);
    setToDate(to);
  };

  const { data: productStatisticsMap, isLoading: productStatsLoading } =
    useLiteProductStatistics(fromDate!, toDate!, productId ? [productId] : []);

  const { data: settledStatistics, isLoading: settledStatsLoading } =
    useLiteSettledDashboardStatistics(fromDate!, toDate!, true, productId);

  const productStatistics = useMemo(() => {
    if (productId && productStatisticsMap && productStatisticsMap[productId]) {
      return productStatisticsMap[productId];
    }
    return [];
  }, [productId, productStatisticsMap]);

  const totals = useMemo(
    () => collectTotals(productStatistics),
    [productStatistics]
  );
  const settledTotals = useMemo(
    () => collectSettledTotals(settledStatistics),
    [settledStatistics]
  );
  const combinedData = useMemo(
    () => combineTotals(productStatistics, settledStatistics, isCumulative),
    [productStatistics, settledStatistics, isCumulative]
  );

  const productTiles: ChartTile[] = [
    // {
    //   title: "Gross Sales",
    //   subtitle: "exc. cancelled orders",
    //   tooltip: "Total gross sales before any discounts excludes shipping fees.",
    //   value: totals?.totalGrossSales
    //     ? `${currency.symbol}${totals.totalGrossSales.toLocaleString(
    //         undefined,
    //         { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    //       )}`
    //     : `${currency.symbol}0.00`,
    //   settledValue: settledTotals?.settledGrossSales
    //     ? `${currency.symbol}${settledTotals.settledGrossSales.toLocaleString(
    //         undefined,
    //         { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    //       )}`
    //     : `${currency.symbol}0.00`,
    //   icon: DollarSign,
    //   color: "from-green-400 to-green-600",
    //   chartColor: "#10B981",
    //   format: "currency",
    //   dataKey: "totalGrossSales",
    //   gridClass: "col-span-1",
    //   settledDataKey: "settledGrossSales",
    // },
    {
      title: "Net Sales",
      tooltip: "Net revenue amount after seller discounts are deducted.",
      value: totals?.totalMerchandiseSales
        ? `${currency.symbol}${totals.totalMerchandiseSales.toLocaleString(
            undefined,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          )}`
        : `${currency.symbol}0.00`,
      settledValue: settledTotals?.settledMerchandiseSales
        ? `${
            currency.symbol
          }${settledTotals.settledMerchandiseSales.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : `${currency.symbol}0.00`,
      icon: DollarSign,
      color: "from-blue-400 to-blue-600",
      chartColor: "#3B82F6",
      format: "currency",
      dataKey: "totalMerchandiseSales",
      gridClass: "col-span-2",
      settledDataKey: "settledMerchandiseSales",
    },
    {
      title: "Net Profits",
      tooltip:
        "Earnings after all costs and expenses have been deducted from revenue for this product.",
      value: totals?.netProfits
        ? `${currency.symbol}${totals.netProfits.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : `${currency.symbol}0.00`,
      settledValue: settledTotals?.settledNetProfits
        ? `${currency.symbol}${settledTotals.settledNetProfits.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}`
        : `${currency.symbol}0.00`,
      icon: TrendingUp,
      color: "from-teal-400 to-teal-600",
      chartColor: "#14B8A6",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "netProfits",
      gridClass: "col-span-2",
      settledDataKey: "settledNetProfits",
    },
    {
      title: "Cost of Goods",
      tooltip: "Direct costs attributable to the production of this product.",
      value: totals?.costOfGoods
        ? `${currency.symbol}${totals.costOfGoods.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : `${currency.symbol}0.00`,
      settledValue: settledTotals?.settledCostOfGoods
        ? `${currency.symbol}${settledTotals.settledCostOfGoods.toLocaleString(
            undefined,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          )}`
        : `${currency.symbol}0.00`,
      icon: Package,
      color: "from-purple-400 to-purple-600",
      chartColor: "#8B5CF6",
      format: "currency",
      dataKey: "costOfGoods",
      gridClass: "col-span-1",
      settledDataKey: "settledCostOfGoods",
    },
    {
      title: "Affiliates",
      tooltip:
        "Estimated fees paid to affiliates for referring customers for this product.",
      value: totals?.totalEstimatedAffiliateCommission
        ? `${
            currency.symbol
          }${totals.totalEstimatedAffiliateCommission.toLocaleString(
            undefined,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          )}`
        : `${currency.symbol}0.00`,
      settledValue: settledTotals?.settledAffiliateCommission
        ? `${
            currency.symbol
          }${settledTotals.settledAffiliateCommission.toLocaleString(
            undefined,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          )}`
        : `${currency.symbol}0.00`,
      icon: Percent,
      color: "from-pink-400 to-pink-600",
      chartColor: "#EC4899",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "totalEstimatedAffiliateCommission",
      gridClass: "col-span-1",
      settledDataKey: "settledAffiliateCommission",
    },
    {
      title: "TikTok Commiss. Fee",
      tooltip:
        "Estimation of fees charged by the e-commerce platform for this product.",
      value: totals?.totalPlatformFee
        ? `${currency.symbol}${totals.totalPlatformFee.toLocaleString(
            undefined,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          )}`
        : `${currency.symbol}0.00`,
      settledValue: settledTotals?.settledPlatformCommission
        ? `${
            currency.symbol
          }${settledTotals.settledPlatformCommission.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : `${currency.symbol}0.00`,
      icon: CreditCard,
      color: "from-orange-400 to-orange-600",
      chartColor: "#F97316",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "totalPlatformFee",
      gridClass: "col-span-1",
      settledDataKey: "settledPlatformCommission",
    },
    {
      title: "Net Units Sold",
      tooltip: "The total number of units sold excluding cancelled units.",
      value: totals && totals.netUnits ? totals.netUnits.toString() : "0",
      settledValue:
        settledTotals && settledTotals.settledUnits
          ? settledTotals.settledUnits.toString()
          : "0",
      change: 3,
      icon: Truck,
      color: "from-purple-400 to-purple-600",
      chartColor: "#9333EA",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "netUnits",
      gridClass: "col-span-1",
      settledDataKey: "settledUnits",
      isNotCurrency: true,
    },
    {
      title: "Net Seller Discounts",
      tooltip:
        "Net seller discounts applied to the customer payment. Excludes cancelled seller discounts.",
      value:
        totals && totals.totalNetSellerDiscounts
          ? `${currency.symbol}${totals.totalNetSellerDiscounts?.toLocaleString(
              undefined,
              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            )}`
          : `${currency.symbol}0.00`,
      settledValue:
        settledTotals && settledTotals.settledSellerDiscountAmount
          ? `${
              currency.symbol
            }${settledTotals.settledSellerDiscountAmount?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}`
          : `${currency.symbol}0.00`,
      change: 15,
      icon: DollarSign,
      color: "from-amber-400 to-amber-600",
      chartColor: "#F59E0B",
      format: "currency",
      dataKey: "totalNetSellerDiscounts",
      gridClass: "col-span-2",
      settledDataKey: "settledSellerDiscountAmount",
    },
    {
      title: "Net Platform Discounts",
      tooltip:
        "Net platform discounts applied to the customer payment. Excludes cancelled platform discounts.",
      value:
        totals && totals.totalNetPlatformDiscounts
          ? `${
              currency.symbol
            }${totals.totalNetPlatformDiscounts?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : `${currency.symbol}0.00`,
      settledValue:
        settledTotals && settledTotals.settledPlatformDiscountAmount
          ? `${
              currency.symbol
            }${settledTotals.settledPlatformDiscountAmount?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}`
          : `${currency.symbol}0.00`,
      change: 15,
      icon: DollarSign,
      color: "from-rose-400 to-rose-600",
      chartColor: "#F43F5E",
      format: "currency",
      dataKey: "totalNetPlatformDiscounts",
      gridClass: "col-span-2",
      settledDataKey: "settledPlatformDiscountAmount",
    },
  ];

  return (
    <div className="flex flex-col gap-2 pb-32">
      <div className="flex gap-8 items-center">
        <SelectDateRange
          onDateRangeChange={handleDateRangeChange}
          initialFromDate={fromDate}
          initialToDate={toDate}
        />
        <div className="flex items-center gap-2">
          <Switch
            checked={swapChartsOnDashboard}
            onCheckedChange={setSwapChartsOnDashboard}
          />
          <span className="text-sm text-muted-foreground">
            Settled Chart Mode
          </span>
          <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger>
                <Info size={14} className="text-muted-foreground" />
              </TooltipTrigger>
              <TooltipContent className="text-lg w-[300px]">
                <p>
                  Switch to display settled data as bars and estimates as a
                  dashed line - making it easier to compare actual vs projected
                  values.
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>

      <div className="flex flex-wrap">
        <div className="lg:w-2/3 w-full border-r pr-4 flex flex-col gap-10">
          <div className="grid grid-cols-4 gap-4">
            {productTiles.map((tile, index) => (
              <ChartTileOverview
                key={index}
                swapCharts={swapChartsOnDashboard}
                tile={tile}
                isLoading={productStatsLoading}
                isSettledLoading={settledStatsLoading}
                combinedData={combinedData}
              />
            ))}
          </div>

          {productId && (
            <ShippingOverviewTile
              productId={productId}
              fromDate={fromDate}
              toDate={toDate}
            />
          )}
        </div>
        <div className="lg:w-1/3 w-full pl-4">
          {productId && (
            <TaxOverviewTile
              productId={productId}
              fromDate={fromDate}
              toDate={toDate}
            />
          )}
        </div>
      </div>
    </div>
  );
};
