import { useParams, useNavigate, Link } from "react-router-dom";
import { useFullTiktokOrderById } from "../../../../hooks/tiktokOrder.hooks";
import { TiktokOrder } from "@/types/TikTokOrder";
import { useContext, useEffect, useState } from "react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { Badge } from "@/components/ui/badge";
import { Card } from "@/components/ui/card";
import {
  CalendarIcon,
  ImageIcon,
  Info,
  ArrowLeft,
  Menu,
  User,
  AlertTriangle,
  MapPin,
  Phone,
  Mail,
  LinkIcon,
  BanIcon,
  X,
  XCircleIcon,
  TriangleAlertIcon,
} from "lucide-react";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { cn } from "@/lib/utils";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { LoadingFallback } from "@/components/loading-fallback";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogHeader,
  DialogTrigger,
  DialogTitle,
  DialogContent,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { OrderStatusBadge } from "@/components/order-statuses";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Currency } from "@/types/Currency";
import {
  EstimationTableValue,
  EstimationTableValueView,
} from "@/routes/private/analytics/orders/viewOrder/estimation-table-value";
import { createFalse } from "typescript";
import { orderLineItemFieldMappings } from "./viewOrder/order-line-item-mappings";
import { orderMappings } from "./viewOrder/order-mappings";
import { statementFieldMappings } from "./viewOrder/statement-mappings";
import { StatementTableValue } from "./viewOrder/statement-table-value";
import { OrderLineItemTableValue } from "./viewOrder/order-line-item-table-value";
import { RawDataButtonDialog } from "./viewOrder/raw-data-button-dialog";
import { OrderTimeline } from "./viewOrder/order-timeline";
import { ShippingTimeline } from "./viewOrder/shipping-timeline";
import { RecipientAddress } from "./viewOrder/recipient-address";

export const ViewOrder = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const {
    data: order,
    isLoading,
    error,
  } = useFullTiktokOrderById({ orderId: orderId! });
  const { currency, isSidebarOpen, setIsSidebarOpen } =
    useContext(UserStateContext);
  const [selectedFields, setSelectedFields] = useState<string[]>([
    "totalMerchandiseSales",
    "estimatedNetTax",
    "potentialAffiliateCommission",
    "margin",
    "costOfGoods",
    "estimatedFbmShippingCost",
    "estimatedFbtShippingCost",
    "sellerShippingCost",
    "netProfits",
  ]);
  const [
    showEstimatedSettledUnitMismatchWarning,
    setShowEstimatedSettledUnitMismatchWarning,
  ] = useState(false);

  useEffect(() => {
    const totalUnits = order?.mergoioData.netUnits || 0;
    const totalUnitsSettled =
      order?.tiktokStatement?.mergoioData.settledNetUnits || 0;
    if (totalUnits !== totalUnitsSettled && order?.tiktokStatement) {
      setShowEstimatedSettledUnitMismatchWarning(true);
    } else {
      setShowEstimatedSettledUnitMismatchWarning(false);
    }
  }, [order]);

  if (error) {
    return (
      <div className="p-8 text-red-500">
        Error loading order: {error.message}
      </div>
    );
  }

  return (
    <div className="min-h-screen flex-1 flex flex-col overflow-hidden">
      <main className="flex-1 bg-background overflow-y-auto min-h-screen pb-32">
        <header className="border-b shadow-sm">
          <div className="px-4 md:px-6 h-16 flex items-center gap-4">
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="md:hidden text-foreground"
            >
              <Menu size={24} />
            </button>
            <Button
              variant="ghost"
              size="icon"
              onClick={() => navigate("/analytics/orders")}
            >
              <ArrowLeft className="h-4 w-4" />
            </Button>
            {order && (
              <div className="flex-1 flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <h2 className="text-xl font-semibold text-foreground">
                    Order #{order?.id}
                  </h2>
                  {order && <OrderStatusBadge status={order.status} />}
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Badge className="text-xs rounded-md flex items-center gap-1 bg-muted/50 font-normal text-foreground border-gray-200/10">
                          <Info className="w-3 h-3 shrink-0" />
                          {order?.fulfillmentType === "FULFILLMENT_BY_TIKTOK"
                            ? "FBT"
                            : order?.fulfillmentType ===
                                "FULFILLMENT_BY_SELLER" &&
                              order?.shippingType === "TIKTOK"
                            ? "SLT"
                            : "SLS"}
                        </Badge>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p className="font-semibold">
                          {order?.fulfillmentType === "FULFILLMENT_BY_TIKTOK"
                            ? "Fulfillment by TikTok (FBT)"
                            : order?.fulfillmentType ===
                                "FULFILLMENT_BY_SELLER" &&
                              order?.shippingType === "TIKTOK"
                            ? "Shipping Labels by TikTok (SLT)"
                            : "Shipping Labels by Seller (SLS)"}
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
                <div className="flex items-center gap-3 text-sm text-muted-foreground">
                  <div className="flex items-center gap-2 font-semibold">
                    <User className="w-4 h-4" />
                    {order?.recipientAddress?.name || "N/A"}
                  </div>
                  <Badge
                    variant="outline"
                    className="flex items-center gap-2 bg-blue-600/40 text-blue-400 border-blue-200/10"
                  >
                    <CalendarIcon className="w-4 h-4" />
                    Created:{" "}
                    {moment(order?.createTime).format("MMM D, YYYY h:mm A")}
                  </Badge>
                  {order?.paidTime && (
                    <Badge
                      variant="outline"
                      className="flex items-center gap-2 bg-green-600/40 text-green-400 border-green-200/10"
                    >
                      <CalendarIcon className="w-4 h-4" />
                      Paid:{" "}
                      {moment(order?.paidTime).format("MMM D, YYYY h:mm A")}
                    </Badge>
                  )}
                </div>
              </div>
            )}
          </div>
        </header>

        {order && order?.isSampleOrder && order.total > 999999 ? (
          <div className="p-8">
            <Alert className="bg-blue-500/10 border-blue-500/20">
              <div className="flex items-start gap-6">
                <Info className="h-8 w-8" />
                <div>
                  <AlertTitle className="font-bold">
                    Sample Order Support Coming Soon
                  </AlertTitle>
                  <AlertDescription className="text-lg">
                    We are currently working on adding full support for viewing sample orders. This feature will be available soon to help you better analyze your sample order data.
                    <br /><br />
                    Please check back later when this feature is released. Thank you for your patience.
                  </AlertDescription>
                </div>
              </div>
            </Alert>
          </div>
        ) : (
          <LoadingFallback loading={isLoading}>
            {order && (
              <div className="p-8 space-y-8">
                <Alert className="bg-orange-500/10 border-orange-500/20">
                  <div className="flex items-start gap-6">
                    <TriangleAlertIcon className="h-8 w-8" />
                    <div>
                      <AlertTitle className="font-bold">
                        Beta Feature
                      </AlertTitle>
                      <AlertDescription className="text-lg">
                        This page is currently in beta. Data like affiliate
                        commissions is missing for LIVE orders. The affiliate
                        data on your dashboards will be the more accurate
                        reference for time being. Please use this order page to
                        look at individual data points for your orders rather
                        than refer to it for net profit calculation. Until we
                        implement affiliate data on this page, net profit will
                        not be accurate.
                        <br />
                        <br />
                        <b>
                          For the most accurate data, please use the{" "}
                          <Link
                            className="text-blue-500 underline hover:text-blue-600"
                            to="/"
                          >
                            Dashboard
                          </Link>{" "}
                          or{" "}
                          <Link
                            className="text-blue-500 underline hover:text-blue-600"
                            to="/analytics/products"
                          >
                            Products
                          </Link>{" "}
                          page.
                        </b>
                      </AlertDescription>
                    </div>
                  </div>
                </Alert>

                {/* Recipient Address */}
                <Card className="p-0">
                  <h2 className="text-lg font-semibold px-4 py-2 border-b bg-muted">
                    Order Details
                  </h2>
                  <div className="grid grid-cols-4 gap-4">
                    {/* Address Column */}
                    <div className="col-span-2 py-4 px-4">
                      <p className="font-medium mb-2">Recipient Address</p>
                      <RecipientAddress order={order} />
                    </div>

                    {/* Dates & Status Column */}
                    <div className="border-l px-4 py-4">
                      <div className="space-y-2">
                        <p className="font-medium">Order Timeline</p>
                        <OrderTimeline order={order} />
                      </div>
                    </div>

                    {/* Shipping Timeline */}
                    <div className="border-l px-4 py-4">
                      <div className="space-y-2">
                        <p className="font-medium">Shipping Timeline</p>
                        <ShippingTimeline order={order} />
                      </div>
                    </div>
                  </div>
                </Card>

                {/* Order Line Items */}
                <Card>
                  <div className="flex items-center justify-between px-4 py-2 border-b bg-muted">
                    <h2 className="text-lg font-semibold">Order Items</h2>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="outline">
                          Fields ({selectedFields.length}/
                          {Object.keys(orderLineItemFieldMappings).length})
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className="w-56">
                        <DropdownMenuLabel>Toggle Fields</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <DropdownMenuCheckboxItem
                          checked={
                            selectedFields.length ===
                            Object.keys(orderLineItemFieldMappings).length
                          }
                          onCheckedChange={(checked) => {
                            setSelectedFields(
                              checked
                                ? Object.keys(orderLineItemFieldMappings)
                                : []
                            );
                          }}
                        >
                          All Fields
                        </DropdownMenuCheckboxItem>
                        <DropdownMenuSeparator />
                        {Object.entries(orderLineItemFieldMappings)
                          .sort((a, b) => a[1].order - b[1].order)
                          .map(([key, config]) => (
                            <DropdownMenuCheckboxItem
                              key={key}
                              checked={selectedFields.includes(key)}
                              onCheckedChange={(checked) => {
                                setSelectedFields(
                                  checked
                                    ? [...selectedFields, key]
                                    : selectedFields.filter(
                                        (field) => field !== key
                                      )
                                );
                              }}
                            >
                              {config.header}
                            </DropdownMenuCheckboxItem>
                          ))}
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead className="font-semibold text-foreground/70 w-[250px]">
                          Product
                        </TableHead>
                        <TableHead className="font-semibold text-foreground/70">
                          SKU
                        </TableHead>
                        {Object.entries(orderLineItemFieldMappings)
                          .filter(([key]) => selectedFields.includes(key))
                          .sort((a, b) => a[1].order - b[1].order)
                          .map(([key, config]) => (
                            <TableHead
                              key={key}
                              className={cn(
                                "text-left font-semibold text-foreground/70 min-w-[100px] max-w-[100px]",
                                config.size === "lg"
                                  ? "min-w-[200px] max-w-[200px]"
                                  : "min-w-[100px] max-w-[100px]"
                              )}
                            >
                              {config.header}
                              {config.estimated && (
                                <div className="flex items-center gap-1 font-normal text-xs">
                                  <p className="text-xs opacity-50">
                                    Estimated
                                  </p>
                                  <AlertTriangle className="w-3 h-3 opacity-50" />
                                </div>
                              )}
                            </TableHead>
                          ))}
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {order.orderLineItems.map((item) => (
                        <TableRow
                          key={item.id}
                          className="hover:bg-muted/50 cursor-pointer"
                          onClick={() =>
                            navigate(
                              `/analytics/products/${item.sku.product.id}`
                            )
                          }
                        >
                          <TableCell>
                            <div className="flex items-center gap-4">
                              <div className="w-12 h-12 shrink-0 rounded-lg overflow-hidden">
                                {item.sku.skuImageUrl ||
                                item.sku.product.mainImageUrl ? (
                                  <img
                                    src={
                                      item.sku.skuImageUrl ||
                                      item.sku.product.mainImageUrl
                                    }
                                    alt={item.sku.product.title}
                                    className="shrink-0 w-full h-full object-cover"
                                  />
                                ) : (
                                  <div className="w-full h-full bg-muted flex items-center justify-center">
                                    <ImageIcon className="shrink-0 w-6 h-6 text-gray-400" />
                                  </div>
                                )}
                              </div>
                              <div>
                                <Link
                                  to={`/analytics/products/${item.sku.product.id}`}
                                  className="text-blue-500 underline flex items-center gap-1 font-medium hover:text-blue-700 hover:underline"
                                >
                                  {item.sku.product.title.length > 50
                                    ? item.sku.product.title.substring(0, 50) +
                                      "..."
                                    : item.sku.product.title}
                                  <LinkIcon className="w-3 h-3 shrink-0" />
                                </Link>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="flex flex-col items-start justify-start gap-2">
                              <div>
                                {item.sku.sellerSku || item.sku.computedSku}
                              </div>
                              {item.displayStatus && (
                                <OrderStatusBadge status={item.displayStatus} />
                              )}
                              {item.cancelReason && (
                                <p className="flex items-start gap-2 text-sm text-red-500">
                                  Cancel Reason: {item.cancelReason}
                                </p>
                              )}
                            </div>
                          </TableCell>
                          {Object.entries(orderLineItemFieldMappings)
                            .filter(([key]) => selectedFields.includes(key))
                            .sort((a, b) => a[1].order - b[1].order)
                            .map(([key]) => (
                              <TableCell key={key}>
                                <OrderLineItemTableValue
                                  lineItem={item}
                                  mappings={orderLineItemFieldMappings}
                                  fieldKey={key}
                                  value={
                                    item.mergoioData[
                                      key as keyof typeof item.mergoioData
                                    ] || 0
                                  }
                                  order={order}
                                  currency={currency}
                                />
                              </TableCell>
                            ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Card>

                {/* Profit & Loss Breakdown */}
                <Card>
                  <h2 className="text-lg font-semibold px-4 py-2 border-b bg-muted">
                    Profit & Loss Breakdown
                  </h2>
                  {showEstimatedSettledUnitMismatchWarning && (
                    <div className="p-6">
                      <Alert className="bg-red-500/10 dark:bg-red-500/20 border-2 border-red-200 dark:border-red-800 shadow-lg">
                        <div className="flex items-start gap-8">
                          <TriangleAlertIcon className="h-12 w-12 text-red-500 dark:text-red-400 animate-pulse" />
                          <div className="space-y-4">
                            <AlertTitle className="text-2xl font-bold text-red-600 dark:text-red-400">
                              Important: Data Discrepancy Detected
                            </AlertTitle>
                            <AlertDescription className="space-y-4">
                              <p className="text-lg leading-relaxed text-gray-700 dark:text-gray-300">
                                We've detected a discrepancy between the
                                estimated and settled unit counts for this
                                order. This typically occurs when order details
                                change after initial completion.
                              </p>

                              <div className="bg-red-100 dark:bg-red-950/50 p-4 rounded-lg border border-red-200 dark:border-red-800">
                                <p className="text-red-700 dark:text-red-400 font-medium">
                                  To ensure accuracy in your financial analysis,
                                  we strongly recommend referring to the{" "}
                                  <span className="font-bold underline">
                                    Settled Profit & Loss Breakdown
                                  </span>{" "}
                                  section below, which contains the final
                                  verified figures.
                                </p>
                              </div>

                              <p className="text-lg font-semibold text-red-600 dark:text-red-400">
                                The settled data represents the official,
                                reconciled figures from TikTok and should be
                                used for all financial decisions.
                              </p>
                            </AlertDescription>
                          </div>
                        </div>
                      </Alert>
                    </div>
                  )}
                  <div className="h-full w-full flex flex-wrap">
                    {/* Live Column */}
                    <div className="lg:w-1/2 w-full">
                      <div className="h-[100px] text-md font-medium p-4 bg-muted/50 flex flex-col justify-center items-start">
                        <div>Estimated</div>
                        <p className="text-sm text-muted-foreground">
                          Until we receive the settlement data from TikTok, all
                          figures shown are estimates.
                        </p>
                      </div>
                      {Object.entries(orderMappings)
                        .sort((a, b) => a[1].order - b[1].order)
                        .map(([key]) => (
                          <EstimationTableValue
                            key={key}
                            mappings={orderMappings}
                            fieldKey={key}
                            value={
                              order.mergoioData[
                                key as keyof typeof order.mergoioData
                              ] ?? 0
                            }
                            order={order}
                            currency={currency}
                          />
                        ))}
                    </div>

                    {/* Settled Column */}
                    <div className="lg:w-1/2 w-full border-l">
                      {order.tiktokStatement ? (
                        <>
                          <div className="h-[100px] text-md font-medium p-4 text-green-400 bg-green-600/50 flex flex-col justify-center items-start">
                            <div>Settled</div>
                            <p className="text-sm text-muted-foreground">
                              Accurate earnings for this order.{" "}
                            </p>
                          </div>
                          {Object.entries(statementFieldMappings)
                            .sort((a, b) => a[1].order - b[1].order)
                            .map(([key]) => {
                              const value =
                                order.tiktokStatement?.mergoioData[
                                  key as keyof typeof order.tiktokStatement.mergoioData
                                ] ?? 0;
                              return (
                                <StatementTableValue
                                  key={key}
                                  mappings={statementFieldMappings}
                                  fieldKey={key}
                                  value={value}
                                  order={order}
                                  currency={currency}
                                />
                              );
                            })}
                        </>
                      ) : (
                        <div className="px-4 py-2 border-b h-full text-gray-400 text-lg flex flex-col items-center justify-center gap-2">
                          <XCircleIcon className="w-10 h-10" />
                          No statement provided from TikTok yet
                        </div>
                      )}
                    </div>
                  </div>
                </Card>

                <RawDataButtonDialog order={order} currency={currency} />
              </div>
            )}
          </LoadingFallback>
        )}
      </main>
    </div>
  );
};
