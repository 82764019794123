"use client";

import React from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { SaveIcon, AlertTriangle } from "lucide-react";
import { FbtFulfillmentFeeCogsFormWrapper } from "./fbt-fulfilment-fees-cogs-form-wrapper";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

interface FbtFulfillmentFeesCogsModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const FbtFulfillmentFeesCogsModal: React.FC<
  FbtFulfillmentFeesCogsModalProps
> = ({ open, setOpen }) => {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="max-w-[1200px] p-0">
        <div className="h-[80vh] overflow-y-auto flex flex-col items-start w-full">
          <div className="p-4">
            <Alert
              variant="default"
              className="mb-4 bg-orange-500/10 border-orange-500/40"
            >
              <div className="flex items-start gap-6">
                <AlertTriangle className="shrink-0 h-6 w-6" />
                <div>
                  <AlertTitle className="text-md font-medium">
                    Why are my fulfillment fees so high?
                  </AlertTitle>
                  <AlertDescription className="text-sm text-muted-foreground text-lg">
                    TikTok does not surface detailed fulfillment fee data to us.
                    By default, we estimate fees assuming standard rates apply
                    to all orders. However, TikTok often provides promotional
                    discounts:
                    <ul className="list-disc pl-6 mt-2 space-y-1">
                      <li>First 90 days: 100% discount on fulfillment fees</li>
                      <li>5,000+ orders/month: 20% discount</li>
                      <li>20,000+ orders/month: 50% discount</li>
                    </ul>
                    Use this form to configure custom discount periods or set
                    default rates to match your actual fulfillment costs.
                  </AlertDescription>
                </div>
              </div>
            </Alert>
          </div>
          <FbtFulfillmentFeeCogsFormWrapper onSaved={() => setOpen(false)} />
        </div>
      </DialogContent>
    </Dialog>
  );
};
