import { UserStateContext } from "@/contexts/UserStateProvider";
import { useContext } from "react";
import { Route, Routes, useLocation } from "react-router";
import { Login } from "./public/Login";
import { SignUpRedirect } from "./public/SignUpRedirect";
import { Dashboard } from "./private/dashboard/Dashboard";
import { Products } from "./private/shop/products/Products";
import Numerology from "./private/numerology/Numerology";
import { ViewProduct } from "./private/shop/products/view/ViewProduct";
import { _Sidebar } from "@/components/layout/_sidebar";
import { SellerAccountPortal } from "./private/portal/SellerAccountPortal";
import { ProductStatistics } from "./private/analytics/products/ProductStatistics";
import { ViewProductStatistics } from "./private/analytics/products/view/ViewProductStatistics";
import { AffiliateStatistics } from "./private/analytics/affiliates/AffiliateStatistics";
import { Settings } from "./private/settings/Settings";
import { Help } from "./private/help/Help";
import { Billing } from "./private/billing/Billing";
import { ProductCancellationStatistics } from "./private/analytics/products/ProductCancellationStatistics";
import { Maintenance } from "@/components/maintenance-page";
import { AdminLogin } from "./public/AdminLogin";
import TiktokAdsRedirect from "./private/tiktokAds/Redirect";
import { BusinessAccounts } from "./private/tiktokAds/BusinessAccounts";
import { OrderStatistics } from "./private/analytics/orders/OrderStatistics";
import { ViewOrder } from "./private/analytics/orders/ViewOrder";
import { Summary } from "./private/summary/Summary";
import SettlementStatistics from "./private/analytics/settlements/SettlementStatistics";

export const RouteProvider = () => {
  const location = useLocation();
  const { loading, isAuthenticated } = useContext(UserStateContext);
  
  if (loading) {
    return <></>;
  }

  if (!isAuthenticated) {
    return (
      <>
        <Routes location={location} key={location.pathname}>
          <Route path={"*"} element={<Login />} />
          <Route path={"/signUpRedirect"} element={<SignUpRedirect />} />
          <Route path={"/admin/login"} element={<AdminLogin />} />
        </Routes>
      </>
    );
  }

  return (
    <>
      <div className="flex h-screen bg-background">
        <div className="flex-shrink-0">
          <_Sidebar />
        </div>
        <Routes location={location} key={location.pathname}>
            <Route path={"*"} element={<Dashboard />} />
            <Route path={"/dashboard"} element={<Dashboard />} />
            <Route path={"/products"} element={<Products />} />
            <Route path={"/products/:productId"} element={<ViewProduct />} />
            <Route path={"/analytics/settlements"} element={<SettlementStatistics />} />
            <Route path={"/analytics/orders"} element={<OrderStatistics />} />
            <Route path={"/analytics/orders/:orderId"} element={<ViewOrder />} />
            <Route path={"/analytics/products"} element={<ProductStatistics />} />
            <Route path={"/analytics/cancellations"} element={<ProductCancellationStatistics />} />
            <Route path={"/analytics/products/:productId"} element={<ViewProductStatistics />} />
            <Route path={"/analytics/affiliates"} element={<AffiliateStatistics />} />
            <Route path={"/numerology"} element={<Numerology />} />
            <Route path={"/settings"} element={<Settings />} />
            <Route path={"/billing"} element={<Billing />} />
            <Route path={"/help"} element={<Help />} />
            <Route path={"/tiktok/adAuthRedirect"} element={<TiktokAdsRedirect />} />
            <Route path={"/tiktok/businessAccounts"} element={<BusinessAccounts />} />
          </Routes>
      </div>
    </>
  );
};
