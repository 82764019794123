import { TextFieldElement } from "@/components/formElements/text-field-element";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Form, useForm } from "@/hooks/form.hook";
import { useValidateTimeSpan } from "@/hooks/validateTimeSpan.hook";
import { cn } from "@/lib/utils";
import { CostOfGoods } from "@/types/CostOfGoods";
import { format } from "date-fns";
import {
  CalendarIcon,
  CheckCircleIcon,
  CircleXIcon,
  PlusIcon,
  TrashIcon,
  TriangleAlertIcon,
  XCircleIcon,
} from "lucide-react";
import { useEffect, useState, useContext } from "react";
import { UserStateContext } from "@/contexts/UserStateProvider";

interface CogsDateRangeModalProps {
  form: Form;
  skuId?: string;
  productId?: string;
  overrideSymbol?: string;
  overrideDomainName?: string;
  onChange?: () => void;
  overrideValueHeader?: string;
}

export const CogsDateRangeForm: React.FC<CogsDateRangeModalProps> = ({
  form,
  productId,
  skuId,
  onChange,
  overrideSymbol,
  overrideDomainName,
  overrideValueHeader
}) => {
  const { validateCostOfGoodsList } = useValidateTimeSpan();
  const [issues, setIssues] = useState<string[]>([]);
  const { currency } = useContext(UserStateContext);

  useEffect(() => {
    const issues = validateCostOfGoodsList(form.state.costOfGoodsList);
    setIssues(issues);
  }, [form.state.costOfGoodsList]);

  const handleAddCogs = () => {
    const newCogs: CostOfGoods = {
      cost: 0,
      default: false,
      fromDate: new Date(),
      toDate: new Date(),
      productId: productId ?? undefined,
    };
    form.updateFieldValue("costOfGoodsList", [
      ...form.state.costOfGoodsList,
      newCogs,
    ]);
    if (onChange) onChange();
  };

  const handleRemoveCogs = (index: number) => {
    const updatedCogsList = [...form.state.costOfGoodsList];
    updatedCogsList.splice(index, 1);
    form.updateFieldValue("costOfGoodsList", updatedCogsList);
  };

  const handleUpdateCogs = (
    index: number,
    field: keyof CostOfGoods,
    value: any
  ) => {
    const updatedCogsList = [...form.state.costOfGoodsList];
    updatedCogsList[index] = { ...updatedCogsList[index], [field]: value };
    form.updateFieldValue("costOfGoodsList", updatedCogsList);
  };

  const formatDate = (date: Date | null) => {
    return date ? format(date, "PPP") : "Select a date";
  };

  return (
    <>
      {issues && issues.length > 0 && (
        <div
          className={
            "bg-red-400/10 border border-red-500 mx-4 mb-4 p-4 rounded-xl flex flex-col gap-2"
          }
        >
          <div className={"text-red-500 text-lg flex items-center gap-2"}>
            <span className={"mr-2"}>
              <XCircleIcon className="h-4 w-4" />
            </span>
            Your {overrideDomainName ? overrideDomainName : "cost of goods"} might not be setup correctly.
          </div>
          <ul>
            {issues.map((issue, index) => (
              <ol key={index}>{issue}</ol>
            ))}
          </ul>
        </div>
      )}
      <div className="w-full space-y-4 flex flex-col items-start">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[250px]">From Date</TableHead>
              <TableHead className="w-[250px]">To Date</TableHead>
              <TableHead className="w-[200px]">{overrideValueHeader ? overrideValueHeader : "Cost (inc. VAT/Tax)"}</TableHead>
              <TableHead className="w-[100px]">Action</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {form.state.costOfGoodsList &&
            form.state.costOfGoodsList.length > 0 ? (
              form.state.costOfGoodsList.map(
                (cogs: CostOfGoods, index: number) => {
                  const invalidCost = cogs.cost === null || cogs.cost < 0;
                  return (
                    <TableRow key={index}>
                      {cogs.default ? (
                        <TableCell colSpan={2}>
                          <div
                            className={cn(
                              "pl-4 border h-[70px] flex gap-2 items-center text-lg font-semibold",
                              invalidCost
                                ? "text-amber-500"
                                : "text-green-500"
                            )}
                          >
                            {invalidCost ? (
                              <TriangleAlertIcon className="w-4 h-4 mr-2" />
                            ) : (
                              <CheckCircleIcon className="w-4 h-4 mr-2" />
                            )}
                            <div>
                              <div>
                                {invalidCost && "No"} Default
                                
                              </div>
                              {invalidCost && (
                                <div className="text-xs font-normal opacity-50 text-muted-foreground">
                                  Please enter a {overrideDomainName ? overrideDomainName : "cost"}.
                                </div>
                              )}
                            </div>
                          </div>
                        </TableCell>
                      ) : (
                        <>
                          <TableCell>
                            <Popover>
                              <PopoverTrigger asChild>
                                <Button
                                  variant="outline"
                                  className={cn(
                                    "w-full justify-start text-left font-normal h-[70px] text-lg"
                                  )}
                                >
                                  <CalendarIcon className="mr-2 h-4 w-4" />
                                  {formatDate(cogs.fromDate)}
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent
                                className="w-auto p-0"
                                align="start"
                              >
                                <Calendar
                                  mode="single"
                                  selected={cogs.fromDate || undefined}
                                  onSelect={(date) => {
                                    if (onChange) onChange();
                                    handleUpdateCogs(index, "fromDate", date);
                                  }}
                                  initialFocus
                                />
                              </PopoverContent>
                            </Popover>
                          </TableCell>
                          <TableCell>
                            <Popover>
                              <PopoverTrigger asChild>
                                <Button
                                  variant="outline"
                                  className={cn(
                                    "w-full justify-start text-left font-normal h-[70px] text-lg"
                                  )}
                                >
                                  <CalendarIcon className="mr-2 h-4 w-4" />
                                  {formatDate(cogs.toDate)}
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent
                                className="w-auto p-0"
                                align="start"
                              >
                                <Calendar
                                  mode="single"
                                  selected={cogs.toDate || undefined}
                                  onSelect={(date) => {
                                    if (onChange) onChange();
                                    handleUpdateCogs(index, "toDate", date);
                                  }}
                                  initialFocus
                                />
                              </PopoverContent>
                            </Popover>
                          </TableCell>
                        </>
                      )}
                      <TableCell>
                        <TextFieldElement
                          startDecorator={overrideSymbol ? overrideSymbol : currency.symbol}
                          className={cn(
                            "h-[70px] text-lg",
                            invalidCost && cogs.default
                              ? "border border-amber-500"
                              : ""
                          )}
                          fieldName={`costOfGoodsList.${index}.cost`}
                          type="number"
                          placeholder={"Enter " + (overrideSymbol ? overrideSymbol : "cost")}
                          form={form}
                          onKeyDown={() => {
                            if (onChange) onChange();
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        {!cogs.default && (
                          <Button
                            variant="outline"
                            size="sm"
                            className="h-[70px] w-[100px] text-lg"
                            onClick={() => {
                              if (onChange) onChange();
                              handleRemoveCogs(index);
                            }}
                          >
                            <TrashIcon className="h-4 w-4" />
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                }
              )
            ) : (
              <TableRow>
                <TableCell
                  colSpan={4}
                  className="text-red-400 text-center px-4 flex gap-4 items-center text-lg"
                >
                  <CircleXIcon className="mr-2 h-4 w-4" />
                  No {overrideDomainName ? overrideDomainName : "cost of goods"} found. Please click "add {overrideDomainName ? overrideDomainName : "cost of goods"}" to
                  get started.
                </TableCell>
              </TableRow>
            )}
            <TableRow className="hover:bg-transparent">
              <TableCell colSpan={4} className="text-center py-4">
                <Button
                  variant="secondary"
                  size="lg"
                  onClick={handleAddCogs}
                  className="h-[80px] text-lg w-full"
                >
                  <PlusIcon className="mr-2 h-4 w-4" /> Add {overrideDomainName ? overrideDomainName : "cost of goods"}
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </>
  );
};
