import React, { useState, useContext } from "react";
import { useTiktokBusinessAccountList, useTiktokBusinessAccountService } from "@/hooks/tiktokBusinessAccount.hooks";
import { _Button } from "@/components/button";
import { MyAccountDropdown } from "@/components/my-account-dropdown";
import { ModeToggle } from "@/components/mode-toggle";
import { LoadingFallback } from "@/components/loading-fallback";
import {
  CircleXIcon,
  ShoppingBagIcon,
  ChevronDownIcon,
  CheckCircle,
  AlertCircle,
  ChevronRight,
  Loader2,
  MegaphoneIcon,
  PlusIcon,
  Ban,
  MailIcon,
  UserIcon,
  GlobeIcon,
  BuildingIcon,
  DollarSignIcon,
  InfoIcon
} from "lucide-react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { MergoioLogo } from "@/components/layout/MergoioLogo";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";
import { OrderCount } from "@/components/order-count";
import { useToast } from "@/hooks/toast.hooks";

export const BusinessAccounts = () => {
  const { businessAccounts, isLoading, error } = useTiktokBusinessAccountList();
  const { getAuthorizeUrl } = useTiktokBusinessAccountService();
  const [authorizing, setAuthorizing] = useState(false);
  const { toast } = useToast();

  const handleAuthorize = async () => {
    setAuthorizing(true);
    const response = await getAuthorizeUrl();
    if (!response.success) {
      setAuthorizing(false);
      toast({
        variant: "destructive",
        title: "Error",
        description: response.errorMessage || "Failed to get authorization URL",
      });
      return;
    }
    window.location.href = response.content.url;
  };

  return (
    <div className="w-full flex flex-col items-center gap-4 justify-center min-h-screen bg-background p-4">
      <Card className="w-full max-w-7xl h-full">
        <div className="bg-blue-500/10 text-blue-500 p-4 flex items-center gap-2 rounded-t-lg border-b">
          <InfoIcon className="h-5 w-5" />
          <p>Once connected, we'll automatically download and sync your ad spend data within 2 hours.</p>
        </div>
        <CardHeader className="space-y-1">
          <div className="flex justify-between gap-10 items-center">
            <div>
              <CardTitle className="text-2xl font-bold">
                TikTok Business Accounts
              </CardTitle>
              <CardDescription>Manage and authorize accounts</CardDescription>
            </div>
            <div className="flex space-x-2">
              <_Button
                variant="secondary"
                className="w-[250px] flex gap-4 justify-between items-center"
                onClick={handleAuthorize}
                loading={authorizing}
              >
                Link Business Account
                <PlusIcon className="h-4 w-4" />
              </_Button>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <LoadingFallback loading={isLoading}>
            {error ? (
              <div className="bg-destructive/10 rounded-lg px-3 py-2 text-sm flex items-center text-destructive">
                <CircleXIcon size={16} className="mr-2" />
                {error.message}
              </div>
            ) : (
              <div className="space-y-4">
                {businessAccounts?.length === 0 ? (
                  <div className="pt-24 flex flex-col items-center justify-center text-muted-foreground">
                    <Ban className="h-12 w-12 mb-4" />
                    <p className="text-lg opacity-75">No business accounts linked</p>
                    <p className="text-sm opacity-50">
                      Connect your TikTok Business Account to start managing your
                      ads
                    </p>
                  </div>
                ) : (
                  businessAccounts?.map((account, index) => (
                    <div key={account.id} className="w-full space-y-2 border rounded-lg">
                      <div className="bg-muted/40 flex gap-2 items-center p-4 border-b rounded-lg">
                        <ShoppingBagIcon className="mr-4 h-6 w-6 text-muted-foreground" />
                        <div className="lg:w-1/3 w-full break-words">
                          <p className="font-medium">{account.displayName}</p>
                          <div className="flex items-center gap-2 text-sm text-muted-foreground mt-1">
                            <MailIcon className="h-4 w-4" />
                            {account.email}
                          </div>
                        </div>
                        <div className="lg:w-2/3 w-full flex items-center justify-end gap-2">
                          <Badge
                            className={cn(
                              account.setupOperationsCompleted
                                ? "bg-green-500/10 text-green-500"
                                : "bg-orange-500/10 text-orange-500",
                              "flex items-center gap-2"
                            )}
                          >
                            {account.setupOperationsCompleted ? (
                              <>
                                <CheckCircle className="h-4 w-4" />
                                Setup Complete
                              </>
                            ) : (
                              <>
                                <AlertCircle className="h-4 w-4" />
                                Setup Incomplete
                              </>
                            )}
                          </Badge>
                        </div>
                      </div>

                      {/* Advertisers List */}
                      <div>
                        {account.advertisers.map((advertiser) => (
                          <div
                            key={advertiser.advertiserId}
                            className="bg-background hover:bg-muted/40 cursor-pointer p-4 border rounded-lg mb-2 mx-4 ml-10"
                          >
                            <div className="flex items-center justify-between mb-2">
                              <div className="flex items-center gap-2">
                                <MegaphoneIcon className="h-5 w-5 text-muted-foreground" />
                                <div>
                                  <p className="font-medium">{advertiser.name || advertiser.advertiserId}</p>
                                  <p className="text-sm text-muted-foreground">ID: {advertiser.advertiserId}</p>
                                </div>
                              </div>
                              <div className="flex gap-2">
                                <Badge variant="outline" className="flex items-center gap-1">
                                  <DollarSignIcon className="h-3 w-3" />
                                  {advertiser.currency}
                                </Badge>
                                <Badge variant="secondary" className="flex items-center gap-1">
                                  <GlobeIcon className="h-3 w-3" />
                                  {advertiser.country}
                                </Badge>
                                <Badge 
                                  className={cn(
                                    "flex items-center gap-1",
                                    advertiser.advertiserAccountType === 'AUCTION' ? "bg-blue-500/10 text-blue-500" : "bg-purple-500/10 text-purple-500"
                                  )}
                                >
                                  {advertiser.advertiserAccountType}
                                </Badge>
                                <Badge 
                                  className={cn(
                                    "flex items-center gap-1",
                                    "bg-green-500/10 text-green-500"
                                  )}
                                >
                                  {advertiser.role.replace('ROLE_', '')}
                                </Badge>
                              </div>
                            </div>
                            {(advertiser.company || advertiser.description) && (
                              <div className="text-sm text-muted-foreground mt-2 pl-7">
                                {advertiser.company && (
                                  <div className="flex items-center gap-2">
                                    <BuildingIcon className="h-3 w-3" />
                                    {advertiser.company}
                                  </div>
                                )}
                                {advertiser.description && (
                                  <p className="mt-1">{advertiser.description}</p>
                                )}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                )}
              </div>
            )}
          </LoadingFallback>
        </CardContent>
      </Card>
    </div>
  );
};
