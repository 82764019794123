import React, { useState, useContext } from "react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { _Button } from "@/components/button";
import { Settings2Icon, InfoIcon, TriangleAlertIcon, XCircleIcon, CheckCircleIcon, SettingsIcon } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { CogsCalcSelect } from "./cogs-calc-select";
import { useOrganisationService } from "@/hooks/organisation.hooks";
import { useQueryClient } from "@tanstack/react-query";
import {
  CANCELLATION_STATISTICS,
  LITE_CANCELLED_STATISTICS,
  LITE_PRODUCT_SKU_STATISTICS,
  LITE_PRODUCT_STATISTICS,
} from "@/hooks/tiktokOrder.hooks";
import { LITE_SETTLED_DASHBOARD_STATISTICS } from "@/hooks/tiktokSkuSettledPeriod.hooks";
import { LITE_DASHBOARD_STATISTICS } from "@/hooks/tiktokOrder.hooks";
import { useToast } from "@/hooks/toast.hooks";

export const CogsCalcPopover = ({
  type = "BUTTON",
}: {
  type?: "CONFIGURE" | "BUTTON";
}) => {
  const { cogsCalculation, setCogsCalculation } = useContext(UserStateContext);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<
    | "ALL_ORDERS"
    | "EXCLUDE_UNSHIPPED_CANCELLED_ORDERS"
    | "EXCLUDE_CANCELLED_ORDERS"
  >(cogsCalculation);
  const { updateSettings } = useOrganisationService();
  const queryClient = useQueryClient();
  const {toast} = useToast();
  const [saving, setSaving] = useState(false);

  const handleSubmit = async () => {
    setSaving(true);
    setCogsCalculation(selectedValue);
    const response = await updateSettings({ cogsCalculation: selectedValue });
    setSaving(false);
    if (!response.success) {
      toast({
        icon: <XCircleIcon size={12} className={"text-red-500"} />,
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: response.errorMessage!,
      });
      return;
    }
    setIsOpen(false);
    queryClient.invalidateQueries({
      queryKey: [LITE_DASHBOARD_STATISTICS],
    });
    queryClient.invalidateQueries({
      queryKey: [LITE_SETTLED_DASHBOARD_STATISTICS],
    });
    queryClient.invalidateQueries({
      queryKey: [LITE_PRODUCT_STATISTICS],
    });
    queryClient.invalidateQueries({
      queryKey: [LITE_PRODUCT_SKU_STATISTICS],
    });
    queryClient.invalidateQueries({
      queryKey: [LITE_CANCELLED_STATISTICS],
    });
    queryClient.invalidateQueries({
      queryKey: [CANCELLATION_STATISTICS],
    });
    toast({
      icon: <CheckCircleIcon size={12} className={"text-green-500"} />,
      variant: "default",
      title: "Success",
      description: "Your COGS calculation method has been successfully updated.",
      duration: 2000,
    });
  };

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger>
        {type === "BUTTON" ? (
          <_Button
            onClick={() => setIsOpen(true)}
            className="w-full flex items-center gap-2"
            variant="secondary"
            size="sm"
          >
            <SettingsIcon className="w-4 h-4" />
            Configure
          </_Button>
        ) : (
          <_Button
            onClick={() => setIsOpen(true)}
            className="h-auto hover:bg-transparent p-0 text-[10px] hover:text-blue-600 flex items-center gap-2 text-blue-500 underline"
            variant="ghost"
            size="sm"
          >
            <Settings2Icon className="w-3 h-3" />
            Configure
          </_Button>
        )}
      </PopoverTrigger>
      <PopoverContent className="w-[500px]">
        <div className="space-y-4">
          <h3 className="font-semibold text-lg">
            How should we calculate COGS?
          </h3>

          <CogsCalcSelect value={selectedValue} onChange={setSelectedValue} />

          <div className="flex items-start gap-2 text-sm text-orange-500">
            <TriangleAlertIcon className="w-5 h-5 flex-shrink-0 mt-0.5" />
            <p>
              Choose carefully. This affects how your profit margins and
              financial metrics are calculated.
            </p>
          </div>

          <div className="flex flex-col gap-2">
            <_Button
              onClick={handleSubmit}
              className="w-full bg-blue-500 text-white hover:bg-blue-600 text-lg"
              loading={saving}
            >
              Save Changes
            </_Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
