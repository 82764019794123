import React, { useContext, useState, useEffect } from "react";
import { Menu, Info, SettingsIcon } from "lucide-react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { _Button } from "@/components/button";
import { Link, useNavigate } from "react-router-dom";
import { MyAccountDropdown } from "@/components/my-account-dropdown";
import { CurrencySelect } from "@/components/currency-select";
import { ChartSettingsPopover } from "@/components/chart-settings-popover";
import { SelectDateRange } from "@/components/select-date-range-component";
import { getDateRange } from "@/lib/utils";
import SalesOverviewTile from "./components/sales-overview-tile";
import TaxOverviewTile from "./components/tax-overview-tile";
import AdsOverviewTile from "./components/ads-overview-tile";
import ShippingOverviewTile from "./components/shipping-overview-tile";

export const SettlementStatistics = () => {
  const { isSidebarOpen, setIsSidebarOpen } = useContext(UserStateContext);
  const [selectedDateRange, setSelectedDateRange] = useState("14");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [totalSales, setTotalSales] = useState<number>(0);
  const [totalCogs, setTotalCogs] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedDateRange !== "custom") {
      const { from, to } = getDateRange(selectedDateRange);
      setFromDate(from);
      setToDate(to);
    }
  }, [selectedDateRange]);

  const handleApplyCustomDates = (fromDate: Date, toDate: Date) => {
    setFromDate(fromDate);
    setToDate(toDate);
    setSelectedDateRange("custom");
  };

  return (
    <div className="min-h-screen flex-1 flex flex-col overflow-hidden">
      <main className="flex-1 bg-background overflow-y-auto min-h-screen">
        <header className="border-b pt-6 pb-4 shadow-sm h-auto flex flex-wrap items-center px-4 md:px-6">
          <div className="lg:w-1/2 w-full flex items-center gap-4 w-full md:w-auto">
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="md:hidden text-foreground"
            >
              <Menu size={24} />
            </button>
            <div className="max-w-3xl">
              <h2 className="text-xl font-semibold text-foreground">
                Settlement Timeline
              </h2>
              <div className="flex items-start  gap-4">
                <Info className="h-4 w-4 text-blue-500 mt-4 shrink-0" />
                <div className="text-sm text-muted-foreground">
                  Data is shown based on when payments were settled in your
                  seller account, not when orders were placed. Visit the{" "}
                  <Link
                    className="text-blue-500 underline hover:text-blue-600"
                    to="/dashboard"
                  >
                    Sales & Profit
                  </Link>{" "}
                  to view settlement data inline with order creation time.
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 w-full flex items-center justify-end space-x-2 w-full">
            <_Button
              onClick={() => navigate("/settings")}
              className="w-auto flex items-center gap-2"
              variant="outline"
              size="sm"
            >
              <SettingsIcon className="w-4 h-4" />
              Settings
            </_Button>
            <ChartSettingsPopover />
            <CurrencySelect />
          </div>
        </header>

        <div className="mx-auto min-h-screen">
          <div className="h-max flex flex-col items-stretch lg:flex-row gap-6">
            {/* Left column: Sales Overview */}
            <div className="w-full lg:w-8/12 p-6 pr-0">
              {fromDate && toDate && (
                <div>
                  <SalesOverviewTile
                    headerExtra={
                      <div className="mr-2 flex items-center gap-4">
                        <SelectDateRange
                          onDateRangeChange={handleApplyCustomDates}
                          initialFromDate={fromDate}
                          initialToDate={toDate}
                          initialRange={selectedDateRange}
                        />
                      </div>
                    }
                    onTotalCogsChange={setTotalCogs}
                    onTotalSalesChange={setTotalSales}
                    selectedDateRange={selectedDateRange}
                    fromDate={fromDate}
                    toDate={toDate}
                  />
                  <ShippingOverviewTile
                    selectedDateRange={selectedDateRange}
                    fromDate={fromDate}
                    toDate={toDate}
                  />
                </div>
              )}
            </div>

            {/* Right column: Future components */}
            <div className="w-full lg:w-4/12 border-l pt-6 px-6 flex flex-col gap-8">
              {fromDate && toDate && (
                <>
                  <AdsOverviewTile
                    selectedDateRange={selectedDateRange}
                    fromDate={fromDate}
                    toDate={toDate}
                  />
                  <TaxOverviewTile
                    selectedDateRange={selectedDateRange}
                    fromDate={fromDate}
                    toDate={toDate}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default SettlementStatistics;
