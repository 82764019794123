import { CostOfGoods } from "@/types/CostOfGoods";
import { useRequest } from "./request.hooks";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { UserStateContext } from "@/contexts/UserStateProvider";

export const COST_OF_GOODS_BY_PRODUCT_ID = "costOfGoodsByProductId";
export const COST_OF_GOODS_BY_TYPE = "costOfGoodsByType";

export const useCostOfGoodsByProductId = (type: "COGS" | "SHIPPING" | "PLATFORM_COMMISSION_FEE" | "FBT_FULFILLMENT_FEE", productId: string) => {
    const { request } = useRequest();
    const { token } = useContext(UserStateContext);
    const { isLoading, isFetched, error, data } = useQuery({
        queryKey: [COST_OF_GOODS_BY_PRODUCT_ID, token, type, productId],
        queryFn: () =>
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve(
                        request({
                            url: `/api/v1/costOfGoods/retrieve/byProductId/${productId}/${type}`,
                            method: "GET"
                        })
                    );
                }, 500);
            })
                .then((response: any) => {
                    if (response.success) {
                        return response.content;
                    } else {
                        throw new Error(response.errorMessage || "Failed to fetch cost of goods");
                    }
                }),
        staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds
    });

    return {
        costOfGoods: data,
        isLoading,
        isFetched,
        error
    };
};

export const useCostOfGoodsByType = (type: "COGS" | "SHIPPING" | "PLATFORM_COMMISSION_FEE" | "FBT_FULFILLMENT_FEE") => {
    const { request } = useRequest();
    const { token } = useContext(UserStateContext);
    const { isLoading, isFetched, error, data } = useQuery({
        queryKey: [COST_OF_GOODS_BY_TYPE, token, type],
        queryFn: () =>
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve(
                        request({
                            url: `/api/v1/costOfGoods/retrieve/byType/${type}`,
                            method: "GET"
                        })
                    );
                }, 500);
            })
                .then((response: any) => {
                    if (response.success) {
                        return response.content;
                    } else {
                        throw new Error(response.errorMessage || "Failed to fetch cost of goods by type");
                    }
                }),
        staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds
    });

    return {
        costOfGoods: data,
        isLoading,
        isFetched,
        error
    };
};

export const useCostOfGoodsService = () => {
    const { request } = useRequest();

    const upsertCostOfGoods = (payload: {
        type: "COGS" | "SHIPPING" | "PLATFORM_COMMISSION_FEE" | "FBT_FULFILLMENT_FEE";
        productId?: string;
        costOfGoodsList: CostOfGoods[];
    }) => {
        return request({
            url: "/api/v1/costOfGoods/actions/upsert",
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            data: payload
        });
    };

    return {
        upsertCostOfGoods
    };
};
