import React, { useState } from "react";
import { _Button } from "@/components/button";
import { PencilIcon, Settings2Icon, SettingsIcon, TriangleAlertIcon } from "lucide-react";
import { FbtFulfillmentFeesCogsModal } from "./fbt-fulfillment-fees-cogs-modal";

export const FbtFulfillmentFeesConfigureButton = ({
  type = "WRONG",
}: {
  type?: "WRONG" | "NORMAL" | "CONFIGURE";
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {type === "WRONG" ? (
        <_Button
          onClick={() => setIsOpen(true)}
          className="w-auto flex items-center gap-2 bg-orange-500/10 border-orange-500/20 text-orange-500"
          variant="outline"
          size="sm"
        >
          <TriangleAlertIcon className="w-4 h-4 mr-2" />
          Does this look wrong?
        </_Button>
      ) : type === "CONFIGURE" ? (
        <_Button
          onClick={() => setIsOpen(true)}
          className="h-auto hover:bg-transparent p-0 text-[10px] hover:text-blue-600 flex items-center gap-2 text-blue-500 underline"
          variant="ghost"
          size="sm"
        >
          <Settings2Icon className="w-3 h-3" />
          Configure
        </_Button>
      ) : (
        <_Button
          onClick={() => setIsOpen(true)}
          className="w-full flex items-center gap-2"
          variant="secondary"
          size="sm"
        >
          <SettingsIcon className="w-4 h-4" />
          Configure
        </_Button>
      )}

      <FbtFulfillmentFeesCogsModal open={isOpen} setOpen={setIsOpen} />
    </div>
  );
};
