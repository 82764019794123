import { useRequest } from "./request.hooks";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { LiteStatisticPeriod } from "@/types/LiteStatisticPeriod";
import { TiktokAffiliateSkuSettledPeriodStatistic } from "@/types/TiktokAffiliateSkuSettledPeriodStatistics";
import { jsonToBase64 } from "@/lib/base64";
import { defaultTaxOptions } from "@/types/TaxConfigurationItem";

export const LITE_SETTLED_DASHBOARD_STATISTICS = "liteSettledDashboardStatistics";
export const LITE_SETTLED_AFFILIATE_STATISTICS = "liteSettledAffiliateStatistics";
export const LITE_SETTLED_AFFILIATE_STATISTICS_BY_PRODUCT_IDS = "liteSettledAffiliateStatisticsByProductIds";

export const useLiteSettledDashboardStatistics = (dateFrom: Date, dateTo: Date, enabled?: boolean, productId?: string, type?: "byStatementTime" | "byDay"): { data: LiteStatisticPeriod[], isLoading: boolean, error: Error | null } => {
    const { request } = useRequest();
    const { token, taxRate, taxRateValidFrom, selectedSellerAccountId, v2, cogsCalculation, taxOptions } = useContext(UserStateContext);
    const { data, isLoading, error } = useQuery({
        queryKey: [LITE_SETTLED_DASHBOARD_STATISTICS, token, enabled, dateFrom, dateTo, selectedSellerAccountId, productId, cogsCalculation, taxOptions, type],
        enabled,
        queryFn: () =>
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve(
                        request({
                            url: `/api/v1/tiktokOrders/retrieve/liteSettledDashboardStatistics`,
                            method: "GET",
                            params: {   
                                dateFrom: dateFrom.toISOString(),
                                dateTo: dateTo.toISOString(),
                                defaultTaxRate: taxRate.toString(),
                                taxRateValidFrom: taxRateValidFrom?.toISOString() || "",
                                sellerAccountId: selectedSellerAccountId!.toString(),
                                productId: productId || "",
                                v2: v2.toString(),
                                shouldIncludeAdSpend: "true",
                                cogsCalculation: cogsCalculation,
                                taxOptions: jsonToBase64(taxOptions || defaultTaxOptions),
                                type: type || "byOrderCreateTime"
                            }
                        })
                    );
                }, 500);
            })
                .then((response: any) => {
                    if (response.success) {
                        return response.content;
                    } else {
                        throw new Error(response.errorMessage || "Failed to fetch lite settled dashboard statistics");
                    }
                }),
        staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds
    });

    return { 
        data, isLoading, error
    };
};

export const useLiteSettledAffiliateStatistics = (dateFrom: Date, dateTo: Date, productId?: string): { data: TiktokAffiliateSkuSettledPeriodStatistic[], isLoading: boolean, error: Error | null } => {
    const { request } = useRequest();
    const { token, selectedSellerAccountId, cogsCalculation } = useContext(UserStateContext);
    
    const { data, isLoading, error } = useQuery({
        queryKey: [LITE_SETTLED_AFFILIATE_STATISTICS, token, dateFrom, dateTo, selectedSellerAccountId, productId, cogsCalculation],
        queryFn: () =>
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve(
                        request({
                            url: `/api/v1/tiktokOrders/retrieve/liteSettledAffiliateStatistics`,
                            method: "GET",
                            params: {   
                                dateFrom: dateFrom.toISOString(),
                                dateTo: dateTo.toISOString(),
                                sellerAccountId: selectedSellerAccountId!.toString(),
                                productId: productId || "",
                                cogsCalculation: cogsCalculation
                            }
                        })
                    );
                }, 500);
            })
                .then((response: any) => {
                    if (response.success) {
                        return response.content;
                    } else {
                        throw new Error(response.errorMessage || "Failed to fetch lite settled affiliate statistics");
                    }
                }),
        staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds
    });

    return { 
        data, isLoading, error
    };
};

export const useLiteSettledAffiliateStatisticsByProductIds = (dateFrom: Date, dateTo: Date, productIds?: string[]): { data: Record<string, TiktokAffiliateSkuSettledPeriodStatistic[]>, isLoading: boolean, error: Error | null } => {
    const { request } = useRequest();
    const { token, selectedSellerAccountId, cogsCalculation } = useContext(UserStateContext);
    
    const { data, isLoading, error } = useQuery({
        queryKey: [LITE_SETTLED_AFFILIATE_STATISTICS_BY_PRODUCT_IDS, token, dateFrom, dateTo, selectedSellerAccountId, productIds, cogsCalculation],
        queryFn: () =>
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve(
                        request({
                            url: `/api/v1/tiktokOrders/retrieve/liteSettledAffiliateStatisticsByProductIds`,
                            method: "GET",
                            params: {   
                                dateFrom: dateFrom.toISOString(),
                                dateTo: dateTo.toISOString(),
                                sellerAccountId: selectedSellerAccountId!.toString(),
                                productIds: productIds ? productIds.join(',') : "",
                            }
                        })
                    );
                }, 500);
            })
                .then((response: any) => {
                    if (response.success) {
                        return response.content;
                    } else {
                        throw new Error(response.errorMessage || "Failed to fetch lite settled affiliate statistics by product IDs");
                    }
                }),
        staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds
    });

    return { 
        data, isLoading, error
    };
};
