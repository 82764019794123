import { useEffect, useMemo } from "react";
import { useContext } from "react";
import { DollarSign } from "lucide-react";
import { useLiteSettledDashboardStatistics } from "@/hooks/tiktokSkuSettledPeriod.hooks";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { collectSettledTotals } from "@/lib/utils";
import { ChartTile } from "@/types/ChartTile";
import { Badge } from "@/components/ui/badge";
import { ChartTileOverview } from "@/components/chart-tile";
import { useOrganisationPlan } from "@/hooks/organisation.hooks";

export default function AdsOverviewTile({
  selectedDateRange,
  fromDate,
  toDate,
  headerExtra,
  extraRightHeader,
  overrideTiles,
}: {
  selectedDateRange: string;
  fromDate: Date;
  toDate: Date;
  headerExtra?: React.ReactNode;
  extraRightHeader?: React.ReactNode;
  overrideTiles?: {
    dataKey: string;
    gridClass: string;
    hide?: boolean;
    color?: string;
    chartColor?: string;
  }[];
}) {
  const { data: organisationPlan } = useOrganisationPlan();
  const { data: liteSettledDashboardStatistics, isLoading: isSettledLoading } =
    useLiteSettledDashboardStatistics(
      fromDate,
      toDate,
      true,
      undefined,
      "byStatementTime"
    );
  const { currency, swapChartsOnDashboard } = useContext(UserStateContext);

  const settledTotals = useMemo(
    () => collectSettledTotals(liteSettledDashboardStatistics),
    [liteSettledDashboardStatistics]
  );

  const dashboardTiles: ChartTile[] = [
    {
      title: "Total Ad Spend",
      tooltip: "Total amount spent on advertising across all settled campaigns.",
      value:
        settledTotals && settledTotals.totalSpend
          ? `${currency.symbol}${settledTotals.totalSpend?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}`
          : `${currency.symbol}0.00`,
      icon: DollarSign,
      color: "from-purple-400 to-purple-600",
      chartColor: "#9333EA",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "totalSpend",
      gridClass: "col-span-2",
    }
  ].filter((tile) => {
    if (!overrideTiles) return true;
    const override = overrideTiles.find((o) => o.dataKey === tile.dataKey);
    if (!override) return false;
    tile.color = override.color || tile.color;
    tile.chartColor = override.chartColor || tile.chartColor;
    tile.gridClass = override.gridClass;
    return true;
  });

  return organisationPlan?.adSpendEnabled ? (
    <div className="space-y-2">
      <div className="mb-4">
        <div className="flex justify-between gap-4 items-center">
          <div className="flex items-center gap-4">
            <h3 className="text-lg font-semibold text-foreground">
              Ads Overview
            </h3>
            <Badge className="bg-purple-500/20 text-purple-500">
              ~ Estimated
            </Badge>
            {headerExtra}
          </div>
          <div className="flex items-center gap-4">
            {extraRightHeader && extraRightHeader}
          </div>
        </div>
      </div>

      <div className="pb-10 flex flex-col gap-4">
        {dashboardTiles.map((tile, index) =>
          tile.hide ? null : (
            <ChartTileOverview
              key={index}
              tile={tile}
              swapCharts={swapChartsOnDashboard}
              isLoading={isSettledLoading}
              isSettledLoading={isSettledLoading}
              combinedData={liteSettledDashboardStatistics}
            />
          )
        )}
      </div>
    </div>
  ) : null;
}
