import { cn } from "@/lib/utils";
import { Tooltip, TooltipContent, TooltipTrigger } from "@radix-ui/react-tooltip";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { CreditCard, HelpCircle, PauseCircle } from "lucide-react";

import {
  Ban,
  Package,
  PackageCheck,
  Truck,
  CheckCircle2,
  XCircle,
  } from "lucide-react";

export const orderStatusConfig = {
  UNPAID: {
    label: "Unpaid",
    icon: CreditCard,
    className:
      "bg-orange-600/40 dark:text-orange-200 light:text-orange-500 border-orange-800 rounded-md",
    description: "The order is placed, but payment is not yet completed.",
  },
  ON_HOLD: {
    label: "On Hold",
    icon: PauseCircle,
    className:
      "bg-gray-600/40 dark:text-gray-200 light:text-gray-500 border-gray-800 rounded-md",
    description:
      "The order is accepted and is waiting for fulfillment. The buyer may still cancel without seller's approval.",
  },
  AWAITING_SHIPMENT: {
    label: "Awaiting Shipment",
    icon: Package,
    className:
      "bg-blue-600/40 dark:text-blue-200 light:text-blue-500 border-blue-800 rounded-md",
    description:
      "The order is ready for shipment, but no items are shipped yet.",
  },
  PARTIALLY_SHIPPING: {
    label: "Partially Shipped",
    icon: PackageCheck,
    className:
      "bg-blue-600/40 dark:text-blue-200 light:text-blue-500 border-blue-800 rounded-md",
    description: "Some items in the order are shipped, but not all.",
  },
  AWAITING_COLLECTION: {
    label: "Awaiting Collection",
    icon: Package,
    className:
      "bg-blue-600/40 dark:text-blue-200 light:text-blue-500 border-blue-800 rounded-md",
    description:
      "The shipment is arranged, but the package is waiting to be collected by the carrier.",
  },
  IN_TRANSIT: {
    label: "In Transit",
    icon: Truck,
    className:
      "bg-blue-600/40 dark:text-blue-200 light:text-blue-500 border-blue-800 rounded-md",
    description:
      "The package is collected by the carrier and delivery is in progress.",
  },
  DELIVERED: {
    label: "Delivered",
    icon: CheckCircle2,
    className:
      "bg-green-400/40 dark:text-green-200 light:text-green-500 border-green-800 rounded-md",
    description: "The package is delivered to buyer.",
  },
  COMPLETED: {
    label: "Completed",
    icon: CheckCircle2,
    className:
      "bg-green-600/40 dark:text-green-200 light:text-green-500 border-green-800 rounded-md",
    description:
      "The order is completed, and no further returns or refunds are allowed.",
  },
  CANCELLED: {
    label: "Cancelled",
    icon: XCircle,
    className:
      "bg-red-600/40 dark:text-red-200 light:text-red-500 border-red-800 rounded-md",
    description: "The order is cancelled.",
  },
};

export const OrderStatusBadge = ({ status }: { status: string }) => {
  const config = orderStatusConfig[
    status as keyof typeof orderStatusConfig
  ] || {
    label: status,
    icon: HelpCircle,
    className: "bg-gray-100 text-gray-800 border-gray-200",
    description: "Unknown status",
  };
  const Icon = config.icon;

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <div
            className={cn(
              "inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 gap-1",
              config.className
            )}
          >
            <Icon className="shrink-0 w-3 h-3" />
            <span>{config.label}</span>
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p className="max-w-xs">{config.description}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};