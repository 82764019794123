import { useContext } from "react";
import { useRequest } from "./request.hooks";
import { useQuery } from "@tanstack/react-query";
import { UserStateContext } from "@/contexts/UserStateProvider";

export const TIKTOK_BUSINESS_ACCOUNT_LIST = "tiktokBusinessAccountList";

interface TiktokBusinessAccount {
    id: number;
    avatarUrl: string;
    tiktokCoreUserId: string;
    displayName: string;
    email: string;
    setupOperationsCompleted: boolean;
    organisationId: number;
    advertisers: {
        id: number;
        name: string | null;
        advertiserAccountType: 'AUCTION' | 'RESERVATION';
        currency: string;
        role: 'ROLE_ADVERTISER' | 'ROLE_CHILD_ADVERTISER' | 'ROLE_CHILD_AGENT' | 'ROLE_AGENT';
        description: string | null;
        country: string;
        company: string | null;
        advertiserId: string;
    }[];
    createdAt: Date;
    updatedAt: Date;
}

export const useTiktokBusinessAccountList = () => {
    const { request } = useRequest();
    const { token } = useContext(UserStateContext);
    
    const { isLoading, isFetched, error, data } = useQuery({
        queryKey: [TIKTOK_BUSINESS_ACCOUNT_LIST, token],
        queryFn: () =>
            request({
                url: "/api/v1/tiktokBusinessAccount/retrieve/all",
                method: "GET"
            })
                .then(response => {
                    if (response.success) {
                        return response.content;
                    } else {
                        throw new Error(response.errorMessage || "Failed to fetch TikTok business accounts");
                    }
                }),
        staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds
    });

    return {
        businessAccounts: data as TiktokBusinessAccount[],
        isLoading,
        isFetched,
        error
    };
};

export const useTiktokBusinessAccountService = () => {
    const { request } = useRequest();

    const authorize = (payload: { authCode: string }) => {
        return request({
            url: "/api/v1/tiktokBusinessAccount/actions/authorize",
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            data: payload
        });
    };

    const getAuthorizeUrl = () => {
        return request({
            url: "/api/v1/tiktokBusinessAccount/actions/getAuthorizeUrl",
            method: "GET"
        });
    };

    return {
        authorize,
        getAuthorizeUrl
    };
};
