import { useOrdersWithEstimates } from "@/hooks/tiktokOrder.hooks";
import { TiktokOrder } from "@/types/TikTokOrder";
import { getDateRange, cn } from "@/lib/utils";
import { useContext, useEffect, useState } from "react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { LoadingFallback } from "@/components/loading-fallback";
import {
  Ban,
  FilterIcon,
  ImageIcon,
  Menu,
  CreditCard,
  PauseCircle,
  Package,
  PackageCheck,
  Truck,
  CheckCircle2,
  XCircle,
  HelpCircle,
  CalendarIcon,
  SlidersHorizontal,
  AlertTriangle,
  Info,
  InfoIcon,
} from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Badge } from "@/components/ui/badge";
import { Link, useNavigate } from "react-router-dom";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import moment from "moment";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { DatePicker } from "@/components/ui/date-picker";
import { useForm } from "@/hooks/form.hook";
import { orderStatusConfig } from "@/components/order-statuses";
import { OrderStatusBadge } from "@/components/order-statuses";
import { Currency } from "@/types/Currency";

const fieldMappings: Record<
  string,
  {
    header: string;
    order: number;
    isNotCurrency?: boolean;
    isSubtract?: boolean;
    isNeutral?: boolean;
    isPercentage?: boolean;
    comingSoon?: boolean;
    size?: "lg";
    estimated?: boolean;
    customRender?: (order: TiktokOrder, currency: Currency) => React.ReactNode;
  }
> = {
  netUnits: {
    header: "Net Units",
    order: 0.5,
    isNotCurrency: true,
    isNeutral: true,
  },
  margin: {
    header: "Net Margin",
    order: 0.75,
    isPercentage: true,
    isNeutral: true,
  },
  totalGrossSales: {
    header: "Gross Sales",
    order: 1,
    isNeutral: true,
  },
  totalMerchandiseSales: {
    header: "Sales",
    order: 2,
    isNeutral: true,
  },
  estimatedNetTax: {
    header: "Net Tax",
    order: 2.5,
    estimated: true,
    isSubtract: true,
  },
  estimatedTax: {
    header: "Tax",
    order: 3,
    estimated: true,
    isSubtract: true,
  },
  estimatedTaxBack: {
    header: "Tax Back",
    order: 3.5,
    estimated: true,
  },
  potentialAffiliateCommission: {
    header: "Affiliates",
    order: 5,
    isSubtract: true,
    estimated: true,
  },
  costOfGoods: {
    header: "COGS",
    order: 6,
    isSubtract: true,
  },
  estimatedFbmShippingCost: {
    header: "FBM Shipping",
    order: 8,
    isSubtract: true,
    estimated: true,
  },
  estimatedFbtShippingCost: {
    header: "FBT Shipping",
    order: 9,
    isSubtract: true,
    estimated: true,
  },
  totalEstimatedFbtFulfilmentAndPackagingFees: {
    header: "FBT Fulfill. & Packaging",
    order: 10,
    isSubtract: true,
    estimated: true,
  },
  sellerShippingCost: {
    header: "Seller Shipping",
    order: 11,
    isSubtract: true,
  },
  estimatedPlatformFee: {
    header: "TikTok Commiss. Fee",
    order: 12,
    isSubtract: true,
    estimated: true,
  },
  netProfits: {
    header: "Net Profits",
    order: 13,
    size: "lg",
    customRender: (order: TiktokOrder, currency: Currency) => {
      const netProfitExcAff =
        order.mergoioData.netProfits +
        order.mergoioData.potentialAffiliateCommission;
      const netProfitIncAff = order.mergoioData.netProfits;
      return (
        <div className="text-left justify-start flex gap-2 items-center">
          <RenderValue
            value={netProfitExcAff}
            mappings={{
              ...fieldMappings,
              netProfits: {
                ...fieldMappings.netProfits,
                customRender: undefined,
              },
            }}
            order={order}
            field="netProfits"
            currency={currency}
          />
          <div className="text-foreground/70">to</div>
          <RenderValue
            value={netProfitIncAff}
            mappings={{
              ...fieldMappings,
              netProfits: {
                ...fieldMappings.netProfits,
                customRender: undefined,
              },
            }}
            order={order}
            field="netProfits"
            currency={currency}
          />
        </div>
      );
    },
  },
};

interface FilterDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  fromDate: Date | undefined;
  toDate: Date | undefined;
  selectedStatuses: string[];
  onlyPaidOrders: boolean;
  onlySampleOrders: boolean;
  onApplyFilters: (filters: {
    fromDate: Date | undefined;
    toDate: Date | undefined;
    selectedStatuses: string[];
    onlyPaidOrders: boolean;
    onlySampleOrders: boolean;
  }) => void;
}

const FilterDialog = ({
  open,
  onOpenChange,
  fromDate: initialFromDate,
  toDate: initialToDate,
  selectedStatuses: initialSelectedStatuses,
  onlyPaidOrders: initialOnlyPaidOrders,
  onlySampleOrders: initialOnlySampleOrders,
  onApplyFilters,
}: FilterDialogProps) => {
  const form = useForm({
    fields: {
      fromDate: initialFromDate,
      toDate: initialToDate,
      selectedStatuses: initialSelectedStatuses,
      onlyPaidOrders: initialOnlyPaidOrders,
      onlySampleOrders: initialOnlySampleOrders,
    },
    validate: {
      fromDate: (value) => {
        if (value && !form.state.toDate) {
          return "Please select an end date";
        }
        return null;
      },
      toDate: (value) => {
        if (value && !form.state.fromDate) {
          return "Please select a start date";
        }
        if (value && form.state.fromDate && value < form.state.fromDate) {
          return "End date must be after start date";
        }
        return null;
      },
    },
    runValidationOnEveryChange: true,
  });

  const toggleStatus = (status: string) => {
    const currentStatuses = form.retrieveFieldValue("selectedStatuses");
    form.updateFieldValue(
      "selectedStatuses",
      currentStatuses.includes(status)
        ? currentStatuses.filter((s: string) => s !== status)
        : [...currentStatuses, status]
    );
  };

  const handleApply = () => {
    if (form.validate()) {
      return;
    }
    onApplyFilters({
      fromDate: form.state.fromDate,
      toDate: form.state.toDate,
      selectedStatuses: form.state.selectedStatuses,
      onlyPaidOrders: form.state.onlyPaidOrders,
      onlySampleOrders: form.state.onlySampleOrders,
    });
    onOpenChange(false);
  };

  useEffect(() => {
    form.validate();
  }, [form.state]);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="w-[625px]">
        <DialogHeader>
          <DialogTitle>Filter Orders</DialogTitle>
        </DialogHeader>
        <div className="w-full flex flex-col gap-10">
          <div>
            <Label>Date Range</Label>
            <div className="flex gap-4">
              <div className="w-full lg:w-1/2">
                <DatePicker
                  date={form.state.fromDate}
                  onDateChange={(date) => {
                    form.setState({
                      fromDate: date || undefined,
                      toDate: !date ? undefined : form.state.toDate,
                    });
                  }}
                  className={
                    form.errors.fromDate ? "border border-red-500" : ""
                  }
                />
                {form.errors.fromDate && (
                  <div className="text-red-500 text-sm mt-1">
                    {form.errors.fromDate}
                  </div>
                )}
              </div>
              <div className="w-full lg:w-1/2">
                <DatePicker
                  date={form.state.toDate}
                  onDateChange={(date) => {
                    form.setState({
                      toDate: date || undefined,
                      fromDate: !date ? undefined : form.state.fromDate,
                    });
                  }}
                  className={form.errors.toDate ? "border border-red-500" : ""}
                />
                {form.errors.toDate && (
                  <div className="text-red-500 text-sm mt-1">
                    {form.errors.toDate}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <Label>Order Status</Label>
            <div className="grid grid-cols-2 gap-2">
              {Object.entries(orderStatusConfig).map(([status, config]) => (
                <div key={status} className="flex items-center space-x-2">
                  <Checkbox
                    id={status}
                    checked={form.state.selectedStatuses.includes(status)}
                    onCheckedChange={() => toggleStatus(status)}
                  />
                  <Label
                    htmlFor={status}
                    className="text-sm flex items-center gap-2"
                  >
                    <OrderStatusBadge status={status} />
                  </Label>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <Label>Additional</Label>
            <div className="flex items-center space-x-2">
              <Checkbox
                id="paid-orders"
                checked={form.state.onlyPaidOrders}
                onCheckedChange={(checked) =>
                  form.updateFieldValue("onlyPaidOrders", !!checked)
                }
              />
              <Label htmlFor="paid-orders">Paid orders</Label>
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox
                id="sample-orders"
                checked={form.state.onlySampleOrders}
                onCheckedChange={(checked) =>
                  form.updateFieldValue("onlySampleOrders", !!checked)
                }
              />
              <Label htmlFor="sample-orders">Sample orders</Label>
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button
            variant="secondary"
            type="submit"
            onClick={handleApply}
            className="mt-4 w-full bg-blue-600 hover:bg-blue-800"
          >
            <FilterIcon className="w-4 h-4 mr-2" />
            Apply Filters
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const RenderValue = ({
  mappings,
  order,
  field,
  currency,
  value,
  hideOperators = false,
}: {
  mappings: Record<
    string,
    {
      header: string;
      order: number;
      isNotCurrency?: boolean;
      isSubtract?: boolean;
      isNeutral?: boolean;
      isPercentage?: boolean;
      comingSoon?: boolean;
      estimated?: boolean;
      customRender?: (
        order: TiktokOrder,
        currency: Currency
      ) => React.ReactNode;
    }
  >;
  order: TiktokOrder;
  field: string;
  currency: Currency;
  value: number;
  hideOperators?: boolean;
}) => {
  const fieldMapping = mappings[field];
  if (fieldMapping.customRender) {
    return <>{fieldMapping.customRender(order, currency)}</>;
  }

  const isNotCurrency = fieldMapping.isNotCurrency;
  const isPercentage = fieldMapping.isPercentage;
  const mergoioData = value;
  const isNegative = fieldMapping.isSubtract
    ? true
    : mergoioData < 0
    ? true
    : false;
  const isPositive = mergoioData > 0;
  const isNeutral = fieldMapping.isNeutral ?? mergoioData === 0;
  return (
    <>
      {fieldMapping.comingSoon ? (
        <div className="flex items-center gap-1 font-normal text-xs text-orange-500">
          <AlertTriangle className="shrink-0 w-3 h-3" />
          Coming Soon
        </div>
      ) : isPercentage ? (
        <span
          className={cn(
            mergoioData === 0
              ? "opacity-50"
              : mergoioData < 0
              ? "text-red-500"
              : "text-green-500"
          )}
        >
          {mergoioData === 0 ? "-" : `${mergoioData?.toFixed(2)}%`}
        </span>
      ) : isNotCurrency ? (
        mergoioData?.toLocaleString()
      ) : (
        <span
          className={cn(
            isNeutral
              ? "opacity-50"
              : isNegative
              ? "text-red-500"
              : isPositive
              ? "text-green-500"
              : "text-foreground"
          )}
        >
          {!hideOperators &&
            (isNeutral ? "" : isNegative ? "- " : isPositive ? "+ " : "")}
          {`${currency.symbol}${Math.abs(mergoioData).toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}`}
        </span>
      )}
    </>
  );
};

export const OrderStatistics = () => {
  const { listOrders } = useContext(UserStateContext);
  const {
    fromDate,
    toDate,
    selectedStatuses,
    onlyPaidOrders,
    filterDialogOpen,
    onlySampleOrders,
    currentPage,
  } = listOrders;
  const {
    setFromDate,
    setToDate,
    setSelectedStatuses,
    setOnlyPaidOrders,
    setFilterDialogOpen,
    setOnlySampleOrders,
    setCurrentPage,
  } = listOrders;

  const pageSize = 20;
  const { currency, isSidebarOpen, setIsSidebarOpen } =
    useContext(UserStateContext);
  const [filterCount, setFilterCount] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    let count = 0;
    if (selectedStatuses.length > 0) {
      count += selectedStatuses.length;
    }
    if (onlyPaidOrders) {
      count += 1;
    }
    if (fromDate || toDate) {
      count += 1;
    }
    setFilterCount(count);
  }, [selectedStatuses, onlyPaidOrders, fromDate, toDate]);

  const [selectedFields, setSelectedFields] = useState<string[]>([
    "totalMerchandiseSales",
    "estimatedNetTax",
    "margin",
    "netUnits",
    "totalEstimatedFbtFulfilmentAndPackagingFees",
    "costOfGoods",
    "estimatedFbmShippingCost",
    "estimatedFbtShippingCost",
    "sellerShippingCost",
    "netProfits",
  ]);

  const { data, isLoading } = useOrdersWithEstimates({
    page: currentPage,
    pageSize,
    dateFrom: fromDate,
    dateTo: toDate,
    statuses: selectedStatuses,
    hasPaid: onlyPaidOrders,
    isSampleOrder: onlySampleOrders,
  });

  const toggleField = (field: string) => {
    setSelectedFields((prev) =>
      prev.includes(field) ? prev.filter((f) => f !== field) : [...prev, field]
    );
  };

  const handleApplyFilters = (filters: {
    fromDate: Date | undefined;
    toDate: Date | undefined;
    selectedStatuses: string[];
    onlyPaidOrders: boolean;
    onlySampleOrders: boolean;
  }) => {
    setFromDate(filters.fromDate);
    setToDate(filters.toDate);
    setSelectedStatuses(filters.selectedStatuses);
    setOnlyPaidOrders(filters.onlyPaidOrders);
    setOnlySampleOrders(filters.onlySampleOrders);
    setCurrentPage(1);
  };

  const sortedSelectedFields = selectedFields.sort(
    (a, b) => fieldMappings[a].order - fieldMappings[b].order
  );

  const renderPagination = () => {
    return (
      data && (
        <Pagination className="flex justify-end">
          <PaginationContent>
            <PaginationItem>
              <PaginationPrevious
                onClick={() =>
                  setCurrentPage((prev: number) => Math.max(prev - 1, 1))
                }
                className="cursor-pointer rounded-full bg-background border hover:bg-muted/50 transition-colors"
              />
            </PaginationItem>
            {(() => {
              const totalPages = data.totalPages;
              const currentPage = data.currentPage;
              let startPage = Math.max(currentPage - 1, 1);
              let endPage = Math.min(startPage + 2, totalPages);

              if (endPage - startPage < 2) {
                startPage = Math.max(endPage - 2, 1);
              }

              return Array.from(
                { length: endPage - startPage + 1 },
                (_, i) => startPage + i
              ).map((page) => (
                <PaginationItem key={page}>
                  <PaginationLink
                    onClick={() => setCurrentPage(page)}
                    isActive={page === currentPage}
                    className={`rounded-full cursor-pointer ${
                      page === currentPage
                        ? "bg-blue-500/50"
                        : "bg-background hover:bg-muted/50"
                    } border transition-colors`}
                  >
                    {page}
                  </PaginationLink>
                </PaginationItem>
              ));
            })()}
            <PaginationItem>
              <PaginationNext
                onClick={() => setCurrentPage((prev: number) => prev + 1)}
                className="cursor-pointer rounded-full bg-background border hover:bg-muted/50 transition-colors"
              />
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      )
    );
  };

  return (
    <div className="min-h-screen flex-1 flex flex-col overflow-hidden">
      <main className="flex-1 bg-background overflow-y-auto min-h-screen pb-32">
        <header className="border-b pt-6 pb-2 shadow-sm h-auto flex items-center gap-4 px-4 md:px-6">
          <div className="flex items-center gap-4 w-full md:w-auto">
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="md:hidden text-foreground"
            >
              <Menu size={24} />
            </button>
            <h2 className="text-xl font-semibold text-foreground">Orders</h2>
            <div className="flex items-center gap-4">
              <Button
                variant="outline"
                className={cn(
                  "flex gap-2 items-center",
                  filterCount > 0 ? "bg-blue-600 hover:bg-blue-800" : ""
                )}
                onClick={() => setFilterDialogOpen(true)}
              >
                <SlidersHorizontal className="w-4 h-4" />
                Filters ({filterCount})
              </Button>

              <FilterDialog
                open={filterDialogOpen}
                onOpenChange={setFilterDialogOpen}
                fromDate={fromDate}
                toDate={toDate}
                selectedStatuses={selectedStatuses}
                onlyPaidOrders={onlyPaidOrders}
                onlySampleOrders={onlySampleOrders}
                onApplyFilters={handleApplyFilters}
              />

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" className="flex gap-2 items-center">
                    <FilterIcon className="w-4 h-4 mr-2" />
                    {selectedFields.length > 0 ? "" : "Select"} Fields (
                    {selectedFields.length})
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56">
                  <DropdownMenuLabel>Toggle Fields</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  {Object.entries(fieldMappings)
                    .sort(([, a], [, b]) => a.order - b.order)
                    .map(([field, mapping]) => {
                      const typedMapping = mapping as {
                        header: string;
                        order: number;
                      };
                      return (
                        <DropdownMenuCheckboxItem
                          key={field}
                          onSelect={(e) => e.preventDefault()}
                          checked={selectedFields.includes(field)}
                          onCheckedChange={() => toggleField(field)}
                        >
                          {typedMapping.header}
                        </DropdownMenuCheckboxItem>
                      );
                    })}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>

          {renderPagination()}
        </header>

        <div className="mt-6 px-6">
          <LoadingFallback loading={isLoading}>
            {!data || !data?.items || data.items.length === 0 ? (
              <div className="pt-24 flex flex-col items-center justify-center text-muted-foreground">
                <Ban className="h-12 w-12 mb-4" />
                <p className="text-lg opacity-75">No orders found</p>
                <p className="text-sm opacity-50">
                  Try adjusting your filters or date range to see more orders
                </p>
              </div>
            ) : (
              <>
                <div className="mt-2 bg-background border rounded-xl overflow-hidden">
                  <Table>
                    <TableHeader>
                      <TableRow className="bg-muted/50">
                        <TableHead className="font-semibold text-foreground/70 w-[200px]">
                          Details
                        </TableHead>
                        <TableHead className="font-semibold text-foreground/70">
                          Items
                        </TableHead>
                        {sortedSelectedFields.map((field) => (
                          <TableHead
                            key={field}
                            className={cn(
                              "font-semibold text-foreground/70 min-w-[100px] max-w-[100px]",
                              fieldMappings[field].size === "lg"
                                ? `min-w-[200px] max-w-[200px]`
                                : "max-w-[100px]"
                            )}
                          >
                            <div className="flex flex-col">
                              {fieldMappings[field].header}
                              {fieldMappings[field].estimated && (
                                <div className="flex items-center gap-1 opacity-50 font-normal text-xs">
                                  <AlertTriangle className="w-3 h-3" />
                                  Estimated
                                </div>
                              )}
                            </div>
                          </TableHead>
                        ))}
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {data.items.map((order) => (
                        <TableRow
                          key={order.id}
                          className={cn(
                            "hover:bg-muted/50 cursor-pointer transition-colors"
                          )}
                          onClick={() =>
                            navigate(`/analytics/orders/${order.id}`)
                          }
                        >
                          <TableCell>
                            <div className="flex flex-col items-start gap-2">
                              <div>
                                <div className="text-sm font-semibold">
                                  {order.id}
                                </div>
                                <div className="text-xs text-muted-foreground flex gap-1 items-center">
                                  <CalendarIcon className="w-3 h-3" />
                                  {moment(order.createTime).format(
                                    "MMM D, YYYY h:mm A"
                                  )}
                                </div>
                              </div>
                              <OrderStatusBadge status={order.status} />
                              <TooltipProvider>
                                <Tooltip delayDuration={0}>
                                  <TooltipTrigger>
                                    <Badge className="text-xs rounded-md flex items-center gap-1 bg-muted/50 font-normal text-foreground border-gray-200/10">
                                      <Info className="w-3 h-3 shrink-0" />
                                      {order.fulfillmentType ===
                                      "FULFILLMENT_BY_TIKTOK"
                                        ? "FBT"
                                        : order.fulfillmentType ===
                                            "FULFILLMENT_BY_SELLER" &&
                                          order.shippingType === "TIKTOK"
                                        ? "SLT"
                                        : "SLS"}
                                    </Badge>
                                  </TooltipTrigger>
                                  <TooltipContent className="w-[200px]">
                                    <p className="font-semibold mb-1">
                                      {order.fulfillmentType ===
                                      "FULFILLMENT_BY_TIKTOK"
                                        ? "Fulfillment by TikTok (FBT)"
                                        : order.fulfillmentType ===
                                            "FULFILLMENT_BY_SELLER" &&
                                          order.shippingType === "TIKTOK"
                                        ? "Shipping Labels by TikTok (SLT)"
                                        : "Shipping Labels by Seller (SLS)"}
                                    </p>
                                    <p className="text-sm text-muted-foreground">
                                      {order.fulfillmentType ===
                                      "FULFILLMENT_BY_TIKTOK"
                                        ? "TikTok handles storage, shipping and fulfillment"
                                        : order.fulfillmentType ===
                                            "FULFILLMENT_BY_SELLER" &&
                                          order.shippingType === "TIKTOK"
                                        ? "Seller handles storage and fulfillment, TikTok generates shipping labels"
                                        : "Seller handles storage, fulfillment and shipping labels"}
                                    </p>
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                              {order.isSampleOrder && (
                                <Badge className="text-xs rounded-md flex items-center gap-1 bg-red-500/10 font-normal text-red-500 border-red-200/10">
                                  <Info className="w-3 h-3 shrink-0" />
                                  Sample
                                </Badge>
                              )}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="flex flex-col gap-2">
                              <div className="flex flex-wrap gap-2">
                                {order.aggregatedOrderLineItems?.map((item) => (
                                  <TooltipProvider key={item.id}>
                                    <Tooltip delayDuration={0}>
                                      <TooltipTrigger>
                                        <div className="relative hover:shadow-lg transition-shadow">
                                          <div className="w-8 h-8 overflow-hidden rounded-lg shadow-lg">
                                            {item.sku.skuImageUrl ||
                                            item.sku.product.mainImageUrl ? (
                                              <img
                                                src={
                                                  item.sku.skuImageUrl &&
                                                  item.sku.skuImageUrl.length >
                                                    0
                                                    ? item.sku.skuImageUrl
                                                    : item.sku.product
                                                        .mainImageUrl
                                                }
                                                alt={item.sku.computedSku}
                                                className="w-full h-full object-cover"
                                              />
                                            ) : (
                                              <div className="w-full h-full bg-muted rounded-lg shadow-sm flex items-center justify-center">
                                                <ImageIcon className="w-6 h-6 text-gray-400" />
                                              </div>
                                            )}
                                          </div>
                                          <Badge
                                            variant="secondary"
                                            className="absolute -top-2 -right-2 bg-blue-500 text-xxs text-white"
                                          >
                                            {item.units}
                                          </Badge>
                                        </div>
                                      </TooltipTrigger>
                                      <TooltipContent className="w-[300px]">
                                        <div className="space-y-1">
                                          <p className="font-semibold">
                                            {item.sku.product.title}
                                          </p>
                                          <p>
                                            Seller SKU:{" "}
                                            {item.sku.sellerSku ?? "N/A"}
                                          </p>
                                          <p>
                                            TikTok SKU: {item.sku.computedSku}
                                          </p>
                                          <Link
                                            to={`/analytics/products/${item.sku.product.id}`}
                                            className="text-blue-500 hover:underline"
                                          >
                                            Click to view product details
                                          </Link>
                                        </div>
                                      </TooltipContent>
                                    </Tooltip>
                                  </TooltipProvider>
                                ))}
                              </div>
                            </div>
                          </TableCell>
                          {sortedSelectedFields.map((field) => (
                            <TableCell key={field}>
                              <RenderValue
                                key={field}
                                mappings={fieldMappings}
                                order={order}
                                field={field}
                                currency={currency}
                                value={
                                  order.mergoioData[
                                    field as keyof typeof order.mergoioData
                                  ]
                                }
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </>
            )}
            <div className="mt-8">
            {renderPagination()}
            </div>
          </LoadingFallback>
        </div>
      </main>
    </div>
  );
};
