import {
  BarChart2,
  ChevronRight,
  HelpCircle,
  Home,
  LineChart,
  Settings,
  ShoppingBag,
  X,
  Zap,
  Package,
  Users,
  TrendingUp,
  SettingsIcon,
  CreditCardIcon,
  TrashIcon,
  BookOpen,
} from "lucide-react";
import { useContext, useState, useEffect } from "react";
import { Button } from "../ui/button";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { MergoioLogo } from "./MergoioLogo";
import { SwitchSellerAccount } from "../switch-seller-account";
import { OrderCount } from "../order-count";
import { ModeToggle } from "../mode-toggle";

export const _Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isSidebarOpen, setIsSidebarOpen } = useContext(UserStateContext);
  const [activeMenuItem, setActiveMenuItem] = useState("");
  const [openSubmenus, setOpenSubmenus] = useState<string[]>([]);

  const toggleSubmenu = (item: string) => {
    setOpenSubmenus((prev) =>
      prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
    );
  };
  const menuItems = [
    // {
    //   icon: BookOpen,
    //   text: "Summary", 
    //   color: "from-orange-400 to-orange-600",
    //   link: "/",
    // },
    {
      icon: LineChart,
      text: "Sales & Profit",
      color: "from-blue-400 to-blue-600",
      link: "/",
    },
    {
      icon: ShoppingBag,
      text: "Your Shop",
      color: "from-green-400 to-green-600",
      subItems: [
        {
          icon: Package,
          text: "Products",
          link: "/products",
          description: "Manage your product catalog",
        },
        // { icon: ShoppingCart, text: "Orders", link: "/orders", description: "View and manage orders" },
        // { icon: Users, text: "Customers", link: "/customers", description: "Manage customer information" },
        // { icon: Users, text: "Affiliates", link: "/affiliates", description: "Manage affiliate programs" }
      ],
    },
    {
      icon: BarChart2,
      text: "Analytics",
      color: "from-purple-400 to-purple-600",
      subItems: [
        {
          icon: ShoppingBag,
          text: "Orders",
          link: "/analytics/orders",
          description: "View performance metrics by order",
        },
        {
          icon: Package,
          text: "Products",
          link: "/analytics/products",
          description: "View performance metrics by product",
        },
        {
          icon: TrashIcon,
          text: "Cancellations",
          link: "/analytics/cancellations",
          description: "View cancellation metrics by product",
        },
        // { icon: TrendingUp, text: "Sales & Profit", link: "/analytics/sales-profit", description: "Analyze sales and profit data" },
        {
          icon: Users,
          text: "Affiliates",
          link: "/analytics/affiliates",
          description: "View affiliate performance over time",
        },
      ],
    },
    // {
    //   icon: Zap,
    //   text: "AI Insights",
    //   color: "from-pink-400 to-pink-600",
    //   subItems: [
    //     { icon: TrendingUp, text: "Trend Analysis", link: "/ai-insights/trend-analysis", description: "AI-powered trend predictions" },
    //     { icon: BarChart2, text: "Performance Predictions", link: "/ai-insights/performance-predictions", description: "AI-generated performance forecasts" },
    //     { icon: MessageSquare, text: "Content Suggestions", link: "/ai-insights/content-suggestions", description: "AI-powered content recommendations" },
    //   ],
    // },
    // { icon: Settings, text: "Settings", color: "from-red-400 to-red-600", link: "/settings" },
    {
      icon: SettingsIcon,
      text: "Settings",
      color: "from-red-400 to-red-600",
      link: "/settings",
    },
    {
      icon: CreditCardIcon,
      text: "Billing",
      color: "from-yellow-400 to-yellow-600",
      link: "/billing",
    },
    {
      icon: TrendingUp,
      text: "Ad Accounts",
      color: "from-indigo-400 to-indigo-600",
      link: "/tiktok/businessAccounts",
    },
    {
      icon: HelpCircle,
      text: "Help",
      color: "from-gray-400 to-gray-600",
      link: "/help",
    },
  ];

  useEffect(() => {
    const currentPath = location.pathname;
    const activeItem = menuItems.find(
      (item) =>
        (item.link &&
          (currentPath === item.link ||
            (item.link === "/" && currentPath === "/home"))) ||
        (item.subItems &&
          item.subItems.some(
            (subItem) => subItem.link && currentPath.startsWith(subItem.link)
          ))
    );
    if (activeItem) {
      setActiveMenuItem(activeItem.text);
      if (activeItem.subItems) {
        setOpenSubmenus((prev) =>
          prev.includes(activeItem.text) ? prev : [...prev, activeItem.text]
        );
      }
    }
  }, [location, menuItems]);

  return (
    <aside
      className={`md:bg-muted/40 bg-muted/100 border-r w-64 min-h-screen flex flex-col transition-all duration-300 ease-in-out ${
        isSidebarOpen ? "translate-x-0" : "-translate-x-full"
      } md:translate-x-0 md:static fixed z-30`}
    >
      <div className="flex items-center justify-between h-16 px-4">
        <MergoioLogo />
        <ModeToggle />
        <button
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="md:hidden text-foreground"
        >
          <X size={24} />
        </button>
      </div>
      <SwitchSellerAccount />
      <div className="mx-4 mt-2 bg-muted/40 rounded-md">
        <OrderCount compact />
      </div>
      <nav className="flex-grow py-6">
        <ul className="space-y-1 px-2">
          {menuItems.map((item, index) => (
            <li key={index}>
              <Button
                variant="ghost"
                className={`group w-full justify-start px-3 py-2 text-sm font-medium text-muted-foreground hover:text-foreground hover:bg-muted/50 transition-all duration-200 rounded-lg ${
                  activeMenuItem === item.text ? "bg-muted text-foreground" : ""
                }`}
                onClick={() => {
                  if (item.subItems) {
                    toggleSubmenu(item.text);
                  } else if (item.link) {
                    setActiveMenuItem(item.text);
                    navigate(item.link);
                  }
                }}
              >
                <div
                  className={`w-8 h-8 mr-3 rounded-md bg-gradient-to-br ${item.color} flex items-center justify-center transition-transform duration-300 ease-in-out group-hover:rotate-12`}
                >
                  <item.icon className="w-5 h-5 text-white" />
                </div>
                <span>{item.text}</span>
                {item.subItems && (
                  <ChevronRight
                    size={16}
                    className={`ml-auto transition-transform duration-200 ${
                      // openSubmenus.includes(item.text) ? "rotate-90" : ""
                      "rotate-90"
                    }`}
                  />
                )}
              </Button>
              {item.subItems && (
                <ul className="mt-1 ml-6 flex flex-col gap-4">
                  {item.subItems.map((subItem, subIndex) => (
                    <li key={subIndex}>
                      <Link to={subItem.link}>
                        <Button
                          variant="ghost"
                          className={`h-auto w-full justify-start px-3 py-1 text-xs font-medium text-muted-foreground hover:text-foreground hover:bg-muted/50 transition-all duration-200 rounded-md ${
                            location.pathname.startsWith(subItem.link)
                              ? "bg-muted text-foreground"
                              : ""
                          }`}
                          onClick={() => setActiveMenuItem(item.text)}
                        >
                          <div className="flex items-start w-full gap-4">
                            <subItem.icon size={12} className="mt-1 shrink-0" />
                            <div className="flex flex-col items-start whitespace-normal text-left">
                              <span className="text-sm font-medium">
                                {subItem.text}
                              </span>
                              <span className="text-xs opacity-75 font-light">
                                {subItem.description}
                              </span>
                            </div>
                          </div>
                        </Button>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  );
};
