import React, { useState, useEffect } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { DatePicker } from "@/components/ui/date-picker";
import { Label } from "@/components/ui/label";
import { CheckIcon, FilterIcon } from "lucide-react";
import moment from "moment";
import { getDateRange } from "@/lib/utils";

interface SelectDateRangeProps {
  onDateRangeChange: (fromDate: Date, toDate: Date) => void;
  initialFromDate?: Date;
  initialToDate?: Date;
  initialRange?: string;
}

export const SelectDateRange = ({
  onDateRangeChange,
  initialFromDate,
  initialToDate,
  initialRange = "14",
}: SelectDateRangeProps) => {
  const [selectedDateRange, setSelectedDateRange] = useState(initialRange);
  const [fromDate, setFromDate] = useState<Date | null>(
    initialFromDate || null
  );
  const [toDate, setToDate] = useState<Date | null>(initialToDate || null);
  const [dateRangeDialogOpen, setDateRangeDialogOpen] = useState(false);
  const [errors, setErrors] = useState<{ fromDate?: string; toDate?: string }>(
    {}
  );
  const [dateDropdownOpen, setDateDropdownOpen] = useState(false);
  useEffect(() => {
    if (selectedDateRange !== "custom") {
      const { from, to } = getDateRange(selectedDateRange);
      setFromDate(from);
      setToDate(to);
      if (from && to) {
        onDateRangeChange(from, to);
      }
    }
  }, [selectedDateRange]);

  const handleDateRangeSelect = (value: string) => {
    setSelectedDateRange(value);
  };

  const validate = () => {
    const newErrors: { fromDate?: string; toDate?: string } = {};

    if (!fromDate && toDate) {
      newErrors.fromDate = "Please select a start date";
    }
    if (!toDate && fromDate) {
      newErrors.toDate = "Please select an end date";
    }
    if (fromDate && toDate && moment(toDate).isBefore(moment(fromDate))) {
      newErrors.toDate = "End date must be after start date";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleApplyCustomDates = () => {
    if (!validate()) return;
    if (!fromDate || !toDate) return;

    onDateRangeChange(fromDate, toDate);
    setSelectedDateRange("custom");
    setDateRangeDialogOpen(false);
  };

  return (
    <div>
      <Select
        value={selectedDateRange}
        onValueChange={handleDateRangeSelect}
        open={dateDropdownOpen}
        onOpenChange={setDateDropdownOpen}
      >
        <SelectTrigger className="w-[250px]">
          {selectedDateRange === "custom" ? (
            <div className="w-full flex justify-between pr-4">
              <span>{moment(fromDate).format("MMM D, YYYY")}</span>
              <span>-</span>
              <span>{moment(toDate).format("MMM D, YYYY")}</span>
            </div>
          ) : (
            <SelectValue placeholder="Select date range" />
          )}
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="7">Last 7 days</SelectItem>
          <SelectItem value="14">Last 14 days</SelectItem>
          <SelectItem value="30">Last 30 days</SelectItem>
          <SelectItem value="60">Last 60 days</SelectItem>
          <SelectItem value="90">Last 90 days</SelectItem>
          <SelectItem value="180">Last 180 days</SelectItem>
          <SelectItem value="365">Last 365 days</SelectItem>
          <div
            className="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 cursor-pointer hover:bg-accent"
            onClick={() => {
              setDateDropdownOpen(false);
              setDateRangeDialogOpen(true);
            }}
          >
            <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
              {selectedDateRange === "custom" && (
                <CheckIcon className="h-4 w-4" />
              )}
            </span>
            Custom Range
          </div>
        </SelectContent>
      </Select>

      <Dialog open={dateRangeDialogOpen} onOpenChange={setDateRangeDialogOpen}>
        <DialogContent className="w-[625px]">
          <DialogHeader>
            <DialogTitle>Select Date Range</DialogTitle>
          </DialogHeader>
          <div className="w-full flex flex-col gap-10">
            <div>
            
              <div className="flex gap-4">
                <div className="w-full lg:w-1/2">
                  <Label>From</Label>
                  <DatePicker
                    date={fromDate ?? undefined}
                    onDateChange={(date) => setFromDate(date ?? null)}
                    className={errors.fromDate ? "border border-red-500" : ""}
                  />
                  {errors.fromDate && (
                    <div className="text-red-500 text-sm mt-1">
                      {errors.fromDate}
                    </div>
                  )}
                </div>
                <div className="w-full lg:w-1/2">
                  <Label>To</Label>
                  <DatePicker
                    date={toDate ?? undefined}
                    onDateChange={(date) => setToDate(date ?? null)}
                    className={errors.toDate ? "border border-red-500" : ""}
                  />
                  {errors.toDate && (
                    <div className="text-red-500 text-sm mt-1">
                      {errors.toDate}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <DialogFooter>
            <Button
              variant="secondary"
              className="mt-4 w-full bg-blue-600 hover:bg-blue-800"
              type="submit"
              onClick={handleApplyCustomDates}
            >
              <FilterIcon className="w-4 h-4 mr-2" />
              Apply
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
