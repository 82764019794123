import React, { useState } from 'react';
import { _Button } from '@/components/button';
import { Settings2Icon, SettingsIcon } from 'lucide-react';
import { PlatformFeesCogsModal } from './platform-fees-cogs-modal';

export const PlatformFeesConfigureButton = ({
  type = "BUTTON"
}: {
  type?: "CONFIGURE" | "BUTTON"
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {type === "BUTTON" ? (
        <_Button
          onClick={() => setIsOpen(true)}
          className="w-full flex items-center gap-2"
          variant="secondary"
          size="sm"
        >
          <SettingsIcon className="w-4 h-4" />
          Configure
        </_Button>
      ) : (
        <_Button
          onClick={() => setIsOpen(true)}
          className="h-auto hover:bg-transparent p-0 text-[10px] hover:text-blue-600 flex items-center gap-2 text-blue-500 underline"
          variant="ghost"
          size="sm"
        >
          <Settings2Icon className="w-3 h-3" />
          Configure
        </_Button>
      )}

      <PlatformFeesCogsModal
        open={isOpen}
        setOpen={setIsOpen}
      />
    </>
  );
};
