import { useEffect, useMemo } from "react";
import { useContext } from "react";
import {
  DollarSign,
  TrendingUp,
  Package,
  Percent,
  CreditCard,
  Truck,
  Info,
} from "lucide-react";
import { useLiteSettledDashboardStatistics } from "@/hooks/tiktokSkuSettledPeriod.hooks";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { collectSettledTotals } from "@/lib/utils";
import { collectTotals } from "@/lib/utils";
import { ChartTile } from "@/types/ChartTile";
import { TooltipContent } from "@/components/ui/tooltip";
import { Switch } from "@/components/ui/switch";
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { ChartTileOverview } from "@/components/chart-tile";

export default function SalesOverviewTile({
  selectedDateRange,
  fromDate,
  toDate,
  onTotalSalesChange,
  onTotalCogsChange,
  headerExtra,
  extraRightHeader,
  overrideTiles,
}: {
  selectedDateRange: string;
  fromDate: Date;
  toDate: Date;
  onTotalSalesChange: (totalSales: number) => void;
  onTotalCogsChange: (totalCogs: number) => void;
  headerExtra?: React.ReactNode;
  extraRightHeader?: React.ReactNode;
  overrideTiles?: {
    dataKey: string;
    gridClass: string;
    hide?: boolean;
    color?: string;
    chartColor?: string;
  }[];
}) {
  const { data: liteSettledDashboardStatistics, isLoading: isSettledLoading } =
    useLiteSettledDashboardStatistics(
      fromDate,
      toDate,
      true,
      undefined,
      "byStatementTime"
    );
  const {
    swapChartsOnDashboard,
    setSwapChartsOnDashboard,
    currency,
    isCumulative,
  } = useContext(UserStateContext);

  const settledTotals = useMemo(
    () => collectSettledTotals(liteSettledDashboardStatistics),
    [liteSettledDashboardStatistics]
  );

  useEffect(() => {
    console.log("settledTotals", settledTotals);
  }, [settledTotals]);

  const dashboardTiles: ChartTile[] = [
    // {
    //   subtitle: "exc. cancelled orders",
    //   title: "Gross Sales",
    //   tooltip: "Total gross sales before any discounts excludes shipping fees.",
    //   value:
    //     totals && totals.totalGrossSales
    //       ? `${currency.symbol}${totals.totalGrossSales?.toLocaleString(
    //           undefined,
    //           { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    //         )}`
    //       : `${currency.symbol}0.00`,
    //   settledValue:
    //     settledTotals && settledTotals.settledGrossSales
    //       ? `${
    //           currency.symbol
    //         }${settledTotals.settledGrossSales?.toLocaleString(undefined, {
    //           minimumFractionDigits: 2,
    //           maximumFractionDigits: 2,
    //         })}`
    //       : `${currency.symbol}0.00`,
    //   change: 15,
    //   icon: DollarSign,
    //   color: "from-green-400 to-green-600",
    //   chartColor: "#10B981",
    //   format: "currency",
    //   dataKey: "totalGrossSales",
    //   gridClass: "col-span-1",
    //   settledDataKey: "settledGrossSales",
    // },
    {
      title: "Net Sales",
      tooltip: "Net revenue amount after seller discounts are deducted.",
      value:
        settledTotals && settledTotals.settledMerchandiseSales
          ? `${
              currency.symbol
            }${settledTotals.settledMerchandiseSales?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}`
          : `${currency.symbol}0.00`,
      change: 15,
      icon: DollarSign,
      color: "from-blue-400 to-blue-600",
      chartColor: "#3B82F6",
      format: "currency",
      dataKey: "settledMerchandiseSales",
      gridClass: "col-span-2",
    },
    {
      title: "Net Profits",
      tooltip:
        "Earnings after all costs and expenses have been deducted from revenue (tax back included in calculations, configure in chart settings).",
      value:
        settledTotals && settledTotals.settledNetProfits
          ? `${
              currency.symbol
            }${settledTotals.settledNetProfits?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : `${currency.symbol}0.00`,
      change: -3,
      icon: TrendingUp,
      color: "from-teal-400 to-teal-600",
      chartColor: "#14B8A6",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "settledNetProfits",
      gridClass: "col-span-2",
    },
    {
      title: "Net Margin",
      tooltip:
        "The percentage of revenue that is left after all costs and expenses have been deducted for this product.",
      value: settledTotals?.settledTotalMargin
        ? `${settledTotals.settledTotalMargin.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}%`
        : `0.00%`,
      icon: TrendingUp,
      color: "from-teal-400 to-teal-600",
      chartColor: "#14B8A6",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "settledTotalMargin",
      gridClass: "col-span-3",
      isNotCurrency: true,
      hide: true,
    },
    {
      title: "Cost of Goods",
      tooltip:
        "Direct costs attributable to the production of the goods sold by a company.",
      value:
        settledTotals && settledTotals.settledCostOfGoods
          ? `${
              currency.symbol
            }${settledTotals.settledCostOfGoods?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : `${currency.symbol}0.00`,
      change: 2,
      icon: Package,
      color: "from-purple-400 to-purple-600",
      chartColor: "#8B5CF6",
      format: "currency",
      dataKey: "settledCostOfGoods",
      gridClass: "col-span-1",
    },
    {
      title: "Affiliates",
      tooltip:
        "Estimated fees paid to affiliates for referring customers (incl. ads and partner commissions).",
      value:
        settledTotals && settledTotals.settledAffiliateCommission
          ? `${
              currency.symbol
            }${settledTotals.settledAffiliateCommission?.toLocaleString(
              undefined,
              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            )}`
          : `${currency.symbol}0.00`,
      change: 7,
      icon: Percent,
      color: "from-pink-400 to-pink-600",
      chartColor: "#EC4899",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "settledAffiliateCommission",
      gridClass: "col-span-1",
    },
    {
      title: "TikTok Commiss. Fee",
      tooltip:
        "Estimation of fees charged by the e-commerce platform for using their services.",
      value:
        settledTotals && settledTotals.settledPlatformCommission
          ? `${
              currency.symbol
            }${settledTotals.settledPlatformCommission?.toLocaleString(
              undefined,
              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            )}`
          : `${currency.symbol}0.00`,
      change: 3,
      icon: CreditCard,
      color: "from-orange-400 to-orange-600",
      chartColor: "#F97316",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "settledPlatformCommission",
      gridClass: "col-span-1",
    },
    {
      title: "Net Units Sold",
      tooltip: "The total number of units sold excluding cancelled units.",
      value:
        settledTotals && settledTotals.settledUnits
          ? settledTotals.settledUnits.toString()
          : "0",
      change: 3,
      icon: Truck,
      color: "from-purple-400 to-purple-600",
      chartColor: "#9333EA",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "settledUnits",
      gridClass: "col-span-1",
      isNotCurrency: true,
    },
    {
      title: "Net Seller Discounts",
      tooltip:
        "Net seller discounts applied to the customer payment. Excludes cancelled seller discounts.",
      value:
        settledTotals && settledTotals.settledSellerDiscountAmount
          ? `${
              currency.symbol
            }${settledTotals.settledSellerDiscountAmount?.toLocaleString(
              undefined,
              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            )}`
          : `${currency.symbol}0.00`,
      change: 15,
      icon: DollarSign,
      color: "from-amber-400 to-amber-600",
      chartColor: "#F59E0B",
      format: "currency",
      dataKey: "settledSellerDiscountAmount",
      gridClass: "col-span-2  ",
    },
    {
      title: "Net Platform Discounts",
      tooltip:
        "Net platform discounts applied to the customer payment. Excludes cancelled platform discounts.",
      value:
        settledTotals && settledTotals.settledPlatformDiscountAmount
          ? `${
              currency.symbol
            }${settledTotals.settledPlatformDiscountAmount?.toLocaleString(
              undefined,
              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            )}`
          : `${currency.symbol}0.00`,
      change: 15,
      icon: DollarSign,
      color: "from-rose-400 to-rose-600",
      chartColor: "#F43F5E",
      format: "currency",
      dataKey: "settledPlatformDiscountAmount",
      gridClass: "col-span-2",
    },
  ].filter((tile) => {
    if (!overrideTiles) return true;
    const override = overrideTiles.find((o) => o.dataKey === tile.dataKey);
    if (!override) return false;
    tile.hide = override.hide;
    tile.color = override.color || tile.color;
    tile.chartColor = override.chartColor || tile.chartColor;
    tile.gridClass = override.gridClass;
    return true;
  });

  return (
    <div className="space-y-2">
      {/* Date Range Filter and Sales Overview Title */}
      <div className="mb-4 flex justify-between gap-4 items-center">
        <div className="flex items-center gap-4">
          <h3 className="text-lg font-semibold text-foreground">
            Sales Overview
          </h3>
          <div className="flex items-center gap-2">
            {headerExtra}
            <Switch
              checked={swapChartsOnDashboard}
              onCheckedChange={setSwapChartsOnDashboard}
            />
            <span className="text-sm text-muted-foreground">
              Settled Chart Mode
            </span>
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipTrigger>
                  <Info size={14} className="text-muted-foreground" />
                </TooltipTrigger>
                <TooltipContent className="text-lg w-[300px]">
                  <p>
                    Switch to settled chart mode to see completed (settled)
                    orders and future projections based on live & estimate data.
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
        <div className="flex items-center gap-4">
          {extraRightHeader && extraRightHeader}
        </div>
      </div>

      {/* Dashboard Tiles */}
      <div className="pb-10 grid grid-cols-4 gap-4">
        {dashboardTiles.map((tile, index) =>
          tile.hide ? null : (
            <ChartTileOverview
              key={index}
              tile={tile}
              swapCharts={swapChartsOnDashboard}
              isLoading={isSettledLoading}
              isSettledLoading={isSettledLoading}
              combinedData={liteSettledDashboardStatistics}
            />
          )
        )}
      </div>
    </div>
  );
}
