import { TiktokOrder } from "@/types/TikTokOrder";
import { BanIcon, CalendarIcon, TriangleAlertIcon } from "lucide-react";
import moment from "moment";

export const OrderTimeline = ({ order }: { order: TiktokOrder }) => {
  return (
    <>
      <div className="space-y-1">
        <p className="flex items-center gap-2 text-sm text-muted-foreground">
          <CalendarIcon className="w-4 h-4 text-primary" />
          <span className="font-medium text-foreground">Created:</span>{" "}
          {order.createTime ? moment(order.createTime).format("DD MMM YYYY [at] HH:mm") : "N/A"}
        </p>
        <p className="flex items-center gap-2 text-sm text-muted-foreground">
          <CalendarIcon className="w-4 h-4 text-green-500" />
          <span className="font-medium text-foreground">Paid:</span>{" "}
          {order.paidTime ? moment(order.paidTime).format("DD MMM YYYY [at] HH:mm") : "N/A"}
        </p>
        <p className="flex items-center gap-2 text-sm text-muted-foreground">
          <CalendarIcon className="w-4 h-4 text-red-500" />
          <span className="font-medium text-foreground">Cancelled:</span>{" "}
          {order.cancelTime ? moment(order.cancelTime).format("DD MMM YYYY [at] HH:mm") : "N/A"}
        </p>
        <p className="flex items-center gap-2 text-sm text-muted-foreground">
          <CalendarIcon className="w-4 h-4 text-red-400" />
          <span className="font-medium text-foreground">
            Cancel Order SLA:
          </span>{" "}
          {order.cancelOrderSlaTime ? moment(order.cancelOrderSlaTime).format("DD MMM YYYY [at] HH:mm") : "N/A"}
        </p>
        <p className="flex items-center gap-2 text-sm text-muted-foreground">
          <CalendarIcon className="w-4 h-4 text-emerald-500" />
          <span className="font-medium text-foreground">Delivered:</span>{" "}
          {order.deliveryTime ? moment(order.deliveryTime).format("DD MMM YYYY [at] HH:mm") : "N/A"}
        </p>
      </div>

      {(order.isBuyerRequestCancelled ||
        order.orderLineItems.some((item) => item.cancelReason)) && (
        <div className="mt-4">
          <p className="font-medium text-red-500">Cancellation Details</p>
          {order.isBuyerRequestCancelled && (
            <p className="flex items-center gap-2 text-sm text-red-500">
              <TriangleAlertIcon className="w-4 h-4" />
              Buyer Requested Cancellation
            </p>
          )}
          {order.cancellationInitiator && (
            <p className="flex items-center gap-2 text-sm text-red-500">
              <BanIcon className="w-4 h-4" />
              Initiated by: {order.cancellationInitiator}
            </p>
          )}
        </div>
      )}
    </>
  );
};
