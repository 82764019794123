export type TaxConfigurationItem = {
    taxRate: number | null;
    taxRefundRate: number | null;
    taxRateValidFrom: Date | null;
};

export type TaxOptions = {
    salesTax: TaxConfigurationItem;
    adSpendTax: TaxConfigurationItem;
    costTax: TaxConfigurationItem;
    platformFeeTax: TaxConfigurationItem;
    affiliateCommissionTax: TaxConfigurationItem;
    fbmShippingCostTax: TaxConfigurationItem;
    fbtShippingCostTax: TaxConfigurationItem;
    sellerShippingCostTax: TaxConfigurationItem;
    fbtFulfilmentAndPackagingFeesTax: TaxConfigurationItem;
    fbtShippingCostTaxBack: TaxConfigurationItem;
}

export const defaultTaxOptions: TaxOptions = {
    salesTax: {
        taxRate: 20,
        taxRefundRate: 20,
        taxRateValidFrom: null,
    },
    adSpendTax: {
        taxRate: 20,
        taxRefundRate: 20,
        taxRateValidFrom: null,
    },
    costTax: {
        taxRate: 20,
        taxRefundRate: 20,
        taxRateValidFrom: null,
    },
    platformFeeTax: {
        taxRate: 20,
        taxRefundRate: 20,
        taxRateValidFrom: null,
    },
    affiliateCommissionTax: {
        taxRate: 20,
        taxRefundRate: 20,
        taxRateValidFrom: null,
    },
    fbmShippingCostTax: {
        taxRate: 20,
        taxRefundRate: 20,
        taxRateValidFrom: null,
    },
    fbtShippingCostTax: {
        taxRate: 20,
        taxRefundRate: 20,
        taxRateValidFrom: null,
    },
    sellerShippingCostTax: {
        taxRate: 20,
        taxRefundRate: 20,
        taxRateValidFrom: null,
    },
    fbtFulfilmentAndPackagingFeesTax: {
        taxRate: 20,
        taxRefundRate: 20,
        taxRateValidFrom: null,
    },
    fbtShippingCostTaxBack: {
        taxRate: 20,
        taxRefundRate: 20,
        taxRateValidFrom: null,
    }
}