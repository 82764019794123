import { useRequest } from "@/hooks/request.hooks";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { OrganisationPlan, OrganisationPlanStatus } from "@/types/Organisation";
import { Currency } from "@/types/Currency";
import moment from "moment";
import { defaultTaxOptions } from "@/types/TaxConfigurationItem";

export const ORGANISATION_PLAN = "organisationPlan";
export const TOTAL_ORDER_COUNT = "totalOrderCount";
export const STRIPE_SUBSCRIPTION_DETAILS = "stripeSubscriptionDetails";
export const ORGANISATION_USAGE = "organisationUsage";
export const ORGANISATION_BETA = "organisationBeta";

export const useOrganisationPlan = (): {
    data: {
        plan: OrganisationPlan,
        planStatus: OrganisationPlanStatus,
        planTrialEndsAt: string | null,
        planSubscriptionId: string | null,
        eligibleForTrial: boolean,
        adSpendEnabled: boolean,
        settings: {
            taxRate: number,
            taxRateValidFrom: string | null,
            currency: string,
            addPurchasesTaxReclaimToNetProfits: boolean
        }
    } | undefined,
    isLoading: boolean,
    isFetched: boolean,
    error: Error | null
} => {
    const { request } = useRequest();
    const { isAuthenticated, token, setTaxOptions, setCurrency, setTaxRate, setTaxRateValidFrom, setAddPurchasesTaxReclaimToNetProfits, setCogsCalculation } = useContext(UserStateContext);

    const { data, isFetched, isLoading, error } = useQuery({
        enabled: isAuthenticated,
        queryKey: [ORGANISATION_PLAN, isAuthenticated, token],
        queryFn: () =>
            request({
                url: "/api/v1/organisation/retrieve/plan",
                method: "GET",
            })
                .then(data => {
                    return {
                        ...data.content,
                        currency: data.content.currency ? JSON.parse(data.content.currency) : null  
                    }
                }),
        staleTime: 1000 * 60 * 5
    });

    useEffect(() => {
        if (data?.settings) {
            if(data.settings.currency) {
                setCurrency(data.settings.currency);
            }
            if(data.settings.taxRate) {
                setTaxRate(data.settings.taxRate);
            } else {
                setTaxRate(20);
            }
            if(data.settings.taxRateValidFrom) {
                setTaxRateValidFrom(moment(data.settings.taxRateValidFrom).toDate());
            } else {
                setTaxRateValidFrom(null);
            }
            if(data.settings.cogsCalculation) {
                setCogsCalculation(data.settings.cogsCalculation);
            } else {
                setCogsCalculation("EXCLUDE_UNSHIPPED_CANCELLED_ORDERS");
            }
            if(data.settings.addPurchasesTaxReclaimToNetProfits) {
                setAddPurchasesTaxReclaimToNetProfits(data.settings.addPurchasesTaxReclaimToNetProfits);
            } else if (data.settings.addPurchasesTaxReclaimToNetProfits != null 
                && data.settings.addPurchasesTaxReclaimToNetProfits != undefined
                && data.settings.addPurchasesTaxReclaimToNetProfits === false) {
                setAddPurchasesTaxReclaimToNetProfits(false);
            }
            if(data.settings.taxOptions && Object.keys(data.settings.taxOptions).length > 0) {
                setTaxOptions(data.settings.taxOptions);
            } else {
                setTaxOptions(defaultTaxOptions);
            }
        }
    }, [data]);

    return {
        isFetched,
        data,
        isLoading,
        error
    };
};

export const useStripeSubscriptionDetails = (): {
    data: {
        plan: OrganisationPlan,
        planStatus: OrganisationPlanStatus,
        price: number,
        startDate: string | null,
        endDate: string | null,
        cancelAt: string | null
    } | undefined,
    isLoading: boolean,
    error: Error | null
} => {
    const { request } = useRequest();
    const { token } = useContext(UserStateContext);

    const { data, isLoading, error } = useQuery({
        queryKey: [STRIPE_SUBSCRIPTION_DETAILS, token],
        queryFn: () =>
            request({
                url: "/api/v1/organisation/retrieve/stripeSubscriptionDetails",
                method: "GET",
            })
                .then(data => data.content),
        staleTime: 1000 * 60 * 5
    });

    return {
        data,
        isLoading,
        error
    };
};

export const useTotalOrderCount = (): {
    data: {
        monthlyOrderCounts: Record<string, number>
    } | undefined,
    isLoading: boolean,
    error: Error | null
} => {
    const { request } = useRequest();
    const { token } = useContext(UserStateContext);

    const { data, isLoading, error } = useQuery({
        queryKey: [TOTAL_ORDER_COUNT, token],
        queryFn: () =>
            request({
                url: "/api/v1/organisation/retrieve/totalOrderCount",
                method: "GET",
            })
                .then(data => data.content),
        staleTime: 1000 * 60 * 5
    });

    return {
        data,
        isLoading,
        error
    };
};

export const useOrganisationUsage = (): {
    data: {
        totalOrders: number
    } | undefined,
    isLoading: boolean,
    error: Error | null
} => {
    const { request } = useRequest();
    const { token } = useContext(UserStateContext);

    const { data, isLoading, error } = useQuery({
        queryKey: [ORGANISATION_USAGE, token],
        queryFn: () =>
            request({
                url: "/api/v1/organisation/retrieve/usage",
                method: "GET",
            })
                .then(data => data.content),
        staleTime: 1000 * 60 * 5
    });

    return {
        data,
        isLoading,
        error
    };
};

export const useOrganisationBeta = (): {
    data: {
        betaEnabled: boolean
    } | undefined,
    isLoading: boolean,
    error: Error | null
} => {
    const { request } = useRequest();
    const { token } = useContext(UserStateContext);

    const { data, isLoading, error } = useQuery({
        queryKey: [ORGANISATION_BETA, token],
        queryFn: () =>
            request({
                url: "/api/v1/organisation/retrieve/beta",
                method: "GET",
            })
                .then(data => data.content),
        staleTime: 1000 * 60 * 5
    });

    return {
        data,
        isLoading,
        error
    };
};

export const ORGANISATION_LIST = "organisationList";

interface Organisation {
    id: number;
    name: string | null;
    isCompany: boolean;
    businessId: string | null;
    addressLine1: string | null;
    addressLine2: string | null;
    postCodeOrZipCode: string | null;
    county: string | null;
    country: string | null;
    plan: string;
    planStatus: string;
    planTrialEndsAt: string | null;
    planSubscriptionId: string | null;
    latestOrderCount: number;
    latestOrderCountUpdatedAt: string;
    createdAt: string;
    updatedAt: string;
}

interface OrganisationListResponse {
    items: Organisation[];
    totalCount: number;
    currentPage: number;
    totalPages: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
}

export const useOrganisationList = (
    page: number = 1,
    pageSize: number = 10,
    plans?: string[],
    planStatuses?: string[]
) => {
    const { request } = useRequest();
    const { token } = useContext(UserStateContext);

    const { isLoading, isFetched, error, data } = useQuery<OrganisationListResponse>({
        queryKey: [ORGANISATION_LIST, token, page, pageSize, plans, planStatuses],
        queryFn: () =>
            request({
                url: "/api/admin/v1/organisations/retrieve/list",
                method: "GET",
                params: {
                    page: page.toString(),
                    pageSize: pageSize.toString(),
                    plans: plans?.join(",") || "",
                    planStatuses: planStatuses?.join(",") || ""
                }
            })
                .then((response: any) => {
                    if (response.success) {
                        return response.content;
                    } else {
                        throw new Error(response.errorMessage || "Failed to fetch organisations");
                    }
                }),
        staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds
    });

    return {
        organisations: data,
        isLoading,
        isFetched,
        error
    };
};


export const useOrganisationService = () => {
    const { request } = useRequest();

    const inviteEmailToOrg = (email: string) => {
        return request({
            url: "/api/v1/organisation/actions/inviteEmailToOrg",
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            data: { email }
        });
    };

    const choosePlan = (plan: OrganisationPlan) => {
        return request({
            url: "/api/v1/organisation/actions/choosePlan",
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            data: { plan }
        });
    };

    const cancelPlan = () => {
        return request({
            url: "/api/v1/organisation/actions/cancelPlan",
            method: "POST",
            headers: {
                "content-type": "application/json",
            }
        });
    };

    const unCancelPlan = () => {
        return request({
            url: "/api/v1/organisation/actions/unCancelPlan",
            method: "POST",
            headers: {
                "content-type": "application/json",
            }
        });
    };

    const updatePlan = (plan: OrganisationPlan) => {
        return request({
            url: "/api/v1/organisation/actions/updatePlan",
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            data: { plan }
        });
    };

    const redeemBetaCode = (code: string) => {
        return request({
            url: "/api/v1/organisation/actions/redeemBetaCode",
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            data: { code }
        });
    };

    const loginAsOrganisation = (organisationId: number) => {
        return request({
            url: "/api/admin/v1/organisations/actions/loginAsOrganisation",
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            data: { organisationId }
        });
    };

    const updateSettings = (settings: any) => {
        return request({
            url: "/api/v1/organisation/actions/updateSettings",
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            data: { settings }
        });
    };

    return {
        inviteEmailToOrg,
        choosePlan,
        cancelPlan,
        unCancelPlan,
        updatePlan,
        redeemBetaCode,
        loginAsOrganisation,
        updateSettings
    };
};
