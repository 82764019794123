import { UserStateContext } from "@/contexts/UserStateProvider";
import { useContext } from "react";
import { Route, Routes, useLocation } from "react-router";
import { Login } from "./public/Login";
import { SignUpRedirect } from "./public/SignUpRedirect";
import { _Sidebar } from "@/components/layout/_sidebar";
import { useRetrieveTiktokSellerAccountById } from "@/hooks/tiktokSellerAccount.hooks";
import { LoadingFallback } from "@/components/loading-fallback";
import { RouteProvider } from "./RouteProvider";
import { SellerAccountPortal } from "./private/portal/SellerAccountPortal";
import { DefaultLoader } from "@/components/default-loader";
import { SellerAccountSetup } from "./private/portal/SellerAccountSetup";
import { useOrganisationPlan } from "@/hooks/organisation.hooks";
import { OrganisationPlan, OrganisationPlanStatus } from "@/types/Organisation";
import { SetupPlan } from "./private/setupPlan/SetupPlan";
import { getTrialPlan } from "@/lib/trial";
import { SetupPlanSuccess } from "./private/setupPlan/SetupPlanSuccess";
import { Billing } from "./private/billing/Billing";
import { PreContextBilling } from "./private/billing/PreContextBilling";
import { Help } from "./private/help/Help";
import { PreContextHelp } from "./private/help/PreContextHelp";
import { Maintenance } from "@/components/maintenance-page";
import { AdminStateContext } from "@/contexts/AdminStateProvider";
import { AdminRouteProvider } from "./AdminRouteProvider";

const canAccessMergoio = ({
  plan,
  planStatus,
}: {
  plan: OrganisationPlan | undefined,
  planStatus: OrganisationPlanStatus | undefined
}) => {
  if (plan === "FREE") {
    return true;
  }

  if (plan !== "NONE" && planStatus === "ACTIVE") {
    return true;
  }

  return false;
}

export const RouteProviderWrapper = () => {
  const { isAuthenticated, selectedSellerAccountId } =
    useContext(UserStateContext);
  const { isAuthenticated: isAdminAuthenticated } = useContext(AdminStateContext);
  const {
    data: tiktokSellerAccount,
    isLoading: isTiktokSellerAccountLoading,
    isFetched: isTiktokSellerAccountFetched,
  } = useRetrieveTiktokSellerAccountById(selectedSellerAccountId);
  const { data: organisationPlan, isLoading: isOrganisationPlanLoading, isFetched: isOrganisationPlanFetched } = useOrganisationPlan();
  const canAccess = canAccessMergoio({
    plan: organisationPlan?.plan,
    planStatus: organisationPlan?.planStatus
  });
  const { isTrialPlan, hasTrialEnded } = getTrialPlan({
    plan: organisationPlan?.plan,
    planTrialEndsAt: organisationPlan?.planTrialEndsAt
  });
  const location = useLocation();

  if (!isAuthenticated) {
    return <RouteProvider />;
  }

  if(isAuthenticated && process.env.REACT_APP_MAINTENANCE && !isAdminAuthenticated) {
    return <Maintenance />
  }

  if (isAuthenticated 
    && (!canAccess || (isTrialPlan && hasTrialEnded)) 
    && isOrganisationPlanFetched) {
    return (
      <Routes location={location} key={location.pathname}>
        <Route path={"success"} element={<SetupPlanSuccess />} />
        <Route path={"*"} element={<SetupPlan />} />
      </Routes>
    );
  }

  if (isAuthenticated && !selectedSellerAccountId) {
    return (
      <Routes location={location} key={location.pathname}>
        <Route path={"*"} element={<SellerAccountPortal />} />
        <Route path={"/billing"} element={<PreContextBilling />} />
        <Route path={"/help"} element={<PreContextHelp />} />
      </Routes>
    );
  }

  if (selectedSellerAccountId && (isTiktokSellerAccountLoading || !isTiktokSellerAccountFetched)) {
    return (
      <div className="flex flex-col items-center gap-4 justify-center min-h-screen bg-background p-4">
        <DefaultLoader />
        <div className="text-xl opacity-50">Setting up...</div>
      </div>
    );
  }

  if(tiktokSellerAccount && !tiktokSellerAccount.setupOperationsCompleted) {
    return (
      <Routes location={location} key={location.pathname}>
        <Route path={"*"} element={<SellerAccountSetup />} />
        <Route path={"/billing"} element={<PreContextBilling />} />
        <Route path={"/help"} element={<PreContextHelp />} />
      </Routes>
    );
  }

  return <RouteProvider />;
};
