import { cn } from "@/lib/utils";
import { useEffect, useState } from "react";

export const AnimatedProgress = () => {
  const [progress, setProgress] = useState(-100);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => (prev >= 100 ? -100 : prev + 1));
    }, 20);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className={cn("h-full w-full rounded-full bg-blue-500")}
      style={{
        transform: `translateX(${progress}%)`,
        transition: "transform 0.02s linear"
      }}
    />
  );
};
