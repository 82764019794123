import { useContext } from "react"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { UserStateContext } from "@/contexts/UserStateProvider"
import { useOrganisationService } from "@/hooks/organisation.hooks"
import { cn } from "@/lib/utils"

const currencies = [
  { name: "GBP", symbol: "£" },
  { name: "USD", symbol: "$" },
  { name: "EUR", symbol: "€" },
]

export function CurrencySelect({ variant = "default" }: {
  variant?: "default" | "muted"
}) {
  const { currency, setCurrency } = useContext(UserStateContext)
  const { updateSettings } = useOrganisationService();
  return (
    <Select

      value={currency ? `${currency.symbol} ${currency.name}` : ""}
      onValueChange={(value) => {
        const selectedCurrency = currencies.find(c => `${c.symbol} ${c.name}` === value)
        if (selectedCurrency) {
          setCurrency(selectedCurrency)
          updateSettings({ currency: selectedCurrency })
        }
      }}
    >
      <SelectTrigger className={cn("w-[100px]", variant === "muted" && "bg-muted")}>
        <SelectValue placeholder="Select currency" />
      </SelectTrigger>
      <SelectContent>
        {currencies.map((item) => (
          <SelectItem key={item.name} value={`${item.symbol} ${item.name}`}>
            {item.symbol} {item.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
