import { Button } from "@/components/ui/button";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { useLiteDashboardStatistics } from "@/hooks/tiktokOrder.hooks";
import { useLiteSettledDashboardStatistics } from "@/hooks/tiktokSkuSettledPeriod.hooks";
import {
  collectSettledTotals,
  collectTotals,
  combineTotals,
} from "@/lib/utils";
import { ChartTile } from "@/types/ChartTile";
import { ChevronRight, Receipt } from "lucide-react";
import { useContext, useMemo } from "react";
import { ChartTileOverview } from "../../../../components/chart-tile";
import { Badge } from "@/components/ui/badge";
import { TaxSettingsPopover } from "@/components/tax-settings-popover";

export const TaxOverviewTile = ({
  fromDate,
  toDate,
}: {
  fromDate: Date;
  toDate: Date;
}) => {
  const { data: liteSettledDashboardStatistics, isLoading: isSettledLoading } =
    useLiteSettledDashboardStatistics(fromDate, toDate, true);
  const { data: liteDashboardStatistics, isLoading } =
    useLiteDashboardStatistics(fromDate, toDate);
  const { currency, isCumulative, swapChartsOnDashboard } = useContext(UserStateContext);

  const totals = useMemo(
    () => collectTotals(liteDashboardStatistics),
    [liteDashboardStatistics]
  );
  const settledTotals = useMemo(
    () => collectSettledTotals(liteSettledDashboardStatistics),
    [liteSettledDashboardStatistics]
  );
  const combinedData = useMemo(
    () =>
      combineTotals(
        liteDashboardStatistics,
        liteSettledDashboardStatistics,
        isCumulative
      ),
    [liteDashboardStatistics, liteSettledDashboardStatistics, isCumulative]
  );

  const tiles: ChartTile[] = [
    {
      title: "Total Tax",
      tooltip:
        "Estimate of all taxes collected on sales, based on the sales figure.",
      value:
        totals && totals.totalTax
          ? `${currency.symbol}${totals.totalTax?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : `${currency.symbol}0.00`,
      settledValue:
        settledTotals && settledTotals.settledTax
          ? `${currency.symbol}${settledTotals.settledTax?.toLocaleString(
              undefined,
              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            )}`
          : `${currency.symbol}0.00`,
      change: 5,
      icon: Receipt,
      color: "from-red-400 to-red-600",
      chartColor: "#EF4444",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "totalTax",
      gridClass: "col-span-2",
      settledDataKey: "settledTax",
      heightClass: "h-[100px]",
    },
    {
        title: "Purchases Tax Reclaim",
        tooltip:
          "Estimate of all taxes that can be reclaimed.",
        value:
          totals && totals.totalTaxBack
            ? `${currency.symbol}${totals.totalTaxBack?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : `${currency.symbol}0.00`,
        settledValue:
          settledTotals && settledTotals.settledTaxBack
            ? `${currency.symbol}${settledTotals.settledTaxBack?.toLocaleString(
                undefined,
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              )}`
            : `${currency.symbol}0.00`,
        change: 5,
        icon: Receipt,
        color: "from-green-400 to-green-600",
        chartColor: "#10B981",
        format: "currency",
        dataLabel: "~ ESTIMATED",
        dataKey: "totalTaxBack",
        gridClass: "col-span-2",
        heightClass: "h-[100px]",
        settledDataKey: "settledTaxBack",
      },
  ];

  return (
    <div className="w-full">
      <div className="mb-4">
        <div className="flex justify-between gap-4 items-center">
          <div className="flex items-center gap-4">
            <h3 className="text-lg font-semibold text-foreground">
              Tax Overview
            </h3>
            <Badge className="bg-orange-500/20 text-orange-500">
              ~ Estimated
            </Badge>
          </div>
        </div>
        <TaxSettingsPopover type="CONFIGURE" />
      </div>
      <div className="flex flex-col gap-4">
        {tiles.map((tile) => (
          <ChartTileOverview
            key={tile.title}
            tile={tile}
            swapCharts={swapChartsOnDashboard}
            isLoading={isLoading}
            isSettledLoading={isSettledLoading}
            combinedData={combinedData}
          />
        ))}
      </div>
    </div>
  );
};
