import { Form } from "@/hooks/form.hook";
import { _Button } from "@/components/button";
import {
  AlertTriangleIcon,
  CheckCircleIcon,
  CheckIcon,
  CircleXIcon,
  InfoIcon,
  UploadCloudIcon,
  XCircleIcon,
} from "lucide-react";
import {
  COST_OF_GOODS_BY_TYPE,
  useCostOfGoodsService,
} from "@/hooks/costOfGoods.hooks";
import { useEffect, useState, useContext } from "react";
import { CostOfGoods } from "@/types/CostOfGoods";
import { CogsDateRangeModal } from "../cogs-date-range-modal";
import { CogsDateRangeForm } from "../cogs-date-range-form";
import { LoadingFallback } from "@/components/loading-fallback";
import { useToast } from "@/hooks/toast.hooks";
import { useQueryClient } from "@tanstack/react-query";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { LITE_DASHBOARD_STATISTICS, LITE_PRODUCT_STATISTICS } from "@/hooks/tiktokOrder.hooks";
import { LITE_SETTLED_DASHBOARD_STATISTICS } from "@/hooks/tiktokSkuSettledPeriod.hooks";

export const FbtFulfillmentFeeCreateEditCogs = ({
  overrideSymbol,
  overrideValueHeader,
  form,
  onSaved
}: {
    form: Form;
    overrideSymbol?: string;
    overrideValueHeader?: string;
    onSaved?: () => void;
}) => {
  const { upsertCostOfGoods } = useCostOfGoodsService();
  const { toast } = useToast();
  const [isSaving, setIsSaving] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const queryClient = useQueryClient();
  const { currency } = useContext(UserStateContext);

  const handleSave = async () => {
    if (form.validate()) {
      return;
    }

    setIsSaving(true);
    try {
      const payload: {
        type: "FBT_FULFILLMENT_FEE";
        costOfGoodsList: CostOfGoods[];
      } = {
        type: "FBT_FULFILLMENT_FEE",
        costOfGoodsList: form.state.costOfGoodsList,
      };

      await new Promise((resolve) => setTimeout(resolve, 1500));
      const response = await upsertCostOfGoods(payload);
      if (!response.success) {
        toast({
          icon: <XCircleIcon size={12} className={"text-red-500"} />,
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: response.errorMessage!,
        });
        return;
      }

      setHasUnsavedChanges(false);
      queryClient.invalidateQueries({
        queryKey: [COST_OF_GOODS_BY_TYPE],
      });
      queryClient.invalidateQueries({
        queryKey: [LITE_DASHBOARD_STATISTICS],
      });
      queryClient.invalidateQueries({
        queryKey: [LITE_SETTLED_DASHBOARD_STATISTICS],
      });
      queryClient.invalidateQueries({
        queryKey: [LITE_PRODUCT_STATISTICS],
      });
      if (onSaved) onSaved();
      toast({
        icon: <CheckCircleIcon size={12} className={"text-green-500"} />,
        variant: "default",
        title: "Success",
        description: "Your FBT fulfillment fees have been successfully recorded.",
        duration: 2000,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "An unexpected error occurred",
        variant: "destructive",
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <div>
        <div className="border-b px-4 py-4 flex justify-between items-center mb-4">
          <div className="lg:w-1/2 w-full">
            <h2 className="text-xl font-semibold">FBT Fulfillment Fees</h2>
            <div className="mt-2 flex items-start gap-2 text-sm opacity-40">
              <InfoIcon className="w-5 h-5 flex-shrink-0 mt-0.5" />
              <p>
                TikTok's FBT fulfillment fees vary based on package dimensions and weight. Here, you can update the fees for specific periods. In the future, we plan to introduce dimension-based fulfillment fees for more accurate calculations.
              </p>
            </div>
          </div>
          <div className="lg:w-1/2 w-full flex items-center gap-4 justify-end">
            {hasUnsavedChanges && (
              <span className="text-amber-400 flex items-center">
                <AlertTriangleIcon className="w-4 h-4 mr-2" />
                Unsaved changes
              </span>
            )}
            <_Button
              className="mr-10 w-[300px] bg-blue-500 text-white hover:bg-blue-600 text-lg flex gap-2 items-center justify-between"
              variant="secondary"
              onClick={handleSave}
              loading={isSaving}
              disabled={!hasUnsavedChanges || form.state.costOfGoodsList.length === 0}
            >
              {hasUnsavedChanges ? "Publish Changes" : "No Changes to Publish"}
              <UploadCloudIcon className="w-4 h-4 ml-2" />
            </_Button>
          </div>
        </div>
        <LoadingFallback loading={isSaving}>
          <CogsDateRangeForm
            overrideDomainName="FBT Fulfillment Fee"
            overrideSymbol={overrideSymbol}
            overrideValueHeader={overrideValueHeader}
            form={form}
            onChange={() => setHasUnsavedChanges(true)}
          />
        </LoadingFallback>
      </div>
    </>
  );
};
