import { ApiResponse, useRequest } from "./request.hooks";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { TiktokSellerAccountLite } from "@/types/TiktokSellerAccount";
import { OperationJobType } from "@/types/Operation";

export const RETRIEVE_ALL_TIKTOK_SELLER_ACCOUNTS = "retrieveAllTiktokSellerAccounts";
export const RETRIEVE_TIKTOK_SELLER_ACCOUNT_BY_ID = "retrieveTiktokSellerAccountById";
export const RETRIEVE_TIKTOK_SELLER_ACCOUNT_BY_ID_REFETCH_INTERVAL = "retrieveTiktokSellerAccountByIdRefetchInterval";
export const LIST_PAYMENTS_BY_TIKTOK = "listPaymentsByTikTok";

export const useRetrieveAllTiktokSellerAccounts = (): { 
    data: TiktokSellerAccountLite[], 
    isLoading: boolean, 
    error: Error | null 
} => {
    const { request } = useRequest();
    const { token } = useContext(UserStateContext);
    
    const { data, isLoading, error } = useQuery({
        queryKey: [RETRIEVE_ALL_TIKTOK_SELLER_ACCOUNTS, token],
        queryFn: () =>
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve(
                        request({
                            url: `/api/v1/tiktokSellerAccount/retrieve/all`,
                            method: "GET"
                        })
                    );
                }, 500);
            })
                .then((response: any) => {
                    if (response.success) {
                        return response.content;
                    } else {
                        throw new Error(response.errorMessage || "Failed to fetch TikTok seller accounts");
                    }
                }),
        staleTime: 5 * 60 * 1000, // 5 minutes in milliseconds
    });

    return { 
        data, isLoading, error
    };
};

export const useListPaymentsByTikTok = (params: {
    pageSize?: number;
    pageToken?: string;
    sortField?: string;
    sortOrder?: "ASC" | "DESC";
    createTimeGe?: Date;
    createTimeLt?: Date;
}) => {
    const { request } = useRequest();
    const { token } = useContext(UserStateContext);

    const { data, isLoading, error } = useQuery({
        queryKey: [LIST_PAYMENTS_BY_TIKTOK, token, params],
        queryFn: () =>
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve(
                        request({
                            url: `/api/v1/tiktokSellerAccount/retrieve/listPaymentsByTikTok`,
                            method: "GET",
                            params: {
                                pageSize: params.pageSize?.toString() || "10",
                                pageToken: params.pageToken || "",
                                sortField: params.sortField || "",
                                sortOrder: params.sortOrder || "",
                                createTimeGe: params.createTimeGe?.toISOString() || "",
                                createTimeLt: params.createTimeLt?.toISOString() || ""
                            }
                        })
                    );
                }, 500);
            })
                .then((response: any) => {
                    if (response.success) {
                        return response.content;
                    } else {
                        throw new Error(response.errorMessage || "Failed to fetch TikTok payments");
                    }
                }),
        staleTime: 5 * 60 * 1000, // 5 minutes in milliseconds
    });

    return { data, isLoading, error };
};

export const useRetrieveTiktokSellerAccountByIdWithRefetchInterval = (id: number | null): {
    data: TiktokSellerAccountLite | null,
    isLoading: boolean,
    error: Error | null,
    isFetched: boolean,
    isFetching: boolean,
    dataUpdatedAt: number | undefined
} => {
    const { request } = useRequest();
    const { token } = useContext(UserStateContext);

    const { isFetched, data, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: [RETRIEVE_TIKTOK_SELLER_ACCOUNT_BY_ID_REFETCH_INTERVAL, token, id],
        enabled: !!id,
        queryFn: () =>
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve(
                        request({
                            url: `/api/v1/tiktokSellerAccount/retrieve/byId/${id}`,
                            method: "GET"
                        })
                    );
                }, 2000);
            })
                .then((response: any) => {
                    if (response.success) {
                        return response.content;
                    } else {
                        throw new Error(response.errorMessage || "Failed to fetch TikTok seller account");
                    }
                }),
        refetchInterval: 5 * 1000, // 5 seconds in milliseconds
    });

    return {
        data, isFetched, isLoading, error, isFetching, dataUpdatedAt
    };
};

export const useRetrieveTiktokSellerAccountById = (id: number | null): {
    data: TiktokSellerAccountLite | null,
    isLoading: boolean,
    error: Error | null,
    isFetched: boolean
} => {
    const { request } = useRequest();
    const { token } = useContext(UserStateContext);

    const { isFetched, data, isLoading, error } = useQuery({
        queryKey: [RETRIEVE_TIKTOK_SELLER_ACCOUNT_BY_ID, token, id],
        enabled: !!id,
        queryFn: () =>
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve(
                        request({
                            url: `/api/v1/tiktokSellerAccount/retrieve/byId/${id}`,
                            method: "GET"
                        })
                    );
                }, 2000);
            })
                .then((response: any) => {
                    if (response.success) {
                        return response.content;
                    } else {
                        throw new Error(response.errorMessage || "Failed to fetch TikTok seller account");
                    }
                }),
        staleTime: 5 * 60 * 1000, // 5 minutes in milliseconds
    });

    return {
        data, isFetched, isLoading, error
    };
};

export const useTiktokSellerAccountService = () => {
    const { request } = useRequest();

    const kickOffInitialSetupOperation = (payload: { sellerAccountId: number, jobType: OperationJobType }): Promise<ApiResponse> => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(
                    request({
                        url: "/api/v1/operation/actions/kickOffInitialSetupOperation",
                        method: "POST",
                        headers: {
                            "content-type": "application/json",
                        },
                        data: payload
                    })
                );
            }, 500);
        });
    };

    const completeSetup = (sellerAccountId: number): Promise<ApiResponse> => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(
                    request({
                        url: `/api/v1/tiktokSellerAccount/complete-setup/${sellerAccountId}`,
                        method: "POST"
                    })
                );
            }, 500);
        });
    };

    const retryOperation = (jobId: number): Promise<ApiResponse> => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(
                    request({
                        url: "/api/v1/operation/actions/retryOperation",
                        method: "POST",
                        headers: {
                            "content-type": "application/json",
                        },
                        data: { jobId }
                    })
                );
            }, 500);
        });
    };

    return {
        kickOffInitialSetupOperation,
        completeSetup,
        retryOperation
    };
};
