import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { cn } from "@/lib/utils";

const cogsCalculationOptions = [
  {
    value: "ALL_ORDERS",
    label: "Exclude unpaid orders",
    description: "Calculate COGS for orders excluding unpaid orders.",
  },
  {
    value: "EXCLUDE_UNSHIPPED_CANCELLED_ORDERS", 
    label: (
      <div className="flex items-start gap-2">
        Exclude cancelled orders which were not shipped
        <div className="px-2 py-0.5 text-xs rounded-md bg-green-500/20 text-green-500">Recommended</div>
      </div>
    ),
    description: "Calculate COGS only for orders that resulted in inventory leaving the warehouse, including shipped orders that were later cancelled.",
  },
  {
    value: "EXCLUDE_CANCELLED_ORDERS",
    label: "Exclude all cancelled orders",
    description: "Calculate COGS only for successfully completed orders, excluding all cancelled orders regardless of shipping status.",
  },
] as const;

export function CogsCalcSelect({
  value,
  onChange,
}: {
  value: "ALL_ORDERS" | "EXCLUDE_UNSHIPPED_CANCELLED_ORDERS" | "EXCLUDE_CANCELLED_ORDERS";
  onChange: (value: "ALL_ORDERS" | "EXCLUDE_UNSHIPPED_CANCELLED_ORDERS" | "EXCLUDE_CANCELLED_ORDERS") => void;
}) {
  return (
    <RadioGroup
      value={value}
      onValueChange={(value) => {
        onChange(value as "ALL_ORDERS" | "EXCLUDE_UNSHIPPED_CANCELLED_ORDERS" | "EXCLUDE_CANCELLED_ORDERS");
      }}
      className="space-y-4"
    >
      {cogsCalculationOptions.map((option) => (
        <div 
          key={option.value} 
          className={cn(
            "flex items-start space-x-4 space-y-0 cursor-pointer hover:bg-muted/20 px-4 py-3 rounded-md",
            value === option.value && "bg-blue-500/10"
          )}
          onClick={() => onChange(option.value)}
        >
          <RadioGroupItem value={option.value} id={option.value} className="mt-1" />
          <div className="grid gap-1.5 leading-none">
            <label
              htmlFor={option.value}
              className="text-base font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              {option.label}
            </label>
            <p className="text-sm text-muted-foreground">
              {option.description}
            </p>
          </div>
        </div>
      ))}
    </RadioGroup>
  );
}
