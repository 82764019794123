
export enum OperationJobStatus {
  PENDING = 'PENDING',
  READY = 'READY',
  RECEIVED_AND_PROCESSING = 'RECEIVED_AND_PROCESSING',
  NOT_REQUIRED = 'NOT_REQUIRED',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS'
}

export enum OperationBlockingType {
  BLOCKING = 'BLOCKING',
  NON_BLOCKING = 'NON_BLOCKING'
}

export enum OperationJobType {
  INITIAL_SYNC_PRODUCTS_AND_SKUS = 'INITIAL_SYNC_PRODUCTS_AND_SKUS',
  INITIAL_SYNC_ORDERS = 'INITIAL_SYNC_ORDERS',
  INITIAL_SYNC_TIKTOK_SKU_PERIODS = 'INITIAL_SYNC_TIKTOK_SKU_PERIODS'
}

export interface Operation {
  id: number;
  jobStatus: OperationJobStatus;
  jobType: OperationJobType;
  retryable: boolean;
  blockingType: OperationBlockingType;
  userFriendlyDescription?: string;
  userJobData?: {
    lastResetPing?: Date;
    syncOrdersStatusData: {
      shopId: number;
      shopName: string;
      processedOrderCount: number;
      totalOrderCount: number;
    }[],
    syncSkuPeriodsStatusData: {
      shopId: number;
      shopName: string;
      processedSkuCount: number;
      totalSkuCount: number;
      totalSettledOrdersCount: number;
      processedSettledOrdersCount: number;
    }[],
  };
  errors?: {
    message: string;
    friendlyMessage?: string;
  }[];
  organisationId: number;
  authorUserId?: number;
  tiktokSellerAccountId?: number;
  updatedAt: Date;
  createdAt: Date;
}
