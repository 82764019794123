import { LoadingFallback } from "@/components/loading-fallback";
import { useMultipleLiteDashboardStatistics } from "@/hooks/tiktokOrder.hooks";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { useContext, useState } from "react";
import {
  DollarSign,
  TrendingUp,
  TrendingDown,
  Info,
  ChevronDown,
  Settings2Icon,
  ChevronRight,
} from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { useOrganisationPlan } from "@/hooks/organisation.hooks";
import { TaxSettingsPopover } from "@/components/tax-settings-popover";
import { PlatformFeesConfigureButton } from "@/components/platformCommissionFees/platform-fees-configure-button";
import { FbtFulfillmentFeesConfigureButton } from "@/components/fbtFulfillmentFees/fbt-fulfillment-fees-configure-button";
import { _Button } from "@/components/button";
import { useNavigate } from "react-router-dom";
import { Currency } from "@/types/Currency";
import { CogsCalcPopover } from "@/components/cogs-calc-popover";

const formatValue = (value: number | undefined | null, currency: Currency) => {
  if (!value) return `${currency.symbol}0.00`;
  return `${currency.symbol}${value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};

const RenderMetric = ({
  label,
  value,
  tooltip,
  isNegative = false,
  isAdded = false,
  isSmall = false,
  isPlainFormat = false,
  isLowOpacity = false,
  isPercentage = false,
  extra,
  children,
  className,
}: {
  label: string;
  value: number | undefined | null;
  tooltip: string;
  isNegative?: boolean;
  isAdded?: boolean;
  isSmall?: boolean;
  isPlainFormat?: boolean;
  isLowOpacity?: boolean;
  isPercentage?: boolean;
  extra?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const hasChildren = !!children;
  const { currency } = useContext(UserStateContext);

  return (
    <>
      <div
        className={cn(
          "flex flex-wrap justify-between items-center text-sm py-1.5 border-b px-4",
          hasChildren && "cursor-pointer hover:bg-muted/20",
          className
        )}
        onClick={() => hasChildren && setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center gap-2">
          <TooltipProvider delayDuration={0}>
            <Tooltip>
              <TooltipTrigger asChild>
                <Info
                  size={12}
                  className="text-muted-foreground opacity-50 hover:opacity-100 transition-opacity cursor-help"
                />
              </TooltipTrigger>
              <TooltipContent className={"w-[200px]"}>
                <p>{tooltip}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <span
            className={cn(
              "text-muted-foreground",
              isLowOpacity ? "opacity-70" : ""
            )}
          >
            {label}
          </span>
          {extra && <div onClick={(e) => e.stopPropagation()}>{extra}</div>}
          {hasChildren && (
            <ChevronRight
              className={cn(
                "h-4 w-4 transition-transform",
                isExpanded && "transform rotate-90"
              )}
            />
          )}
        </div>
        <span
          className={cn(
            "font-medium",
            isLowOpacity ? "opacity-70" : "",
            isNegative
              ? "text-red-500"
              : isAdded
              ? "text-green-500"
              : "text-foreground",
            isSmall ? "text-xs" : "text-sm"
          )}
        >
          {isNegative ? "-" : isAdded ? "+" : ""}{" "}
          {isPlainFormat
            ? value
              ? value
              : "-"
            : isPercentage
            ? `${
                value
                  ? value.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "0"
              }%`
            : formatValue(value, currency)}
        </span>
      </div>
      {hasChildren && isExpanded && (
        <div className="opacity-100">{children}</div>
      )}
    </>
  );
};

export default function PandLOverviewTile() {
  const { data: organisation } = useOrganisationPlan();
  const adSpendEnabled = organisation?.adSpendEnabled;
  const navigate = useNavigate();
  const { currency } = useContext(UserStateContext);
  const { data, isLoading } = useMultipleLiteDashboardStatistics([
    "today",
    "yesterday",
    "this_week",
    "last_week",
  ]);

  const calculatePercentageChange = (current: number, previous: number) => {
    if (!previous) return 0;
    return ((current - previous) / previous) * 100;
  };

  const renderTrend = (percentage: number) => {
    if (percentage > 0) {
      return (
        <div className="flex items-center text-green-500 text-sm">
          <TrendingUp size={14} className="mr-1" />
          <span>+{percentage.toFixed(1)}%</span>
        </div>
      );
    } else if (percentage < 0) {
      return (
        <div className="flex items-center text-red-500 text-sm">
          <TrendingDown size={14} className="mr-1" />
          <span>{percentage.toFixed(1)}%</span>
        </div>
      );
    }
    return null;
  };

  const renderTile = (
    current: any,
    previous: any | null,
    title: string,
    icon: JSX.Element,
    tooltipText: string,
    showComparison: boolean = true
  ) => (
    <Collapsible className="h-full rounded-lg border bg-card text-card-foreground shadow-sm flex flex-col justify-between">
      <div>
        <div className="pt-6 px-6 flex flex-wrap justify-between items-start">
          <div className="w-full space-y-1 flex flex-wrap justify-between items-center">
            <div className="flex items-center gap-4">
              {icon}
              <div>
                <h3 className="text-sm font-medium text-muted-foreground">
                  {title}
                </h3>
                <p className="text-xs text-muted-foreground/50">Gross Sales</p>
              </div>
            </div>
            {showComparison &&
              renderTrend(
                calculatePercentageChange(
                  current?.totalGrossSales ?? 0,
                  previous?.totalGrossSales ?? 0
                )
              )}
            {/* <div className="flex flex-col items-center gap-1">
              <p className="text-lg font-bold">
                {formatValue(current?.totalGrossSales, currency)}
              </p>
            </div> */}
          </div>
        </div>

        <div className="mt-4 border-t flex flex-col justify-between">
          <RenderMetric
            label="Net Units Sold"
            value={current?.units - current?.cancelledUnits}
            tooltip="The total number of units sold. Excludes cancelled units."
            isPlainFormat={true}
          >
            <RenderMetric
              className="pl-8"
              isLowOpacity={true}
              label="Units"
              value={current?.units}
              tooltip="The total number of units sold. Includes cancelled units."
              isPlainFormat={true}
              isAdded={true}
            />
            <RenderMetric
              className="pl-8"
              label="Cancelled"
              value={current?.cancelledUnits}
              tooltip="Total cancelled units."
              isPlainFormat={true}
              isNegative={true}
              isLowOpacity={true}
              children={
                <>
                  <RenderMetric
                    className="pl-16"
                    label="Cancelled (by TikTok)"
                    value={current?.systemCancelledUnits}
                    tooltip="Units cancelled by the system (TikTok). These are usually due to: failed / missed payment, failed delivery, or other issues."
                    isPlainFormat={true}
                    isLowOpacity={true}
                  />
                  <RenderMetric
                    className="pl-16"
                    label="Cancelled (by Seller)"
                    value={current?.sellerCancelledUnits}
                    tooltip="Units cancelled by the seller (you)."
                    isPlainFormat={true}
                    isLowOpacity={true}
                  />
                  <RenderMetric
                    className="pl-16"
                    label="Cancelled (by Buyer)"
                    value={current?.buyerCancelledUnits}
                    tooltip="Units cancelled by the buyer."
                    isPlainFormat={true}
                    isLowOpacity={true}
                  />
                </>
              }
            />
          </RenderMetric>
          <RenderMetric
            label="Gross Sales"
            value={current?.totalGrossSales}
            tooltip="Gross revenue amount before seller discounts, shipping and platform discounts are deducted."
            children={
              <>
                <RenderMetric
                  className="pl-8"
                  label="Customer Payment"
                  value={
                    (current?.totalCustomerPayment - current?.totalCancelledCustomerPayment) - (current?.totalShippingSalePrice - current?.totalCancelledShippingSalePrice)
                  }
                  tooltip="Customer payment after deducting shipping fees paid by the buyer. Excludes cancelled payments."
                  isAdded={true}
                  isSmall={true}
                  isLowOpacity={true}
                  children={
                    <>
                      <RenderMetric
                        className="pl-16"
                        label="Payment"
                        value={Math.abs(current?.totalCustomerPayment ?? 0) - Math.abs(current?.totalCancelledCustomerPayment ?? 0)}
                        tooltip="Total payments received from customers. Excludes cancelled payments."
                        isNegative={false}
                        isAdded={true}
                        isSmall={true}
                        isLowOpacity={true}
                        children={
                          <>
                          <RenderMetric
                              className="pl-20"
                              label="Total"
                              value={current?.totalCustomerPayment}
                              tooltip="Total payments received from customers. Includes cancelled payments."
                              isAdded={true}
                              isSmall={true}
                              isLowOpacity={true}
                            />
                            <RenderMetric
                              className="pl-20"
                              label="Cancelled"
                              value={current?.totalCancelledCustomerPayment}
                              tooltip="Total cancelled payments received from customers."
                              isNegative={true}
                              isAdded={true}
                              isSmall={true}
                              isLowOpacity={true}
                            />
                          </>
                        }
                      />
                      <RenderMetric
                        className="pl-16"
                        label="Buyer Shipping Fees"
                        value={current?.totalShippingSalePrice - current?.totalCancelledShippingSalePrice}
                        tooltip="Shipping fees paid by the buyer. Deducted from the customer payment as it does not count towards the seller's revenue."
                        isNegative={true}
                        isSmall={true}
                        isLowOpacity={true}
                      />
                    </>
                  }
                />
                <RenderMetric
                  className="pl-8"
                  label="Platform Discounts"
                  value={current?.totalNetPlatformDiscounts}
                  tooltip="Platform discounts applied to the customer payment. Excludes cancelled platform discounts."
                  isAdded={true}
                  isSmall={true}
                  isLowOpacity={true}
                  children={
                    <>
                      <RenderMetric
                        className="pl-16"
                        label="Total"
                        value={current?.totalPlatformDiscounts}
                        tooltip="Total platform discounts applied to the customer payment. Includes cancelled platform discounts."
                        isAdded={true}
                        isSmall={true}
                        isLowOpacity={true}
                      />
                      <RenderMetric
                        className="pl-16"
                        label="Cancelled"
                        value={current?.totalCancelledPlatformDiscounts}
                        tooltip="Total cancelled platform discounts applied to the customer payment."
                        isNegative={true}
                        isAdded={true}
                        isSmall={true}
                        isLowOpacity={true}
                      />
                    </>
                  }
                />
                <RenderMetric
                  className="pl-8"
                  label="Seller Discounts"
                  value={current?.totalNetSellerDiscounts}
                  tooltip="Seller discounts applied to the customer payment. Excludes cancelled seller discounts."
                  isNegative={false}
                  isAdded={true}
                  isSmall={true}
                  isLowOpacity={true}
                  children={
                    <>
                      <RenderMetric
                        className="pl-16"
                        label="Total"
                        value={current?.totalSellerDiscounts}
                        tooltip="Total seller discounts applied to the customer payment. Includes cancelled seller discounts."
                        isAdded={true}
                        isSmall={true}
                        isLowOpacity={true}
                      />
                      <RenderMetric
                        className="pl-16"
                        label="Cancelled"
                        value={current?.totalCancelledSellerDiscounts}
                        tooltip="Total cancelled seller discounts applied to the customer payment."
                        isNegative={true}
                        isAdded={true}
                        isSmall={true}
                        isLowOpacity={true}
                      />
                    </>
                  }
                />
                {/* <RenderMetric
                  className="pl-8"
                  label="Refunds"
                  value={Math.abs(current?.totalGrossSalesRefundAmount ?? 0)}
                  tooltip="Total refunds on gross sales."
                  isNegative={true}
                  isSmall={true}
                  isLowOpacity={true}
                /> */}
              </>
            }
          />
          <RenderMetric
            label="Net Sales"
            value={current?.totalMerchandiseSales}
            tooltip="Net revenue amount after seller discounts are deducted. This figure does not include shipping fees or cancelled orders."
            children={
              <>
                <RenderMetric
                  className="pl-8"
                  label="Customer Payment"
                  value={
                    (current?.totalCustomerPayment - current?.totalCancelledCustomerPayment) - (current?.totalShippingSalePrice - current?.totalCancelledShippingSalePrice)
                  }
                  tooltip="Customer payment after deducting shipping fees paid by the buyer. Excludes cancelled payments."
                  isAdded={true}
                  isSmall={true}
                  isLowOpacity={true}
                  children={
                    <>
                      <RenderMetric
                        className="pl-16"
                        label="Payment"
                        value={Math.abs(current?.totalCustomerPayment ?? 0) - Math.abs(current?.totalCancelledCustomerPayment ?? 0)}
                        tooltip="Total payments received from customers. Excludes cancelled payments."
                        isNegative={false}
                        isAdded={true}
                        isSmall={true}
                        isLowOpacity={true}
                        children={
                          <>
                          <RenderMetric
                              className="pl-20"
                              label="Total"
                              value={current?.totalCustomerPayment}
                              tooltip="Total payments received from customers. Includes cancelled payments."
                              isAdded={true}
                              isSmall={true}
                              isLowOpacity={true}
                            />
                            <RenderMetric
                              className="pl-20"
                              label="Cancelled"
                              value={current?.totalCancelledCustomerPayment}
                              tooltip="Total cancelled payments received from customers."
                              isNegative={true}
                              isAdded={true}
                              isSmall={true}
                              isLowOpacity={true}
                            />
                          </>
                        }
                      />
                      <RenderMetric
                        className="pl-16"
                        label="Buyer Shipping Fees"
                        value={current?.totalShippingSalePrice - current?.totalCancelledShippingSalePrice}
                        tooltip="Shipping fees paid by the buyer. Deducted from the customer payment as it does not count towards the seller's revenue."
                        isNegative={true}
                        isSmall={true}
                        isLowOpacity={true}
                      />
                    </>
                  }
                />
                <RenderMetric
                  className="pl-8"
                  label="Platform Discounts"
                  value={current?.totalNetPlatformDiscounts}
                  tooltip="Platform discounts applied to the customer payment. Excludes cancelled platform discounts."
                  isNegative={false}
                  isAdded={true}
                  isSmall={true}
                  isLowOpacity={true}
                  children={
                    <>
                      <RenderMetric
                        className="pl-16"
                        label="Total"
                        value={current?.totalPlatformDiscounts}
                        tooltip="Total platform discounts applied to the customer payment. Includes cancelled platform discounts."
                        isAdded={true}
                        isSmall={true}
                        isLowOpacity={true}
                      />
                      <RenderMetric
                        className="pl-16"
                        label="Cancelled"
                        value={current?.totalCancelledPlatformDiscounts}
                        tooltip="Total cancelled platform discounts applied to the customer payment."
                        isNegative={true}
                        isAdded={true}
                        isSmall={true}
                        isLowOpacity={true}
                      />
                    </>
                  }
                />
                {/* <RenderMetric
                  className="pl-8"
                  label="Cancelled"
                  value={Math.abs(
                    current?.totalMerchandiseSalesRefundAmount ?? 0
                  )}
                  tooltip="Total refunds on net sales."
                  isNegative={true}
                  isSmall={true}
                  isLowOpacity={true}
                /> */}
              </>
            }
          />
          <RenderMetric
            label="Cost of Goods"
            value={current?.costOfGoods}
            tooltip="Cost of units sold. May or may not exclude cancelled orders based on your settings. Click configure to change."
            isNegative={true}
            extra={<CogsCalcPopover type="CONFIGURE" />}
          />
          {adSpendEnabled && (
            <RenderMetric
              label="Ad Spend"
              value={current?.totalSpend}
              tooltip="Total spend on ads (regular Ads and GMV Max Ads). Inclusive of VAT."
              isNegative={true}
            />
          )}
          <RenderMetric
            label="TikTok Commiss. Fee"
            value={current?.totalPlatformFee}
            tooltip="Estimation of fees charged by the platform for using their services."
            isNegative={true}
            extra={<PlatformFeesConfigureButton type="CONFIGURE" />}
          />
          <RenderMetric
            label="Affiliates"
            value={current?.totalEstimatedAffiliateCommission}
            tooltip="Estimated fees paid to affiliates for referring customers (incl. ads and partner commissions)."
            isNegative={true}
          />
          <RenderMetric
            label="Shipping Costs"
            value={
              (current?.totalEstimatedFbmShippingCost ?? 0) +
              (current?.totalEstimatedSellerShippingCost ?? 0) +
              (current?.totalEstimatedFbtShippingCost ?? 0)
            }
            tooltip="Total shipping costs across all fulfillment methods."
            isNegative={true}
          >
            <RenderMetric
              className="pl-8"
              label="FBM Shipping"
              value={current?.totalEstimatedFbmShippingCost}
              tooltip="Shipping fee's paid for orders auto-shipped with TikTok, but fulfilled by the seller (Fulfilled by Merchant)."
              isNegative={true}
              isSmall={true}
              isLowOpacity={true}
            />
            <RenderMetric
              className="pl-8"
              label="Seller-Fulfilled Shipping"
              value={current?.totalEstimatedSellerShippingCost}
              tooltip="Shipping fee's paid for orders managed, and fulfilled by the seller (you)."
              isNegative={true}
              isSmall={true}
              isLowOpacity={true}
            />
            <RenderMetric
              className="pl-8"
              label="FBT Shipping"
              value={current?.totalEstimatedFbtShippingCost}
              tooltip="Shipping fee's for orders fulfilled using Fulfilled by TikTok (FBT)."
              isNegative={true}
              isSmall={true}
              isLowOpacity={true}
            />
          </RenderMetric>
          <RenderMetric
            label="FBT Fulfillment & Packaging"
            value={current?.totalEstimatedFbtFulfilmentAndPackagingFees}
            tooltip="Fee's paid to TikTok for the fulfillment of orders using Fulfilled by TikTok (FBT). Estimated based on product size."
            isNegative={true}
            extra={<FbtFulfillmentFeesConfigureButton type="CONFIGURE" />}
          />
          <RenderMetric
            label="Net Tax"
            value={current?.totalTax - current?.totalTaxBack}
            tooltip="Total tax minus tax back on costs."
            isNegative={true}
            extra={<TaxSettingsPopover type="CONFIGURE" />}
          >
            <RenderMetric
              className="pl-8"
              label="Tax"
              value={current?.totalTax}
              tooltip="Total tax on sales."
              isNegative={true}
              isSmall={true}
              isLowOpacity={true}
            />
            <RenderMetric
              className="pl-8"
              label="Tax Refunds"
              value={current?.totalTaxBack}
              tooltip="Tax refunds on costs."
              isAdded={true}
              isSmall={true}
              isLowOpacity={true}
              extra={<TaxSettingsPopover type="CONFIGURE" />}
            />
          </RenderMetric>
        </div>
      </div>
      <div className="flex flex-col">
        <RenderMetric
          className="border-none bg-muted/20"
          label="Net Profits"
          value={current?.netProfits}
          tooltip="Earnings after all costs and expenses"
          isNegative={current?.netProfits < 0}
          isAdded={current?.netProfits > 0}
        />
        <RenderMetric
          className="border-none bg-muted/20"
          label="Net Margin"
          value={current?.margin.toFixed(2)}
          tooltip="Net margin as a percentage of gross sales."
          isPercentage={true}
        />
        <RenderMetric
          className="border-none bg-muted/20"
          label="ROI"
          value={
            current?.netProfits && current?.costOfGoods
              ? (current.netProfits / current.costOfGoods) * 100
              : 0
          }
          tooltip="Return on Investment - Net profits as a percentage of cost of goods."
          isPercentage={true}
        />
      </div>
    </Collapsible>
  );

  return (
    <LoadingFallback loading={isLoading}>
      <div className="space-y-4">
        <div className="flex items-center gap-2">
          <h3 className="text-lg font-semibold text-foreground">
            P&L Overview
          </h3>
        </div>

        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 items-stretch">
          {renderTile(
            data?.today,
            data?.yesterday,
            "Today",
            <div className="h-8 w-8 rounded-full bg-green-400 flex items-center justify-center">
              <DollarSign className="h-4 w-4 text-green-800" />
            </div>,
            "Today's performance metrics"
          )}
          {renderTile(
            data?.yesterday,
            null,
            "Yesterday",
            <div className="h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center">
              <DollarSign className="h-4 w-4 text-blue-800" />
            </div>,
            "Yesterday's performance metrics",
            false
          )}
          {renderTile(
            data?.this_week,
            data?.last_week,
            "This Week",
            <div className="h-8 w-8 rounded-full bg-purple-500 flex items-center justify-center">
              <DollarSign className="h-4 w-4 text-purple-800" />
            </div>,
            "This week's performance metrics"
          )}
          {renderTile(
            data?.last_week,
            null,
            "Last Week",
            <div className="h-8 w-8 rounded-full bg-orange-500 flex items-center justify-center">
              <DollarSign className="h-4 w-4 text-orange-800" />
            </div>,
            "Last week's performance metrics",
            false
          )}
        </div>
      </div>
    </LoadingFallback>
  );
}
