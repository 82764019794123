import React, { useState, useContext, useEffect } from "react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { TextFieldElement } from "@/components/formElements/text-field-element";
import { _Button } from "@/components/button";
import {
  Settings2Icon,
  InfoIcon,
  TriangleAlertIcon,
  CalendarIcon,
  TrashIcon,
  SettingsIcon,
  CheckCircleIcon,
  XCircleIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  MinusIcon,
  PlusIcon,
  UploadCloudIcon,
} from "lucide-react";
import { useForm } from "@/hooks/form.hook";
import { Calendar } from "@/components/ui/calendar";
import { format } from "date-fns/format";
import { Button } from "./ui/button";
import { useOrganisationService } from "@/hooks/organisation.hooks";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { useQueryClient } from "@tanstack/react-query";
import { useToast } from "@/hooks/toast.hooks";
import { TaxItemType } from "@/types/TaxItemType";
import { LITE_SETTLED_DASHBOARD_STATISTICS } from "@/hooks/tiktokSkuSettledPeriod.hooks";
import {
  LITE_PRODUCT_SKU_STATISTICS,
  LITE_PRODUCT_STATISTICS,
  LITE_DASHBOARD_STATISTICS,
} from "@/hooks/tiktokOrder.hooks";
import { ORGANISATION_PLAN } from "@/hooks/organisation.hooks";

type TaxItemConfig = {
  label: string;
  description?: string;
  showTaxRate?: boolean;
  showRefundRate?: boolean;
  showValidFrom?: boolean;
};

const taxItemLabels: Record<TaxItemType, TaxItemConfig> = {
  salesTax: {
    label: "Sales",
    showTaxRate: true,
    showValidFrom: true,
  },
  costTax: {
    label: "Cost of Goods",
    showRefundRate: true,
    showValidFrom: true,
  },
  adSpendTax: {
    label: "Ad Spend",
    showTaxRate: true,
    showRefundRate: true,
  },
  platformFeeTax: {
    label: "TikTok Commission Fees",
    showRefundRate: true,
  },
  affiliateCommissionTax: {
    label: "Affiliate Commission",
    showRefundRate: true,
  },
  fbmShippingCostTax: {
    label: "SLT Shipping",
    description:
      "Fulfilled By Merchant, Shipping by TikTok. You (the seller) store inventory but TikTok handles shipping",
    showRefundRate: true,
  },
  fbtShippingCostTax: {
    label: "FBT Shipping",
    description:
      "Fulfilled By TikTok. TikTok handles both storage and shipping",
    showRefundRate: true,
  },
  sellerShippingCostTax: {
    label: "SLS Shipping",
    description:
      "Fulfilled & Shipped by Seller. You (the seller) handle both storage and shipping",
    showRefundRate: true,
  },
  fbtFulfilmentAndPackagingFeesTax: {
    label: "FBT Fulfilment & Packaging Fees",
    showRefundRate: true,
  },
};

export const TaxSettingsPopover = ({
  type = "BUTTON",
}: {
  type?: "CONFIGURE" | "BUTTON";
}) => {
  const { taxOptions } = useContext(UserStateContext);
  const [isOpen, setIsOpen] = useState(false);
  const { updateSettings } = useOrganisationService();
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (taxOptions && Object.keys(taxOptions).length > 0) {
      form.setState({
        salesTaxRate: taxOptions.salesTax.taxRate,
        salesTaxRefundRate: taxOptions.salesTax.taxRefundRate,
        salesTaxValidFrom: taxOptions.salesTax.taxRateValidFrom,
        adSpendTaxRate: taxOptions.adSpendTax.taxRate,
        adSpendTaxRefundRate: taxOptions.adSpendTax.taxRefundRate,
        costTaxRate: taxOptions.costTax.taxRate,
        costTaxRefundRate: taxOptions.costTax.taxRefundRate,
        costTaxValidFrom: taxOptions.costTax.taxRateValidFrom,
        platformFeeTaxRate: taxOptions.platformFeeTax.taxRate,
        platformFeeTaxRefundRate: taxOptions.platformFeeTax.taxRefundRate,
        affiliateCommissionTaxRate: taxOptions.affiliateCommissionTax.taxRate,
        affiliateCommissionTaxRefundRate:
          taxOptions.affiliateCommissionTax.taxRefundRate,
        fbmShippingCostTaxRate: taxOptions.fbmShippingCostTax.taxRate,
        fbmShippingCostTaxRefundRate:
          taxOptions.fbmShippingCostTax.taxRefundRate,
        fbtShippingCostTaxRate: taxOptions.fbtShippingCostTax.taxRate,
        fbtShippingCostTaxRefundRate:
          taxOptions.fbtShippingCostTax.taxRefundRate,
        sellerShippingCostTaxRate: taxOptions.sellerShippingCostTax.taxRate,
        sellerShippingCostTaxRefundRate:
          taxOptions.sellerShippingCostTax.taxRefundRate,
        fbtFulfilmentAndPackagingFeesTaxRate:
          taxOptions.fbtFulfilmentAndPackagingFeesTax.taxRate,
        fbtFulfilmentAndPackagingFeesTaxRefundRate:
          taxOptions.fbtFulfilmentAndPackagingFeesTax.taxRefundRate,
      });
    }
  }, [taxOptions]);

  const form = useForm({
    fields: {
      salesTaxRate: 0,
      salesTaxRefundRate: 0,
      salesTaxValidFrom: null,
      adSpendTaxRate: 0,
      adSpendTaxRefundRate: 0,
      costTaxRate: 0,
      costTaxRefundRate: 0,
      costTaxValidFrom: null,
      platformFeeTaxRate: 0,
      platformFeeTaxRefundRate: 0,
      affiliateCommissionTaxRate: 0,
      affiliateCommissionTaxRefundRate: 0,
      fbmShippingCostTaxRate: 0,
      fbmShippingCostTaxRefundRate: 0,
      fbtShippingCostTaxRate: 0,
      fbtShippingCostTaxRefundRate: 0,
      sellerShippingCostTaxRate: 0,
      sellerShippingCostTaxRefundRate: 0,
      fbtFulfilmentAndPackagingFeesTaxRate: 0,
      fbtFulfilmentAndPackagingFeesTaxRefundRate: 0,
    },
    validate: {
      salesTaxRate: (value) =>
        value < 0 || value > 100 ? "Tax rate must be between 0 and 100" : null,
      salesTaxRefundRate: (value) =>
        value < 0 || value > 100
          ? "Refund rate must be between 0 and 100"
          : null,
      adSpendTaxRate: (value) =>
        value < 0 || value > 100 ? "Tax rate must be between 0 and 100" : null,
      adSpendTaxRefundRate: (value) =>
        value < 0 || value > 100
          ? "Refund rate must be between 0 and 100"
          : null,
      costTaxRate: (value) =>
        value < 0 || value > 100 ? "Tax rate must be between 0 and 100" : null,
      costTaxRefundRate: (value) =>
        value < 0 || value > 100
          ? "Refund rate must be between 0 and 100"
          : null,
      platformFeeTaxRate: (value) =>
        value < 0 || value > 100 ? "Tax rate must be between 0 and 100" : null,
      platformFeeTaxRefundRate: (value) =>
        value < 0 || value > 100
          ? "Refund rate must be between 0 and 100"
          : null,
      affiliateCommissionTaxRate: (value) =>
        value < 0 || value > 100 ? "Tax rate must be between 0 and 100" : null,
      affiliateCommissionTaxRefundRate: (value) =>
        value < 0 || value > 100
          ? "Refund rate must be between 0 and 100"
          : null,
      fbmShippingCostTaxRate: (value) =>
        value < 0 || value > 100 ? "Tax rate must be between 0 and 100" : null,
      fbmShippingCostTaxRefundRate: (value) =>
        value < 0 || value > 100
          ? "Refund rate must be between 0 and 100"
          : null,
      fbtShippingCostTaxRate: (value) =>
        value < 0 || value > 100 ? "Tax rate must be between 0 and 100" : null,
      fbtShippingCostTaxRefundRate: (value) =>
        value < 0 || value > 100
          ? "Refund rate must be between 0 and 100"
          : null,
      sellerShippingCostTaxRate: (value) =>
        value < 0 || value > 100 ? "Tax rate must be between 0 and 100" : null,
      sellerShippingCostTaxRefundRate: (value) =>
        value < 0 || value > 100
          ? "Refund rate must be between 0 and 100"
          : null,
      fbtFulfilmentAndPackagingFeesTaxRate: (value) =>
        value < 0 || value > 100 ? "Tax rate must be between 0 and 100" : null,
      fbtFulfilmentAndPackagingFeesTaxRefundRate: (value) =>
        value < 0 || value > 100
          ? "Refund rate must be between 0 and 100"
          : null,
    },
  });

  const handleSubmit = async () => {
    if (form.validate()) {
      console.log(form.errors);
      return;
    }

    setSaving(true);
    const response = await updateSettings({
      taxOptions: {
        salesTax: {
          taxRate: form.state.salesTaxRate,
          taxRefundRate: form.state.salesTaxRefundRate,
          taxRateValidFrom: form.state.salesTaxValidFrom,
        },
        adSpendTax: {
          taxRate: form.state.adSpendTaxRate,
          taxRefundRate: form.state.adSpendTaxRefundRate,
        },
        costTax: {
          taxRate: form.state.costTaxRate,
          taxRefundRate: form.state.costTaxRefundRate,
          taxRateValidFrom: form.state.costTaxValidFrom,
        },
        platformFeeTax: {
          taxRate: form.state.platformFeeTaxRate,
          taxRefundRate: form.state.platformFeeTaxRefundRate,
        },
        affiliateCommissionTax: {
          taxRate: form.state.affiliateCommissionTaxRate,
          taxRefundRate: form.state.affiliateCommissionTaxRefundRate,
        },
        fbmShippingCostTax: {
          taxRate: form.state.fbmShippingCostTaxRate,
          taxRefundRate: form.state.fbmShippingCostTaxRefundRate,
        },
        fbtShippingCostTax: {
          taxRate: form.state.fbtShippingCostTaxRate,
          taxRefundRate: form.state.fbtShippingCostTaxRefundRate,
        },
        sellerShippingCostTax: {
          taxRate: form.state.sellerShippingCostTaxRate,
          taxRefundRate: form.state.sellerShippingCostTaxRefundRate,
        },
        fbtFulfilmentAndPackagingFeesTax: {
          taxRate: form.state.fbtFulfilmentAndPackagingFeesTaxRate,
          taxRefundRate: form.state.fbtFulfilmentAndPackagingFeesTaxRefundRate,
        },
      },
    });

    setSaving(false);

    if (!response.success) {
      toast({
        icon: <XCircleIcon size={12} className={"text-red-500"} />,
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: response.errorMessage!,
      });
      return;
    }

    setIsOpen(false);
    queryClient.invalidateQueries({
      queryKey: [LITE_SETTLED_DASHBOARD_STATISTICS],
    });
    queryClient.invalidateQueries({
      queryKey: [LITE_DASHBOARD_STATISTICS],
    });
    queryClient.invalidateQueries({
      queryKey: [LITE_PRODUCT_STATISTICS],
    });
    queryClient.invalidateQueries({
      queryKey: [LITE_PRODUCT_SKU_STATISTICS],
    });
    queryClient.invalidateQueries({
      queryKey: [ORGANISATION_PLAN],
    });
    toast({
      icon: <CheckCircleIcon size={12} className={"text-green-500"} />,
      variant: "default",
      title: "Success",
      description: "Your tax settings have been successfully updated.",
      duration: 2000,
    });
  };

  const handleClearDate = () => {
    form.updateFieldValue("salesTaxValidFrom", null);
  };

  const formatDate = (date: Date | null) => {
    return date ? format(date, "PPP") : "Select a date";
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        {type === "BUTTON" ? (
          <_Button
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(true);
            }}
            className="w-full flex items-center gap-2"
            variant="secondary"
            size="sm"
          >
            <SettingsIcon className="w-4 h-4" />
            Configure Taxes
          </_Button>
        ) : (
          <_Button
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(true);
            }}
            className="h-auto hover:bg-transparent p-0 text-[10px] hover:text-blue-600 flex items-center gap-2 text-blue-500 underline"
            variant="ghost"
            size="sm"
          >
            <Settings2Icon className="w-3 h-3" />
            Configure
          </_Button>
        )}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[75rem] p-0 h-[95dvh] overflow-y-auto rounded-none">
        <div>
          <div className="border-b flex items-center justify-between px-6 py-4">
            <div className="flex flex-col">
              <h3 className="font-semibold text-lg">Tax Settings</h3>
              <p className="text-sm font-normal text-muted-foreground">
                Click to manage tax rates and refund rates for each tax item.
              </p>
            </div>
            <_Button
              onClick={handleSubmit}
              className="mr-10 w-auto bg-blue-500 text-white hover:bg-blue-600 flex items-center justify-center gap-4"
              loading={saving}
            >
              Save Changes
              <UploadCloudIcon className="w-4 h-4" />
            </_Button>
          </div>

          <Accordion type="single" collapsible className="w-full">
            {Object.entries(taxItemLabels).map(([key, config]) => (
              <AccordionItem
                key={key}
                value={key}
                className="hover:underline-none"
              >
                <AccordionTrigger className="text-lg font-medium px-6 py-2">
                  <div className="flex items-center w-full">
                    <div className="lg:w-2/3 w-full">
                      <div className="text-sm font-medium text-left">
                        {config.label}
                        {config.description && (
                          <div className="text-xs font-normal text-muted-foreground">
                            {config.description}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="lg:w-1/3 flex w-full items-center justify-end gap-10 mr-16">
                      {config.showTaxRate && (
                        <div className="flex items-center gap-1 text-sm">
                          <span className="text-muted-foreground">Rate:</span>
                          <div className="flex items-center gap-1">
                            <MinusIcon className="h-3 w-3 text-red-500" />
                            <span className="font-medium text-red-600">
                              {form.state[`${key}Rate`]}%
                            </span>
                          </div>
                        </div>
                      )}
                      {config.showRefundRate && (
                        <div className="flex items-center gap-1 text-sm">
                          <span className="text-muted-foreground">
                            Refund Rate:
                          </span>
                          <div className="flex items-center gap-1">
                            <PlusIcon className="h-3 w-3 text-green-500" />
                            <span className="font-medium text-green-600">
                              {form.state[`${key}RefundRate`]}%
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="px-6 pb-4">
                  <div className="border rounded-lg p-4 space-y-4">
                    <div className="flex flex-col gap-6">
                      <div className="grid grid-cols-3 gap-4">
                        {config.showTaxRate && (
                          <TextFieldElement
                            fieldName={`${key}Rate`}
                            label="Tax Rate"
                            type="number"
                            className="bg-muted w-full "
                            placeholder="Enter tax rate"
                            startDecorator="%"
                            form={form}
                            helper="The tax rate that will be used to calculate the tax amount for this item."
                          />
                        )}

                        {config.showRefundRate && (
                          <div className="w-full flex items-start justify-between gap-4">
                            <TextFieldElement
                              fieldName={`${key}RefundRate`}
                              label="Tax Refund Rate"
                              type="number"
                              className="bg-muted w-full"
                              placeholder="Enter tax refund rate"
                              startDecorator="%"
                              form={form}
                              helper="The rate at which you can claim back tax on this item."
                            />
                          </div>
                        )}

                        {config.showRefundRate && (
                          <div className="flex flex-col gap-2">
                            <div className="flex items-center gap-3 text-sm text-blue-500">
                              <InfoIcon className="h-4 w-4" />
                              Tax Refund Scenario
                            </div>
                            <p className="mt-2 text-xs">
                              Let's say you pay £75.00 in shipping fees (inclusive of
                              tax) and the refund tax rate is set to 20%.
                            </p>
                            <ul className="mt-1 list-disc list-inside text-xs">
                              <li>Net amount: £75.00 / 1.20 = £62.50</li>
                              <li>Tax paid: £75.00 - £62.50 = £12.50</li>
                              <li className="text-green-500">
                                You can claim back £12.50 in tax on this item.
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>

                    {config.showValidFrom && (
                      <div className="w-full mt-4">
                        <label className="block text-sm font-medium mb-1">
                          Apply From Date
                        </label>
                        <div className="flex items-start gap-2 w-full">
                          <Popover>
                            <PopoverTrigger>
                              <Button
                                variant="secondary"
                              >
                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {formatDate(form.state[`${key}ValidFrom`])}
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent
                              className="w-auto p-0"
                              align="start"
                            >
                              <Calendar
                                mode="single"
                                selected={
                                  form.state[`${key}ValidFrom`] || undefined
                                }
                                onSelect={(date) =>
                                  form.updateFieldValue(`${key}ValidFrom`, date)
                                }
                                initialFocus
                              />
                            </PopoverContent>
                          </Popover>
                          {form.state[`${key}ValidFrom`] && (
                            <Button
                              variant="outline"
                              className="bg-red-500 h-[60px] text-white w-full justify-center items-center flex gap-2 w-auto"
                              onClick={() =>
                                form.updateFieldValue(`${key}ValidFrom`, null)
                              }
                            >
                              <TrashIcon className="h-4 w-4" />
                            </Button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </DialogContent>
    </Dialog>
  );
};
